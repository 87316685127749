import axios from 'axios';
import * as rax from 'retry-axios';
import Vue from 'vue';
import { defaultInterceptors } from './services/fmApiClient';
/**
 * A global axios instance config func
 * NOTE: Will removed after moving all API to single instance
 */
const initAxios = () => {
  Vue.prototype.$log.info('initial global axios');
  // These config only run once when app started in main
  // They are not in an interceptor, so they don't run before each request
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';
  axios.defaults.headers.common['FLUX-BUILD'] = import.meta.env.VITE_BUILD_STAMP;
  // And this happens on each page reload (window.location change, redirect, etc)
  axios.defaults.baseURL = import.meta.env.VITE_FM_API_BASE;

  // Attach retry-axios before the global error handler, so it gets executed first
  rax.attach(axios);
  // Won't do rax default config here by setting axios.defaults.raxConfig
  // because they do not take effect due to a bug https://github.com/JustinBeckwith/retry-axios/pull/91
  // This way rax will still retry by its default settings, which are pretty good

  // attach interceptors
  axios.interceptors.request.use(defaultInterceptors.requestInterceptor, defaultInterceptors.requestInterceptorError);
  axios.interceptors.response.use(((c) => c), defaultInterceptors.responseInterceptorError);
};

export default initAxios;
