import { unset, cloneDeep } from 'lodash-es';

/**
 * Returns a new copy of the object passed as the first argument with the specified keys removed.
 * Keys to omit can be specified as single arguments, multiple arguments, or as an array.
 *
 * Based on https://levelup.gitconnected.com/omit-is-being-removed-in-lodash-5-c1db1de61eaf
 *
 * @param {Record<string, unknown>} object Plain object
 * @param {string[]} keysToOmit Array of keys to omit
 * @returns {Object}
 */
// Don't use `Object` as a type. The `Object` type actually means "any non-nullish value", so it is marginally better than `unknown`.
// - If you want a type meaning "any object", you probably want `Record<string, unknown>` instead.
export const omit = (object: Record<string, unknown>, ...keysToOmit: string[]) => {
  const clonedObject = cloneDeep(object);
  // flatten keysToOmit to support a single array argument, which will be wrapped in another array
  // by the rest operator.
  for (const path of keysToOmit.flat()) {
    unset(clonedObject, path);
  }
  return clonedObject;
};
