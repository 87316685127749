import { acceptHMRUpdate, defineStore } from 'pinia';

interface GlobalState {
  snackbar: object,
  debugModeEnabled: boolean,
  changeHistoryEnabled: boolean,
  displayBackNavigation: boolean,
}

const defaultSnackbarState = () => (
  {
    visible: false,
    message: '',
    icon: '',
    color: 'secondary4',
    timeout: 6000,
    position: 'bottom',
    isClosable: false,
    e2eTag: '',
    button: null,
    buttonPath: null,
  }
);

const getDefaultState = (): GlobalState => (
  {
    snackbar: defaultSnackbarState(),
    debugModeEnabled: false,
    changeHistoryEnabled: false,
    displayBackNavigation: false,
  }
);

export const useGlobalStore = defineStore('global', {
  state: (): GlobalState => getDefaultState(),
  actions: {
    setSnackbarConfig(config) {
      this.snackbar = {
        ...defaultSnackbarState(),
        ...config,
      };
    },
    showSnackbar(config) {
      this.setSnackbarConfig({
        ...config,
        visible: true,
      });
    },
    hideSnackbar(config) {
      this.setSnackbarConfig({
        ...config,
        visible: false,
      });
    },
    setDebugMode(value) {
      this.debugModeEnabled = value;
    },
    showChangeHistory() {
      this.changeHistoryEnabled = true;
    },
    setBackNavigation(value) {
      this.displayBackNavigation = value;
    },
  },
  getters: {
    snackbarConfig: (state) => state.snackbar,
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
