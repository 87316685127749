// Routes used in the talent / employee view of the app
// All talent routes require authentication
// All talent routes are prepended with /talent in the path

export default [
  {
    path: '/talent',
    component: () => import('../../views/BaseRouter.vue'),
    meta: {
      authRequired: true,
      view: 'talent',
    },
    children: [
      {
        path: 'survey/:surveyId',
        component: () => import('../../views/survey/Survey.vue'),
        props: true,
        meta: {
          title: 'Surveys',
          analyticsPageName: 'Survey',
        },
      },
      {
        path: 'opportunities/roles/:roleId',
        component: () => import('../../views/roles/Role.vue'),
        props: (route) => ({
          // pass thru URL parameters
          roleId: Number.parseInt(route.params.roleId, 10) || undefined,
          query: route.query,
        }),
        meta: {
          title: 'Job Family',
          analyticsPageName: 'Role',
          displayBackNavigation: true,
        },
      },
      {
        path: 'opportunities/requisitions/:requisitionId',
        component: () => import('../../views/requisitions/Requisition.vue'),
        props: (route) => ({
          requisitionId: Number.parseInt(route.params.requisitionId, 10) || undefined,
          displayTalentOptions: true,
          query: route.query,
        }),
        name: 'talentRequisition',
        meta: {
          title: 'Opportunity',
          analyticsPageName: 'View Requisition',
          displayBackNavigation: true,
        },
      },
      {
        path: 'opportunities/requisitions/:requisitionId/applications',
        component: () => import('../../views/requisitions/ApplicationChecklist.vue'),
        props: (route) => ({
          requisitionId: Number.parseInt(route.params.requisitionId, 10) || undefined,
          query: route.query,
        }),
        meta: {
          title: 'Application',
          analyticsPageName: 'View Application',
          displayBackNavigation: true,
        },
      },
      // redirect old requisitions search page to combined search page
      {
        path: 'opportunities/requisitions',
        redirect: (to) => {
          const { query } = to;
          return { path: '/talent/opportunities', query: { ...query } };
        },
      },
      {
        path: 'opportunities',
        component: () => import('../../views/opportunities/Opportunities.vue'),
        props: (route) => ({
          query: route.query,
        }),
        name: 'talentOpportunities',
        meta: {
          title: 'Opportunities',
          analyticsPageName: 'Opportunities',
        },
      },
      {
        path: 'opportunities/programs/:programId',
        component: () => import('../../views/programs/Program.vue'),
        props: (route) => ({
          programId: Number.parseInt(route.params.programId, 10) || undefined,
          query: route.query,
        }),
        name: 'talentProgram',
        meta: {
          title: 'Program',
          analyticsPageName: 'View Program',
          displayBackNavigation: true,
        },
      },
      // redirect /programs/ routes to /opportunities/programs
      {
        path: 'programs/:programId',
        redirect: (to) => {
          const { params, query } = to;
          return { path: `/talent/opportunities/programs/${params.programId}`, query: { ...query } };
        },
      },
      // redirect old programs search page to combined search page
      {
        path: 'programs',
        redirect: (to) => {
          const { query } = to;
          return { path: '/talent/opportunities', query: { t: 'programs', ...query } };
        },
      },
      {
        path: 'user/profile',
        components: {
          drawerView: () => import('../../views/user/Profile.vue'),
          default: () => import('../../views/dashboard/TalentDashboard.vue'),
        },
        props: {
          drawerView: (route) => ({
            query: route.query,
          }),
        },
        name: 'talentProfile',
        meta: {
          title: 'Profile',
          analyticsPageName: 'Profile',
          drawer: true,
        },
      },
      {
        path: 'chat',
        components: {
          drawerView: () => import('../../components/chat/CareerAssistant.vue'),
          default: () => import('../../views/dashboard/TalentDashboard.vue'),
        },
        name: 'talentCareerChat',
        meta: {
          title: 'Chat',
          analyticsPageName: 'Pursuits Career Assistant',
          drawer: true,
          allowClose: true,
          color: 'white',
        },
      },
      {
        path: 'talentQualities',
        name: 'talentQualities',
        redirect: { name: 'talentProfile', query: { t2: 'qualities' } },
      },
      {
        path: 'user/settings',
        component: () => import('../../views/user/Settings.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'User Settings',
          analyticsPageName: 'User Settings',
        },
      },
      {
        path: 'explore/locations',
        component: () => import('../../views/explore/Locations.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'Explore Locations',
          analyticsPageName: 'Explore Locations',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore/roles',
        component: () => import('../../views/explore/Roles.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'Explore Roles',
          analyticsPageName: 'Explore Roles',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore/groups',
        component: () => import('../../views/explore/OrgGroups.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'Explore Org Groups',
          analyticsPageName: 'Explore Organization Groups',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore/domains/:domainId',
        component: () => import('../../views/explore/Domain.vue'),
        props: (route) => ({
          query: route.query,
          domainId: Number.parseInt(route.params.domainId, 10) || undefined,
        }),
        meta: {
          title: 'Job Family Group',
          analyticsPageName: 'Work Domain',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore/pathways',
        component: () => import('../../views/explore/Pathways.vue'),
        meta: {
          title: 'Pathways',
          analyticsPageName: 'Pathways',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore/interests',
        component: () => import('../../views/explore/Interests.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'Explore Interests',
          analyticsPageName: 'Explore Interests',
          displayBackNavigation: true,
        },
      },
      {
        path: 'explore',
        component: () => import('../../views/explore/Dashboard.vue'),
        meta: {
          title: 'Explore',
          analyticsPageName: 'Explore',
        },
      },
      {
        path: 'dashboard',
        component: () => import('../../views/dashboard/TalentDashboard.vue'),
        props: (route) => ({
          ureq: route.query.ureq,
        }),
        name: 'talentDashboard',
        meta: {
          title: 'Dashboard',
          analyticsPageName: 'Talent Dashboard',
        },
      },
      {
        path: 'talent/search/:userId',
        components: {
          drawerView: () => import('../../views/search/Profile.vue'),
          default: () => import('../../views/dashboard/TalentDashboard.vue'),
        },
        props: {
          drawerView: (route) => ({
            userId: Number.parseInt(route.params.userId, 10) || undefined,
            query: route.query,
          }),
          default: (route) => ({
            query: route.query,
          }),
        },
        meta: {
          title: 'People Search',
          analyticsPageName: 'Talent Search Result Profile',
          drawer: true,
        },
      },
      {
        path: 'talent/search',
        component: () => import('../../views/search/Search.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'People',
          analyticsPageName: 'Talent Search',
        },
      },
      {
        path: 'talent',
        redirect: 'talent/search',
      },
      {
        path: '',
        redirect: 'dashboard',
      },
    ],
  },
];
