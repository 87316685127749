<template>
  <modal
    v-model="showDialog"
    e2e-tag="npsDialog"
    :persistent="false"
  >
    <template #pre-header>
      <!-- hide modal pre-header -->
      <v-spacer />
    </template>
    <vertical-split-container>
      <template #header>
        <ImageWrapper
          :src="$image('/img/requisition/application.png')"
          width="175"
          classes="nps-survey__image ml-3"
        />
        <v-card-text class="nps-survey__header-text">
          <h3 class="text-h4">
            How are we doing?
          </h3>
          <p>We are always seeking ways to be better. Help us improve!</p>
        </v-card-text>
      </template>
      <template #body>
        <SurveyUnit
          v-if="surveyId"
          :class="'nps-survey'"
          :minimal="true"
          :survey-id="surveyId"
          :load-answers="false"
          view-type="dialog"
          @completed="handleSurveyCompleted"
        />
      </template>
    </vertical-split-container>
  </modal>
</template>

<script>
import axios from 'axios';
import { mapState, mapStores } from 'pinia';
import { useSurveysStore } from '@/store';
import SurveyUnit from './Survey.vue';
import VerticalSplitContainer from '../containers/VerticalSplitContainer.vue';
import Modal from '../elements/Modal.vue';
import ImageWrapper from '../elements/ImageWrapper.vue';

export default {
  name: 'NpsSurvey',
  components: {
    VerticalSplitContainer,
    SurveyUnit,
    Modal,
    ImageWrapper,
  },
  props: {
    showSurvey: { type: Boolean, required: true },
  },
  data() {
    return {
      surveyName: 'nps_simple',
      surveyId: null,
      isEligible: false,
    };
  },
  computed: {
    ...mapStores(useSurveysStore),
    ...mapState(useSurveysStore, [
      'npsTriggered',
      'npsTriggerEvent',
    ]),
    showDialog: {
      get() {
        return this.showSurvey && this.isEligible;
      },
      set(value) {
        this.isEligible = value;
      },
    },
  },
  watch: {
    showSurvey(newVal) {
      if (newVal) {
        this.checkNpsEligibility();
      }
    },
  },
  methods: {
    async getLatestNpsSurveyId() {
      const { data } = await axios.get('/v1/surveys/', {
        params: {
          name: this.surveyName,
          latestVersion: true,
          results: false,
          includeConfig: true,
        },
      });
      const [survey] = data;
      this.surveyId = survey ? survey.id : survey;
    },
    async checkNpsEligibility() {
      if (!this.surveyId) {
        await this.getLatestNpsSurveyId();
      }

      if (!this.surveyId) throw new Error(`Cannot find NPS survey id with name ${this.surveyName}`);
      // 1. check localstorage of date & number of logins, eligible if:
      //  A. date does not exist
      //  B. date is older than 3 weeks
      //  C. login count is 4 TODO: localstorage for this value
      // 2. ajax request to /api/v1/surveys/:id/should-show returns positive
      const lastNpsDateString = localStorage.getItem('last_nps_survey_date');
      let threeWeeksSinceLastNps = false;

      if (lastNpsDateString) {
        const lastNpsDate = new Date(Date(lastNpsDateString));
        const timeDiffInDays = (Date.now() - lastNpsDate.getTime()) / (1000 * 60 * 60 * 24);
        threeWeeksSinceLastNps = timeDiffInDays >= 21;
      }

      if (((lastNpsDateString && threeWeeksSinceLastNps) || !lastNpsDateString)
        && this.npsTriggered) {
        const { data } = await axios.get(`/v1/surveys/${this.surveyId}/should-show`, {
          params: {
            event: this.npsTriggerEvent,
          },
        });

        this.isEligible = data.showSurvey;

        if (!this.isEligible) {
          await this.surveysStore.hideNpsSurvey();
        }
      }
    },
    async handleSurveyCompleted() {
      this.isEligible = false;
      localStorage.setItem('last_nps_survey_date', Date.now().toString());
      await this.surveysStore.hideNpsSurvey();
    },
  },
};
</script>

<style lang="scss">
  .nps-survey {
    &__after-message {
      text-align: center;
    }

    &__card-actions {
      display: flex;
      flex-direction: row-reverse;
    }

    &__header-text {
      display: inline-block;
      width: 300px !important;
    }

    &__image {
      display: inline-block !important;
      position: relative;
      top: 10px;
    }

    /* SurveyJS Overrides */
    $survey-border-radius: 50%;

    .surveyJS-root label span {
      padding-left: 0;
    }

    .surveyJS-radiogroup-root {
      padding-bottom: 15px;
      text-align: center;
      width: 100%;

      div {
        display: inline-block;
        background-color: white;
        margin-right: 3%;
        width: 50px;
        height: 50px;
        border-radius: $survey-border-radius;
      }

      .checked {
        background-color: #B5D7FC;
      }

      label {
        display: inline-block;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border: 1px solid #f1f1f1;
        border-radius: $survey-border-radius;
        font-size: 16px;
        box-shadow: #e8e8e8 3px 3px 15px 0;
        span {
          position: static;
          font-size: 1.5rem;
          vertical-align: middle;
        }

        &:hover {
          transition: background-color 115ms ease;
          background-color: #dbf3ff;
        }
      }

      input[type=radio] {
        opacity: 0;
        position: fixed;
      }
    }

    .surveyJS-root {
      textarea {
        background-color: white;
        border-radius: 10px;
        border-color: #f1f1f1;
        box-shadow: #e8e8e8 3px 3px 15px 0;
      }
    }
  }
</style>
