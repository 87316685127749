import Vue from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import {
  get, isEmpty, has, flatten, uniq,
  pick, sortBy,
} from 'lodash-es';
import globals from '@/globals';
import type { Role } from '@/types/role';
import type {
  CompanyRole,
  CompanySettings,
  CompanyDomain,
  Stages,
} from '@/types/company';
import type {
  Requisition, RequisitionType, RequisitionTypeOption, RequisitionWorkflow,
} from '@/types/requisition';
import { sentenceCase as sentenceCaseFunc } from '@/utils/string';
import type { SearchResult } from '@/types/search';
/* eslint-disable import/no-cycle */
import { getUserNavTabs } from '@/services/users';
import { getRequisitions } from '@/services/companies/requisition';
import type { Score } from '@/types/match';
import { useUserStore } from './user';
import { useAnalyticsStore } from './analytics';
/* eslint-enable import/no-cycle */

interface CompanyState {
  companyRoles: CompanyRole[],
  canonicalRoles: CompanyRole[],
  companySettings: Partial<CompanySettings>,
  companyPrograms: object[],
  globalActions?: {
    requisition: string[],
    program: string[],
    level: string[],
  },
  navigationOptions: {
    operations: string[],
    talent: string[],
  },
  navigationOptionsLoaded: boolean,
  programSearchParams: object,
  programSearchResults: object[],
  programSearchTotalResults: number,
  requisitionRankingSearchParams: object,
  requisitionRankingSearchResults: object[],
  requisitionSearchResults: Requisition[],
  requisitionSearchParams: object,
  requisitionSearchTotalResults: number,
  requisitionWorkflowOptions?: RequisitionWorkflow[],
  role: Partial<Role>,
  roleSearchResults: Partial<Role>[],
  roleSearchTotalResults: number,
  roleSearchParams: object,

  requisitionProcessStages: Stages[],
  requisitionStates: Stages[],
  requisitionTypes: RequisitionType[]
  programStates: Stages[],
  applicationProcessStages: Stages[],
  applicationStates: Stages[],

  companyTenure: object,
  groupTenure: object,
  roleTenure: object,

  titleTransitions: {
    fromTitle: string,
    fromRoleId: number,
    toTitle: string,
    toRoleId: number,
  }[],
  companyLevels: {
    id: number,
    name: string,
    numValue: number,
  }[],
  companyWorkerTypes: {
    id: number,
    name: string,
    isDefault: boolean,
  }[],
  companyPromotedSkills: {
    id: number,
    name: string,
  }[],
  companyDomains: CompanyDomain[],
  canonicalDomains: CompanyDomain[],
  domainlessCompanyRoles: object[],
  locations: Location[]
}

const getDefaultState = (): CompanyState => (
  {
    companyRoles: [],
    canonicalRoles: [],
    companySettings: {},
    companyPrograms: [],
    globalActions: undefined,
    navigationOptions: {
      operations: [],
      talent: [],
    },
    navigationOptionsLoaded: false,
    programSearchParams: {},
    programSearchResults: [],
    programSearchTotalResults: 0,
    requisitionRankingSearchParams: {},
    requisitionRankingSearchResults: [],
    requisitionSearchResults: [],
    requisitionSearchParams: {},
    requisitionSearchTotalResults: 0,
    requisitionWorkflowOptions: [],
    requisitionTypes: [],
    role: {},
    roleSearchResults: [],
    roleSearchTotalResults: 0,
    roleSearchParams: {},

    requisitionProcessStages: [],
    requisitionStates: [],
    programStates: [],
    applicationProcessStages: [],
    applicationStates: [],

    companyTenure: {},
    groupTenure: {},
    roleTenure: {},
    titleTransitions: [],
    companyLevels: [],
    companyWorkerTypes: [],
    companyPromotedSkills: [],
    companyDomains: [],
    canonicalDomains: [],
    domainlessCompanyRoles: [],
    locations: [],
  }
);

// Translate the requisition type object returned from the API to the format used in the UI.
export function formatReqTypeOptions(reqTypes): RequisitionTypeOption[] | [] {
  return reqTypes
    ? reqTypes.map((rt) => ({
      internalName: rt.name,
      name: rt.displayName,
      ...pick(rt, ['id', 'description', 'settings', 'fields', 'displayOrder']),
      // Below fields are deprecated. Should use settings instead.
      onlyForInterests: rt.settings.isAllowedAsInterest
        && !rt.settings.isAllowedForRequisitionCreation
        && !rt.settings.isAllowedForSearch,
      isReadOnly: rt.settings.isAllowedForRequisitionCreation,
      isExemptFromManagerApproval: rt.settings.isExemptFromManagerApproval,
    })) : [];
}

export const useCompanyStore = defineStore('company', {
  state: (): CompanyState => getDefaultState(),
  getters: {
    roleOpenRequisitions: (state) => state.role.requisitions,
    // All requisition types defined at the company.
    requisitionTypeOptions(state): RequisitionTypeOption[] {
      return formatReqTypeOptions(state.requisitionTypes);
    },
    requisitionTypesSubjectToManagerApproval(): RequisitionTypeOption[] {
      return this.requisitionTypeOptions
        ? this.requisitionTypeOptions.filter((type) => !type.settings?.isExemptFromManagerApproval)
        : [];
    },
    // A subset of requisitionTypeOptions. These are the types that appear in e.g. search filters.
    requisitionReadTypeOptions(): RequisitionTypeOption[] {
      return this.requisitionTypeOptions
        ? this.requisitionTypeOptions.filter((type) => type.settings?.isAllowedForSearch)
        : [];
    },
    // A subset of requisitionTypeOptions. These are the types that can be created directly in fm-client.
    requisitionCreateTypeOptions(): RequisitionTypeOption[] {
      return this.requisitionTypeOptions
        ? this.requisitionTypeOptions.filter((type) => type.settings?.isAllowedForRequisitionCreation)
        : [];
    },
    // A subset of requisitionTypeOptions. These are the types a user can create based on the user's authz roles.
    userRequisitionCreateTypeOptions(): RequisitionTypeOption[] {
      const userStore = useUserStore();
      return (this.requisitionCreateTypeOptions || []).filter(
        (type) => userStore.user?.createRequisitionTypes?.find((rt) => rt.id === type.id),
      );
    },
    displayPromotedSkills: (state) => !!get(state.companySettings, 'featureSwitches.promotedSkills', false),
    displayInterests: (state) => !!get(state.companySettings, 'featureSwitches.interests', false),
    companyRequiredAcknowledgements: (state) => state.companySettings.acknowledgements || [],
    canCreateRequisition: (state) => state.globalActions?.requisition?.includes('write'),
    canCreateProgram: (state) => state.globalActions?.program?.includes('write'),
    canViewUserLevel: (state) => state.globalActions?.level?.includes('read'),
    hasOperationsAuthorization: (state) => !isEmpty(state.navigationOptions.operations),
    // ensures a single navType is included in navigationOptions.operations list
    hasOperationsNavOption: (state) => (navType) => !navType
    || state.navigationOptions.operations.includes(navType),
    // ensures at least one in the list of navTypes is included in navigationOptions.operations list
    hasAnyOperationsNavOption: (state) => (navTypes) => !navTypes || navTypes
      .some((navType) => state.navigationOptions.operations.includes(navType)),
    hasTalentNavOption: (state) => (navType) => !navType || state.navigationOptions.talent.includes(navType),
    defaultRequisitionWorkflow: (state) => state.requisitionWorkflowOptions?.find((wf) => wf.isCompanyDefault),
    hasReadLevelsPermission: (state) => has(state, 'globalActions.level') && state.globalActions?.level.includes('read'),
    displayMentorship: (state) => !!get(state.companySettings, 'featureSwitches.mentorship', false),
    displayMentorshipV2: (state) => !!get(state.companySettings, 'featureSwitches.mentorshipV2', false),
    useGeneratedBio: (state) => !!get(state.companySettings, 'featureSwitches.useGeneratedBio', true),
    showTenureYears: (state) => !!get(state.companySettings, 'featureSwitches.showTenureYears', false),
  },
  actions: {
    async loadRole({ roleId }) {
      const userStore = useUserStore();
      if (!userStore.userCompanyId) {
        throw new Error('Current user has no company id for loading role');
      }

      const [{ data: role }, { data: requisitionResp }] = await Promise.all([
        axios.get(`/v1/companies/${userStore.userCompanyId}/roles/${roleId}`, {
          params: {
            eagerLoad: ['features', 'topSkills', 'groups', 'domains'],
          },
        }),
        axios.get(`/v1/companies/${userStore.userCompanyId}/requisitions`, {
          params: {
            states: ['open'],
            roleIds: [roleId],
          },
        }),
      ]);
      this.role = { ...role, requisitions: requisitionResp.results };
    },
    async searchRoles(params) {
      // store the latest role search parameters
      this.roleSearchParams = params;
      const userStore = useUserStore();
      const analyticsStore = useAnalyticsStore();
      const { data } = await axios.get(
        `/v1/companies/${userStore.userCompanyId}/roles`,
        { params },
      );

      // async API calls might not return in same order they are called,
      // so compare search parameters to latest and ignore response if out of date
      if (params === this.roleSearchParams) {
        this.roleSearchResults = data.results;
        this.roleSearchTotalResults = data.totalResults;

        // Track 'Search and Filter Roles' event
        const properties = {
          keywords: params.searchString,
          filters: params,
          totalResults: data.totalResults,
        };
        analyticsStore.trackEvent({ eventName: 'Search and Filter Roles', propertiesObj: properties });
      }
    },
    clearCompany() {
      Vue.prototype.$log.debug('clearCompany');
      Object.assign(this, getDefaultState());
    },
    async searchRequisitions(params) {
      const userStore = useUserStore();
      const analyticsStore = useAnalyticsStore();
      if (!userStore.userCompanyId) {
        throw new Error('Current user has no company id for searching requisitions');
      }

      this.requisitionSearchParams = params.search;

      const data: SearchResult<Requisition & Score> = await getRequisitions(
        userStore.userCompanyId,
        params.search,
      );

      // async API calls might not return in same order they are called,
      // so compare search parameters to latest and ignore response if out of date
      if (params.search === this.requisitionSearchParams) {
        this.requisitionSearchResults = data.results;
        this.requisitionSearchTotalResults = data.totalResults;

        if (!params.trackingDisabled) {
          // Track 'Search and Filter Requisitions' event
          const properties = {
            companyId: userStore.userCompanyId,
            keywords: params.searchString,
            filters: params,
            totalResults: data?.totalResults,
            requisitions: data
              && JSON.stringify(data.results.map((r) => ({
                requisitionId: r.id,
                fromRoleId: userStore.userRole?.id,
                toRoleId: r.roleId,
                score: r.score,
              }))),
          };
          analyticsStore.trackEvent({ eventName: 'Search and Filter Requisitions', propertiesObj: properties });
        }
      }
    },
    async searchRequisitionRankings(params) {
      this.requisitionRankingSearchParams = params;

      const userStore = useUserStore();
      const { data } = await axios.get(
        `/v1/companies/${userStore.userCompanyId}/requisitions/rankings`,
        { params },
      );

      // async API calls might not return in same order they are called,
      // so compare search parameters to latest and ignore response if out of date
      if (params === this.requisitionRankingSearchParams) {
        this.requisitionRankingSearchResults = data;
      }
    },
    async searchPrograms(params) {
      this.programSearchParams = params.search;

      const userStore = useUserStore();
      const analyticsStore = useAnalyticsStore();
      const { data } = await axios.get(
        `/v1/companies/${
          userStore.userCompanyId
        }/programs`,
        { params: params.search },
      );

      // async API calls might not return in same order they are called,
      // so compare search parameters to latest and ignore response if out of date
      if (params.search === this.programSearchParams) {
        this.programSearchResults = data.results;
        this.programSearchTotalResults = data.totalResults;

        if (!params.trackingDisabled) {
          // Track 'Search and Filter Programs' Event
          const properties = {
            keywords: params.searchString,
            filters: params,
            totalResults: data.totalResults,
          };
          analyticsStore.trackEvent({ eventName: 'Search and Filter Programs', propertiesObj: properties });
        }
      }
    },
    async postRequisitionRankings(params) {
      const userStore = useUserStore();
      const { data } = await axios.post(
        `/v1/companies/${userStore.userCompanyId}/requisitions/rankings`,
        { requisitions: params.requisitions, nullify: params.nullify },
      );
      this.requisitionRankingSearchResults = data;
    },
    async loadGroupTenure({ groupId }) {
      const userStore = useUserStore();
      const { data } = await axios.get(`/v1/companies/${
        userStore.userCompanyId
      }/insights/tenure/group/${groupId}`);
      this.groupTenure = data;
    },
    async loadCompanyRoles(params = {}) {
      if (!isEmpty(this.companyRoles)) return;
      const userStore = useUserStore();
      const { data } = await axios.get(
        `/v1/companies/${userStore.userCompanyId}/roles`,
        { params },
      );
      this.companyRoles = data.results;
    },
    async loadCanonicalRoles(params = {}) {
      if (!isEmpty(this.canonicalRoles)) return;
      const userStore = useUserStore();
      const { data } = await axios.get(
        `/v1/companies/${userStore.userCompanyId}/roles?useCanonical=true`,
        { params },
      );
      this.canonicalRoles = data.results;
    },
    async loadRoleTenure({ roleId }) {
      const userStore = useUserStore();
      const { data } = await axios.get(`/v1/companies/${
        userStore.userCompanyId
      }/insights/tenure/role/${roleId}`);
      this.roleTenure = data;
    },
    async loadCompanyTenure() {
      const userStore = useUserStore();
      const { data } = await axios.get(`/v1/companies/${
        userStore.userCompanyId
      }/insights/tenure`);
      this.companyTenure = data;
    },
    async loadCompanyProcessStages() {
      // loadCompanyProcessStages is responsible for setting many values including state.requisitionStates
      // if state.requisitionStates is set, it means the API call was already made, so do not duplicate
      if (!isEmpty(this.requisitionStates)) {
        Vue.prototype.$log.debug('Company process stages are already loaded');
        return;
      }
      const userStore = useUserStore();
      const { data } = await axios.get(`/v1/companies/${userStore.userCompanyId}/process-stages`);
      this.setCompanyStatesProcessStages(data);
    },
    setCompanyStatesProcessStages(data) {
      this.requisitionProcessStages = data.requisition.processStages.map((stage) => ({
        id: stage.name,
        name: stage.displayName,
        defaultSelect: stage.defaultSelect || false,
      }));

      this.requisitionStates = data.requisition.states.map((reqState) => ({
        id: reqState.name,
        name: reqState.name.split('_').map((s) => sentenceCaseFunc(s)).join(' ').replace(globals.requisition.submitted, globals.requisition.submittedReplacement),
        defaultSelect: reqState.defaultSelect || false,
      }));

      this.applicationProcessStages = data.application.processStages.map((stage) => ({
        name: stage.name,
        displayName: stage.displayName,
        defaultSelect: stage.defaultSelect || false,
        displayInApplicationWorkflow: !has(stage, ['displayInApplicationWorkflow']) ? true : stage.displayInApplicationWorkflow,
      }));

      this.applicationStates = data.application.states.map((appState) => ({
        id: appState.name,
        name: appState.name.split('_').map((s) => sentenceCaseFunc(s)).join(' ').replace(globals.requisition.submitted, globals.requisition.submittedReplacement),
        defaultSelect: appState.defaultSelect || false,
      }));

      this.programStates = data.program.states.map((prgmState) => ({
        id: prgmState.name,
        name: prgmState.name.split('_').map((s) => sentenceCaseFunc(s)).join(' '),
        defaultSelect: prgmState.defaultSelect || false,
      }));
    },
    async loadGlobalActions() {
      if (this.globalActions) return;
      const userStore = useUserStore();
      const response = await axios.get(`/v1/companies/${userStore.userCompanyId}/global-actions`);
      this.globalActions = response.data;
    },
    async loadNavigationOptions() {
      // load nav tabs from API
      if (!isEmpty(this.navigationOptions.talent)) return;
      const data = await getUserNavTabs();
      this.navigationOptions = {
        talent: data.talentTabs,
        operations: data.operationsTabs,
      };
      this.navigationOptionsLoaded = true;
    },
    async loadCompanyPrograms() {
      const userStore = useUserStore();
      const { data } = await axios
        .get(`/v1/companies/${userStore.userCompanyId}/programs?states[]=open`);

      this.companyPrograms = data.results;
    },
    async loadTitleTransitions() {
      // Skip loading if it's already loaded as this is a heavy load
      if (!isEmpty(this.titleTransitions)) return;

      Vue.prototype.$log.debug('loadTitleTransitions starts');
      // load title transitions
      const userStore = useUserStore();
      const { data: titleTransitions } = await axios.get(`/v1/companies/${
        userStore.userCompanyId
      }/insights/title-transitions`);
      this.titleTransitions = titleTransitions;
      Vue.prototype.$log.debug('loadTitleTransitions ends');
    },
    async loadLevelsAndWorkerTypes() {
      const userStore = useUserStore();
      const { data } = await axios.get(`/v1/companies/${userStore.userCompanyId}/`);
      this.companyLevels = data?.levels;
      this.companyWorkerTypes = data.workerTypes;
    },
    getRequisitionById({ id, params }) {
      const userStore = useUserStore();
      return axios.get(`/v1/companies/${userStore.userCompanyId}/requisitions/${id}`, { params });
    },
    resumeRequisitionById(id) {
      const userStore = useUserStore();
      return axios.post(`/v1/companies/${userStore.userCompanyId}/requisitions/${id}/resume`);
    },
    getRequisitionActions(id) {
      const userStore = useUserStore();
      return axios.get(`/v1/companies/${userStore.userCompanyId}/requisitions/${id}/actions`);
    },
    getRequisitionOptionByType(requisitionTypeName) {
      const requisitionType = this.requisitionTypeOptions.find((rt) => rt.internalName === requisitionTypeName);
      if (requisitionType) {
        return requisitionType;
      }

      throw new Error(`Requisition type ${requisitionTypeName} not found`);
    },
    async getRequisitionWorkflowOptions() {
      // Skip loading if it's already loaded
      if (!isEmpty(this.requisitionWorkflowOptions)) {
        Vue.prototype.$log.debug('Requisition workflow options are already loaded');
        return;
      }
      const userStore = useUserStore();
      const { data: response } = await axios.get(`/v1/companies/${userStore.userCompanyId}/requisition-workflows`);
      this.requisitionWorkflowOptions = response;
    },
    async getCompanyPromotedSkills() {
      // Skip loading if it's already loaded
      if (!isEmpty(this.companyPromotedSkills)) {
        Vue.prototype.$log.debug('Promoted skills are already loaded');
        return;
      }
      const { data } = await axios.get('/v1/skills/promoted-skills');
      this.companyPromotedSkills = data.map((skill) => ({
        id: skill.skill.id,
        name: skill.skill.name,
        companySkillId: skill.id,
      }));
    },
    async loadDomains() {
      // check if domains are already loaded
      if (!isEmpty(this.companyDomains)) {
        Vue.prototype.$log.debug('Domains requested. Domains are already loaded.');
        return;
      }
      const { data } = await axios.get('/v1/domains');
      this.companyDomains = sortBy(data, 'name');
      // ensure domains are loaded so domainless company roles can be set
      if (isEmpty(this.companyRoles)) await this.loadCompanyRoles();

      const rolesWithADomain = uniq(flatten(data.map((cD) => cD.roles.map((r) => r.id))));
      const rolesWithOutADomain = this.companyRoles.filter((r) => !rolesWithADomain.includes(r.id));
      this.domainlessCompanyRoles = rolesWithOutADomain;
    },
    async loadCanonicalDomains() {
      // check if domains are already loaded
      if (!isEmpty(this.canonicalDomains)) {
        Vue.prototype.$log.debug('Canonical domains requested. Canonical domains are already loaded.');
        return;
      }
      const { data } = await axios.get('/v1/domains?useCanonical=true');
      this.canonicalDomains = data;
    },
    getDomain(domainId) {
      const userStore = useUserStore();
      if (!userStore.userCompanyId) {
        throw new Error('Current user has no company id for loading company domains');
      }
      return axios.get(`/v1/domains/${domainId}`);
    },
    async setCompanySettingsFromCurrentUser(settings) {
      this.companySettings = settings;
    },
    async patchCompanySettings(settings) {
      const userStore = useUserStore();
      const { data } = await axios.patch(`/v1/companies/${userStore.userCompanyId}/settings`, settings);
      this.companySettings = data.settings;
    },
    async loadRequisitionTypes(
      { graph } = { graph: '{ id, name, displayName, description, settings, fields }' },
    ) {
      const userStore = useUserStore();
      const { data } = await axios.post('/graphql', {
        query: `
          {
            company (id: ${userStore.userCompanyId}) {
              requisitionTypes ${graph}
            }
          }`,
      });

      this.requisitionTypes = data.data.company.requisitionTypes;
    },
    getLabelAlias(name: string, { sentenceCase = false, plural = false } = {}) {
      const { labelAlias = {} } = this.companySettings ?? {};

      let label = labelAlias[name] ?? name;

      label = label[sentenceCase ? 'sentenceCase' : 'lowerCase'];
      label = label[plural ? 'plural' : 'singular'];

      return label;
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot));
}
