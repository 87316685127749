import { isEmpty, isNil } from 'lodash-es';
import { DateTime, Duration } from 'luxon';
import globals from '../globals';

/**
 * @param date target date
 * @param max compare date
 * @returns true if target date is on or before comapre date
 */
export const isDateOnOrBefore = (date: DateTime, max: DateTime): boolean => {
  if (isEmpty(date) || isEmpty(max)) return true; // skip empty date
  return date <= max;
};

export const isDateOnOrAfter = (date: DateTime | string, min: DateTime | string): boolean => {
  if (isEmpty(date) || isEmpty(min)) return true; // skip empty date
  const dateDateTime = typeof date === 'string' ? DateTime.fromISO(date) : date;
  const minDateTime = typeof min === 'string' ? DateTime.fromISO(min) : min;
  return minDateTime <= dateDateTime;
};

export const isDateInRange = (date: string | DateTime, min: DateTime, max: DateTime): boolean => {
  if (isEmpty(date) || isEmpty(min) || isEmpty(max)) return true; // skip empty date
  const value = typeof date === 'string' ? DateTime.fromISO(date) : date;
  return isDateOnOrAfter(value, min) && isDateOnOrBefore(value, max);
};

/**
 *
 * @param date
 * @returns true if a date string is valid
 */
export const isValidDate = (date: string): boolean => {
  if (isEmpty(date)) return true; // skip empty
  return DateTime.fromISO(date).isValid;
};

export const isValidDateByType = (date, type: 'month' | 'date'): boolean => {
  if (isEmpty(date)) return true; // skip empty date
  const parsedDate = DateTime.fromFormat(
    date,
    type === 'month' ? globals.dateFormatMonthYearOnly : globals.datePickerFormat,
  );
  return parsedDate.isValid;
};

/**
 * add "!" as a type assertion to tell TypeScript that the return value of the toISODate() method will never be null.
 * @returns ISO 8601-compliant string representation of today
 */
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
export const today = (): string => DateTime.local().toISODate()!;

// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
export const getPreviousDate = (daysAgo: number): string => DateTime.local().minus({ days: daysAgo }).toISODate()!;

// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
export const getFutureDate = (daysFromNow: number): string => DateTime.local().plus({ days: daysFromNow }).toISODate()!;

export const formatTimelineDate = (
  date: string | null | DateTime,
  dateFormat = globals.dateFormatAbbrMonthYearOnly,
): string => {
  if (date === null) return 'Present';
  return (typeof date === 'string' ? DateTime.fromISO(date) : date)
    .toFormat(dateFormat);
};

export const isFutureDate = (endMonth?: number | null, endYear?: number | null): boolean => {
  // compares provided date to now, if provided date is bigger than it is a future date
  if (!!endMonth && !!endYear) return DateTime.now() < DateTime.fromISO(DateTime.utc(endYear, endMonth).toString());
  if (endYear) return DateTime.now() < DateTime.fromISO(DateTime.utc(endYear).toString());
  return false;
};

export const formatDate = (value) => DateTime.fromISO(String(value)).toLocaleString(DateTime.DATE_MED);

export const formatDistance = (datetime: string) => DateTime.fromISO(datetime).toRelative();

export const formatDateWithDistance = (value) => `${DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)} (${DateTime.fromISO(value).toRelative()})`;

// e.g. Apr 16, 2021 4:20 AM EST
export const formatUserDateTime = (datetime: string) => DateTime.fromISO(datetime).toFormat(globals.dateTimeFormat);

/**
 * Calculate the number of days between the parameter date and now.
 * Days will return in absolute value and round up to a full day
 *
 * @param date
 * @returns days
 */
export const daysBeforeNow = (date: string): number => Math.ceil(
  Math.abs(DateTime.fromISO(date, { zone: 'utc' }).diffNow('days').days),
);

/**
 * Calculate the number of days (integer) between the parameters startDate and endDate/now.
 * If the date formate is yyyy-MM, will calculate based on the first day of the month.
 * @param startDate
 * @param endDate
 * @returns days
 */
export const durationDays = (startDate: string, endDate: string | null | undefined): number => {
  const end = endDate ? DateTime.fromISO(endDate, { zone: 'utc' }) : DateTime.utc();
  const start = DateTime.fromISO(startDate, { zone: 'utc' });
  // Including 'hours' here ensures that the returned days value will always be an integer, even if
  // endDate was not provided and thus the current timestamp was used as the end date of the calculated
  // Duration.
  return Math.abs(start.diff(end, ['days', 'hours']).days);
};

/**
 * Convert days to rounded years, 0.5 year increment rounding up.
 * 0 - 182 days (including) → 0.5 years;
 * 182 - 365 days (including) → 1 year;
 * 365 - 547 days (including) → 1.5 years;
 *
 * @param days
 * @returns rounded years
 */
export const convertDaysToRoundedYears = (days?: number): null | number => {
  if (isNil(days)) return null;
  // convert days to years
  const years = Duration.fromObject({ days }).as('years');
  // 1. Multiply the years by 2.
  // 2. Use Math.ceil to round up to the nearest whole or half-year.
  // 3. Finally, divide by 2 to get the result as a half-year increment.
  return Math.ceil(years * 2) / 2;
};
