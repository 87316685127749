// eslint-disable-next-line import/no-cycle
import fmApiClient from '@/services/fmApiClient';
import type { Application } from '@/types/application';
import { VERSION_V1 } from '../config';

interface RequireApplicationApprovalForDataType {
  requisitionTypes: string[],
  isApprovalRequired: boolean;
  requisitionStartDateRange?: { from: string, to: string }
  requisitionWeeklyCommitmentHours?: { gt: number }
}

export const patchSubordinate = async (userId: number, data: RequireApplicationApprovalForDataType):
Promise<{ userId: number, requireApplicationApprovalFor: Partial<RequireApplicationApprovalForDataType> }> => fmApiClient.patch(`${VERSION_V1}/users/${userId}/applications/required-approvals`, data);

interface ParamsType {
  sort?: string[],
  states?: string[],
  excludeRequisitionTypes?: string[],
  eagerLoad?: string[],
  limit?: number,
  offset?: number,
  includeActions?: boolean,
  searchString?: string,
  userStates?: string[],
  excludeLms?: boolean
}

export const getUserApplications = async (userId: number, params: ParamsType): Promise<{ totalResults: number, results: Application[] }> => fmApiClient.get(`${VERSION_V1}/users/${userId}/applications`, { params });

interface CreateApplicationDataType {
  requisitionId: number,
  applicantResponses?: string,
}

export const postUserApplication = async (userId: number, data: CreateApplicationDataType): Promise<{ data: Application }> => fmApiClient.post(`${VERSION_V1}/users/${userId}/applications`, data);
