<script setup lang="ts">
import { useImage } from '@/utils/image';
import { markNotificationRead } from '@/services/users/notification';
import { get, debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useUserStore, useAuthStore } from '@/store';
import globals, { iconMap } from '@/globals';
import { computed, watch, ref } from 'vue';
import { type Notification as NotificationType } from '@/types/notification';
import Notification from '../elements/Notification.vue';
import Badge from '../elements/Badge.vue';
import SecondaryContainer from '../containers/SecondaryContainer.vue';
import ImageWrapper from '../elements/ImageWrapper.vue';
import Btn from '../elements/Button.vue';

const menu = ref<boolean>(false);
const isLoadingData = ref<boolean>(false);

const userStore = useUserStore();
const {
  currentView,
  talentNotifications,
  talentNotificationsTotalResults,
  operationsNotifications,
  operationsNotificationsTotalResults,
  user,
} = storeToRefs(userStore);
const {
  loadCurrentUserNotificationFeed,
} = userStore;

const { isAuthenticated } = storeToRefs(useAuthStore());

const displayTalentView = computed<boolean>(() => currentView.value === 'talent');

const feedItems = computed<NotificationType[]>(() => (
  displayTalentView.value
    ? talentNotifications.value
    : operationsNotifications.value
));

const feedTotalResults = computed<number>(() => (
  displayTalentView.value
    ? talentNotificationsTotalResults.value
    : operationsNotificationsTotalResults.value
));

const unreadFeedItems = computed<NotificationType[]>(() => feedItems.value.filter((item) => item.state === 'unread'));

const numUnread = computed<string | number>(() => (unreadFeedItems.value.length > 9 ? '9+' : unreadFeedItems.value.length));

const loadNotificationData = async (params = { limit: globals.pagination.defaultRequestLimit, offset: 0 }) => {
  await loadCurrentUserNotificationFeed({ userView: currentView.value, params });
};

const loadMoreNotifications = debounce(async () => {
  if (feedItems.value.length < feedTotalResults.value) {
    isLoadingData.value = true;
    const params = {
      limit: globals.pagination.defaultRequestLimit,
      offset: feedItems.value.length,
    };

    await loadNotificationData(params);
    isLoadingData.value = false;
  }
}, 300);

const markFeedItemsAsRead = async () => {
  if (feedItems.value.length > 0 && user.value?.id && currentView.value) {
    await markNotificationRead(
      user.value?.id,
      feedItems.value[0].id,
      currentView.value,
    );

    const params = {
      limit: feedItems.value.length,
      offset: 0,
    };
    await loadNotificationData(params);
  }
};

const path = (feedItem) => {
  const PATH_MAP = {
    survey: `/talent/survey/${feedItem.content.config && feedItem.content.config.id}`,
    role: '/talent/experiences/add',
    settings: '/talent',
    profile: '/talent/user/profile',
    experiences: '/talent/experiences/review',
    talentProfile: `/talent/talent/search/${feedItem.content.config?.pathIds?.userId}`,
  };

  return PATH_MAP[feedItem.content.type]
        || PATH_MAP[feedItem.content.config && feedItem.content.config.category] || null;
};

const routerPath = (feedItem) => {
  if (feedItem.content.config.namedPath) {
    const query = feedItem.content.config.query || {};
    if (feedItem.notificationId) {
      // add notificationId and channel to enable click tracking for analytics
      query.notificationId = feedItem.notificationId;
      query.channel = 'feed';
    }
    return {
      name: feedItem.content.config.namedPath,
      params: feedItem.content.config.pathIds || {},
      query,
    };
  }

  return path(feedItem);
};

const iconObj = (feedItem) => {
  let iconType;
  if (get(feedItem, 'content.config.iconType')) {
    iconType = feedItem.content.config.iconType;
  } else if (get(feedItem, 'content.config.header.iconType')) {
    iconType = feedItem.content.config.header.iconType;
  }
  return iconMap[iconType] || {};
};

const tagline = (feedItem) => (
  get(feedItem, 'content.config.header.text')
    ? feedItem.content.config.header.text
    : null
);

watch(currentView, async (view) => {
  if (view) {
    await loadNotificationData();
  }
}, { immediate: true });

watch(menu, async (isMenuOpen) => {
  // mark all feed items as read when menu closes
  if (!isMenuOpen) {
    await markFeedItemsAsRead();
  }
});
</script>

<template>
  <VMenu
    v-if="isAuthenticated"
    v-model="menu"
    maxWidth="350"
    minWidth="275"
    offsetY
  >
    <SecondaryContainer
      class="overflow-y-auto"
      style="max-height:500px"
    >
      <VRow
        noGutters
        align="center"
        class="flex-nowrap"
      >
        <h4 class="pa-3 text-h4">
          Notifications
        </h4>
        <VSpacer />
        <Btn
          v-if="unreadFeedItems.length > 0"
          type="text"
          class="px-2 mr-2"
          @click.stop.prevent="markFeedItemsAsRead"
        >
          Mark all read
        </Btn>
      </VRow>
      <VDivider />
      <Notification
        v-for="(item, index) in feedItems"
        :key="item.id"
        :routerPath="routerPath(item)"
        :unread="item.state === 'unread'"
        :icon="iconObj(item).icon"
        :displayIcon="displayTalentView"
        :color="iconObj(item).color"
        :tagline="tagline(item)"
        :title="item.content.config.title"
        :description="item.content.config.description"
        :isHtml="item.content.config.isHtml"
        :timestamp="item.createdAt"
        :last="(index + 1) === feedTotalResults"
        :action="item.content.config?.action"
        data-e2e="notificationMenuItem"
      />
      <div
        v-if="feedItems.length < feedTotalResults"
        class="ma-3"
      >
        <Btn
          v-intersect="loadMoreNotifications"
          :disabled="isLoadingData"
          :loading="isLoadingData"
          type="secondary"
          block
          @click.stop.prevent="loadMoreNotifications"
        >
          Show more
        </Btn>
      </div>
      <div
        v-if="feedTotalResults === 0"
        class="pa-6 text-center"
      >
        <ImageWrapper
          :src="useImage('/img/yeti/empty-280.png')"
          width="140"
          height="140"
          classes="mx-auto mb-2"
        />
        No notifications right now
      </div>
    </SecondaryContainer>
    <template
      #activator="{ on }"
    >
      <Btn
        :class="{ 'v-btn--active': menu }"
        class="px-2 py-7"
        style="min-width: 0;"
        type="text"
        color="secondary4"
        data-e2e="headerNotificationButton"
        v-on="on"
      >
        <Badge
          :content="numUnread"
          :value="numUnread"
          class="user-notification-count"
          color="primary"
          inline
          e2eTag="headerNotificationBadge"
        >
          <VIcon class="pl-1">
            notifications
          </VIcon>
        </Badge>
      </Btn>
    </template>
  </VMenu>
</template>

<style lang="scss">
  .user-notification-count .v-badge__wrapper {
    margin-left: 0 !important;
  }
</style>
