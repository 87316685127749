import { isLocal } from './env';

/**
 * Get asset URL from the given path.
 * @param path directory path under src/assets
 * @returns asset path
 */
export const useImage = (path: string): string => {
  if (path === undefined || path.length === 0) { throw new Error('Path cannot be empty!'); }
  const parsedPath = path.startsWith('/') ? path.substring(1) : path;
  const isStorybook = Boolean(import.meta.env.STORYBOOK);

  /**
   *  Spacial fix for storybook to access currect assets
   *  Storybook url includes `iframe.[hash].js` in default url
   *  remove it to get relative path
   * */
  if (isStorybook) {
    if (isLocal) {
      const { protocol, host } = new URL(import.meta.url);
      return new URL(`/assets/${parsedPath}`, `${protocol}//${host}`).href;
    }

    return new URL(`./assets/${parsedPath}`, import.meta.url.replace(/\/iframe.*.js/, '')).href;
  }

  // normal returns
  return new URL(`/src/assets/${parsedPath}`, import.meta.url).href;
};
