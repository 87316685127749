<script setup lang="ts">
import Vue, {
  computed, onBeforeMount, onMounted, watch,
} from 'vue';
import { isEmpty, isNil, pick } from 'lodash-es';
import * as Sentry from '@sentry/browser';
import { storeToRefs } from 'pinia';
import {
  useUserStore,
  useCompanyStore,
  useSurveysStore,
  useAnalyticsStore,
  useGlobalStore,
  useAuthStore,
} from '@/store';
import { initializeStatusPage } from '@/utils/statusPage';
import { useRoute, useRouter } from 'vue-router/composables';
import globals from './globals';
import CenterLayout from './layouts/Center.vue';
import NavigationFooter from './components/navigation/NavigationFooter.vue';
import NpsSurvey from './components/survey/NpsSurvey.vue';
import DebugModePanel from './components/elements/DebugMode.vue';
import Snackbar from './components/elements/Snackbar.vue';
import TheTopNavigation from './components/navigation/TheTopNavigation.vue';
import Btn from './components/elements/Button.vue';
import EventBus from './event-bus';
import { useBuildStamp } from './composables/useBuildStamp';
import { useTheme } from './composables/useTheme';
import { isPrd } from './utils/env';

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);
const { scheduleTokenRenewal } = authStore;
const userStore = useUserStore();
const {
  user,
  invalidUser,
  currentView,
  isUserLoaded,
  isUserOnboarded,
} = storeToRefs(userStore);
const { toggleProfile, detectAndSaveTimeZone } = userStore;

const { initializeAnalyticsTracking, identifyAnalyticsUser } = useAnalyticsStore();

const companyStore = useCompanyStore();
const {
  companySettings,
  globalActions,
  hasOperationsAuthorization,
  hasAnyOperationsNavOption,
} = storeToRefs(companyStore);
const {
  loadGlobalActions,
  loadRequisitionTypes,
} = companyStore;

const globalStore = useGlobalStore();
const { displayBackNavigation } = storeToRefs(globalStore);

useBuildStamp({
  enabled: isPrd,
  isAuthenticated,
  isUserLoaded,
});

const { setColorConfigs, setLogoBranding } = useTheme();

const isUserOnboarding = computed(() => route.name === 'userOnboarding');
const displayTopNav = computed(() => isAuthenticated.value && isUserLoaded.value && !isUserOnboarding.value);

const routerViewClass = computed(() => {
  if (isUserOnboarding.value) return '';
  if (displayBackNavigation) return 'pb-12 pt-5';
  return 'pb-12 pt-14';
});

const redirectToInitialView = (hasOperationsAuth) => {
  const redirectUrl = localStorage.getItem('login_redirect') ? JSON.parse(localStorage.getItem('login_redirect') as string) : undefined;
  const redirectUrlQueryParams = localStorage.getItem('login_redirect_params') ? JSON.parse(localStorage.getItem('login_redirect_params') as string) : {};

  if (redirectUrl) {
    // if user attempted to visit a URL but was redirected to login, redirect them to original URL
    // TODO: remove this comment that was added to trigger fm-client pipeline
    localStorage.removeItem('login_redirect');
    localStorage.removeItem('login_redirect_params');

    Vue.prototype.$log.debug('App.vue redirect to ', redirectUrl);
    router.replace({ path: redirectUrl, query: redirectUrlQueryParams });
  } else {
    // default page redirects
    let routerPath = '/talent';
    if (hasOperationsAuth) {
      routerPath = '/operations';
    }

    Vue.prototype.$log.debug('App.vue redirect to ', routerPath);
    router.replace(routerPath);
  }
};

/**
 * Initialize page. Lots of operations here need page DOM ready (e.g. inserting segment tracking scripts, zendesk scripts)
 * This also handles lots of redirects:
 *  * Redirect user to their initial home page (operations vs. talent)
 *  * Redirect talent user to onboarding
 *  * Redirect talent user if they input an operations URL
 */
const initializePage = async () => {
  Vue.prototype.$log.debug('initializePage start');
  try {
    if (isEmpty(user.value?.company?.name)) {
      Sentry.captureException(new Error('Company name is empty when initializePage is called. User not loaded?'));
    }
    await Promise.all([
      loadGlobalActions(),
      loadRequisitionTypes(),
    ]);
    Vue.prototype.$log.info('Grow company', user.value?.company?.name);

    // Initialize analytics tracking code and identify user
    initializeAnalyticsTracking();
    identifyAnalyticsUser();

    // Schedule a token renewal with the default delay (60 seconds)
    scheduleTokenRenewal();

    // update user timezone
    detectAndSaveTimeZone();

    if (!route.meta?.bypassInitialRedirects) {
      // redirect user to the first view after auth callback
      if (route.path === '/auth/callback') {
        redirectToInitialView(hasOperationsAuthorization.value);
      }

      // redirect to onboarding page if user is trying to view talent page without onboarding
      if (currentView.value === 'talent') {
        if (!isUserOnboarded.value && route.name !== 'userOnboarding') {
          Vue.prototype.$log.debug('App.vue redirect to onboarding');
          await router.push({ name: 'userOnboarding' });
        } else if (
          isUserOnboarded.value
          && route.name === 'userOnboarding'
        ) {
          await router.push({ name: 'talentDashboard' });
        }
      }

      // redirect to 403 page if user is trying to view operations or admin pages without authorization
      if ((currentView.value === 'operations' && !hasOperationsAuthorization.value)
        || (route.matched.some(
          (routeItem) => !hasAnyOperationsNavOption.value(routeItem.meta.requireNavOptions),
        ))) {
        toggleProfile('talent');

        // check to see if 'talent' version of the route exists
        const talentPath = route.path.replace('/operations/', '/talent/');
        const matchedRoute = router.resolve(talentPath).resolved?.matched[0];
        if (matchedRoute && matchedRoute.meta?.operationsRequired !== true) {
          // redirect to 'talent' route
          await router.push(talentPath);
          return;
        }

        // else redirect to 403 page
        await router.push('/error/403');
        return;
      }

      if (currentView.value === 'operations') {
        if (!isUserOnboarded.value && route.name !== 'userOnboarding') {
          Vue.prototype.$log.debug('App.vue redirect to onboarding');
          await router.push({ name: 'userOnboarding' });
        } else if (isUserOnboarded.value && route.name === 'userOnboarding') {
          await router.push({ name: 'operationsDashboard' });
        }
      }
    }
  } catch (error) {
    // redirect to user error page if user has not been enabled in the system
    if (invalidUser.value) {
      await router.push('/error/user?errorType=unrecognizedAccount');
    }
    Vue.prototype.$log.error(`Error getting current user: ${JSON.stringify(error)}`);
  }
};

onBeforeMount(() => {
  // Plan to remvoe eventbus from app
  EventBus.$on('currentUserLoaded', async () => {
    await initializePage();
  });
});

onMounted(() => {
  initializeStatusPage();
});

const navigateBack = () => {
  router.back();
};

const displayContent = computed(() => {
  // most of these conditionals temporarily hide the view while user or auth data is loading
  if (isNil(user.value.id) && route.matched.some((routeItem) => routeItem.meta.authRequired)) {
    // hide display if user data is not yet loaded
    Vue.prototype.$log.debug('displayContent -> false: User data not loaded');
    return false;
  }

  if (currentView.value === 'operations' && !(hasOperationsAuthorization.value && user.value?.id)
    && route.matched.some((routeItem) => routeItem.meta.authRequired)) {
    // hide display if user visits operations path without authorization
    Vue.prototype.$log.debug('displayContent -> false: operations path without authorization');
    return false;
  }

  if (currentView.value === 'operations'
    && !isNil(user.value?.id)
    && !user.value.hasAcceptedPrivacyPolicy
    && route.matched.some((routeItem) => routeItem.meta.authRequired)
    && route.name !== 'userOnboarding'
    && !route.path.startsWith('/auth')
  ) {
    // hide display for operations path if user data is loaded but user has not accepted user agreement
    // this prevents a flicker of content before the user is redirected to operations onboarding
    Vue.prototype.$log.debug('displayContent -> false: operations user has not accepted user agreement');
    return false;
  }

  if (!isEmpty(route.meta?.requiredProgramActions)) {
    route.meta?.requiredProgramActions.forEach((requiredAction) => {
      // globalActions is undefined until loaded
      if (globalActions?.value && !globalActions.value?.program?.includes(requiredAction)) {
        // throw 403
        router.push('/error/403');
      }
      return true;
    });
  }

  if (!isEmpty(route.meta?.requiredRequisitionActions)) {
    route.meta?.requiredRequisitionActions.forEach((requiredAction) => {
      // globalActions is undefined until loaded
      if (globalActions?.value && !globalActions.value?.requisition?.includes(requiredAction)) {
        // throw 403
        router.push('/error/403');
      }
      return true;
    });
  }

  if (
    currentView.value === 'talent'
    && user.value?.id
    && !isUserOnboarded.value
    && route.matched.some((routeItem) => routeItem.meta.authRequired)
    && route.name !== 'userOnboarding'
    && !route.matched.some((routeItem) => routeItem.meta.isPreviewRoute)
    && !route.path.startsWith('/auth')
  ) {
    // hide for talent path if user data is loaded but user has not onboarded
    // this prevents a flicker of content before the user is redirected to onboarding
    Vue.prototype.$log.debug('displayContent -> false: talent path not onboarded');
    return false;
  }

  return true;
});

const surveyStore = useSurveysStore();

const npsEligible = computed(() =>
  // isAuthenticated prevents weird 401 loops when logging in (perhaps race conditions?)
  // eslint-disable-next-line implicit-arrow-linebreak
  surveyStore.npsTriggered && isAuthenticated.value);

watch(() => companySettings.value?.branding, (v) => {
  if (v?.theme) {
    setColorConfigs(v.theme);
  }

  const logoFields = ['header', 'footer', 'favicon', 'pdfLogo'];
  if (!isEmpty(pick(v, logoFields))) {
    setLogoBranding(pick(v, logoFields));
  }
}, { deep: true, immediate: true });
</script>

<template>
  <VApp>
    <a
      v-if="displayTopNav"
      class="d-sr-only-focusable skip-to-main-content text-h4"
      href="#main"
      data-e2e="skipToMainContentLink"
    >
      {{ globals.accessibility.dashboard.skipToMainContentLink }}
    </a>
    <TheTopNavigation v-if="displayTopNav" />
    <DebugModePanel v-if="isAuthenticated" />
    <VMain
      v-if="displayContent"
      id="main"
      style="min-height:100%"
      class="content-bg"
    >
      <VContainer
        class="ma-0 pa-0 flex-fill"
        fluid
      >
        <NpsSurvey :showSurvey="npsEligible" />
        <CenterLayout v-if="!isUserOnboarding">
          <Btn
            v-if="displayBackNavigation"
            type="text"
            class="pl-0"
            @click="navigateBack"
          >
            <span class="pr-2">
              <VIcon>chevron_left</VIcon>
              Back
            </span>
          </Btn>
        </CenterLayout>
        <RouterView :class="routerViewClass" />
      </VContainer>
      <NavigationFooter v-if="!isUserOnboarding" />
      <Snackbar />
    </VMain>
  </VApp>
</template>

<style lang="scss">
.content-bg {
  background: var(--grow-site-background);

  .v-main__wrap {
    display: flex;
    flex-direction: column;
  }
}

.responsive-inline-flex-content {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

#build-stamp-card {
  position: fixed;
  top: 5em;
  right: 2em;
}

.skip-to-main-content {
  z-index: 6; // atop the nav
  position: absolute;
  background-color: white;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  text-decoration: none;
  height: 60px; // adjusted to match nav's height
  width: auto;
  left: 3px;
  top: 3px;
  padding: 0 1rem;
}
</style>
