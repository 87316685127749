<template>
  <v-progress-linear
    :color="color"
    :rounded="rounded"
    :value="value"
    :indeterminate="indeterminate"
  />
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    color: { type: String, required: false, default: 'primary' },
    rounded: { type: Boolean, required: false, default: true },
    value: { type: Number, required: false, default: 0 },
    indeterminate: { type: Boolean, required: false, default: false },
  },
};
</script>
