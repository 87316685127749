/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import { orderBy } from 'lodash-es';
import type { Application } from '@/types/application';
import { useUserStore } from './user';

interface RequisitionApplicationsState {
  allRequisitionApplications: Application[],
  totalResults: number,
}

const getDefaultState = (): RequisitionApplicationsState => (
  {
    allRequisitionApplications: [],
    totalResults: 0,
  }
);

export const useRequisitionApplicationsStore = defineStore('requisition_applications', {
  state: (): RequisitionApplicationsState => getDefaultState(),
  actions: {
    async loadRequisitionApplications(
      { requisition, params = { offset: 0, limit: 20 } },
    ) {
      const { offset } = params;
      try {
        const userStore = useUserStore();
        const { data } = await axios.get(
          `/v1/companies/${userStore.userCompanyId}/requisitions/${requisition.id}/applications`,
          {
            params: {
              ...params,
              includeActions: true,
              includeSource: true,
            },
          },
        );

        // first query will set new data
        let applicationsWithRequisitions: Application[] = [];

        if (offset !== 0) {
          applicationsWithRequisitions = [...this.allRequisitionApplications];
        }

        const { totalResults, results } = data;

        // push into state
        applicationsWithRequisitions.push(...results.map((app) => ({
          ...app,
          requisition: { ...requisition },
        })));

        this.setAllRequisitionApplications(applicationsWithRequisitions, totalResults);
      } catch (error) {
        Vue.prototype.$log.error(`Error getting requisition applications: ${error}`);
        throw error;
      }
    },
    setAllRequisitionApplications(allRequisitionApplications, totalResults = 0) {
      this.totalResults = totalResults;
      this.allRequisitionApplications = orderBy(
        allRequisitionApplications,
        ['state', 'rank'],
        ['desc', 'asc'],
      );
    },
    async loadAllRequisitionApplications(requisition) {
      let offset = 0;
      const limit = 20;
      await this.loadRequisitionApplications({ requisition, params: { offset, limit } });
      while (this.allRequisitionApplications.length < this.totalResults) {
        offset += limit;
        // eslint-disable-next-line no-await-in-loop
        await this.loadRequisitionApplications({ requisition, params: { offset, limit } });
      }
    },
    async patchApplicationTransition(url, data = {}) {
      const response = await axios({
        method: 'patch',
        url,
        data,
      });
      this.updateRequisitionApplication(response.data);
    },
    updateRequisitionApplication(updatedApplication) {
      this.allRequisitionApplications = orderBy(
        this.allRequisitionApplications
          .map((application) => (
            application.id !== updatedApplication.id
              ? application
              : updatedApplication
          )),
        ['state', 'rank'],
        ['desc', 'asc'],
      );
    },
    async completeApplication({
      url, gainedSkills, interestsAdded, excludeFromProfile = null,
      mentorFeedback = null,
    }) {
      try {
        return await axios.request({
          method: 'patch',
          url: `${url}`,
          data: {
            skillIdsAdded: gainedSkills,
            interestsAdded,
            ...(excludeFromProfile != null ? { excludeFromProfile } : {}),
            ...(mentorFeedback ? { comments: { adding: mentorFeedback } } : {}),
          },
        });
      } catch (error) {
        Vue.prototype.$log.error(`Error transitioning requisition application to completed: ${error}`);
        throw error;
      }
    },
    async withdrawApplication(url) {
      try {
        return await axios.patch(url);
      } catch (error) {
        Vue.prototype.$log.error(`Error transitioning requisition application to withdrawn: ${error}`);
        throw error;
      }
    },
    async getApplication(id, userId) {
      try {
        return await axios.get(`/v1/users/${userId}/applications/${id}`);
      } catch (error) {
        Vue.prototype.$log.error(`Error getting application: ${error}`);
        throw error;
      }
    },
    async talentCreateOpportunityFeedback(userId, applicationId, payload) {
      try {
        return await axios.post(`/v1/users/${userId}/applications/${applicationId}/feedback`, {
          ...payload,
        });
      } catch (error) {
        Vue.prototype.$log.error(`Error creating talent user feedback results: ${error}`);
        throw error;
      }
    },
    async operationsCreateOpportunityFeedback(userId, applicationId, payload) {
      try {
        return await axios.post(`/v1/users/${userId}/applications/${applicationId}/feedback`, {
          ...payload,
        });
      } catch (error) {
        Vue.prototype.$log.error(`Error creating operations user feedback results: ${error}`);
        throw error;
      }
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRequisitionApplicationsStore, import.meta.hot));
}
