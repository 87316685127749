import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/styles/global.scss';
import Vue from 'vue';
import VueWait from 'vue-wait';
import * as Sentry from '@sentry/vue';
import { Settings as localeSettings } from 'luxon';
import axios from 'axios';
import pinia from './plugins/pinia';
import vuetify from './plugins/vuetify';
import globals from './globals';
import vueLogger from './plugins/logger';
import { useImage } from './utils/image';
import { isLocalOrDev } from './utils/env';
import router from './router/router';
import App from './App.vue';
import initAxios from './axios';
import { getEnLocale } from './utils/locale';
// There are basically 4 environments:
// Production: grow.beamery.com
// Staging: stg.grow.beamery.com
// Development: dev.grow.beamery.com
// Local: localhost:8080
//
// Relevant Environment Variables
// * VITE_BUILD_ENV: Possible values are 'prd', 'stg', 'dev'. It will be undefined for Local. Set by `build/scripts/ci-set-env.sh`.
// * NODE_ENV: Set by vue-cli-service based on Vue build mode. Possible values are 'production', 'development', 'test'.
// NODE_ENV is set to 'production' in Production/Staging/Development environments. It's 'development' when using `vue-cli-service serve` (aka. `npm run devstart`) in the Local env.
// See https://cli.vuejs.org/guide/mode-and-env.html#environment-variables for more info about NODE_ENV

// Make sure Vue.config.devtools is set before Vue modules are loaded
// Otherwise Vuex is not detected by Vue devtools on dev
// https://github.com/vuejs/vue-devtools/issues/405#issuecomment-399882681
Vue.config.devtools = isLocalOrDev;
Vue.config.productionTip = false;
Vue.config.performance = true;

// LOGGER
Vue.use(vueLogger);
Vue.prototype.$log = vueLogger; // set logger global

// LUXON
localeSettings.defaultLocale = getEnLocale();

// AXIOS
initAxios();

// SENTRY
const ignoreErrors = [
  'ChunkLoadError', // ignore ChunkLoadError which is generally caused by user having an outdated browser tab open
  'Cancel request without auth token', // ignore no Auth token error
  /(.* )status code 429/gi, // ignore 429 errors
  /(.* )status code 401/gi, // ignore 401 errors
  'Failed to fetch dynamically imported module', // ignore failed to fetch dynamically imported module
];

Sentry.init({
  Vue,
  normalizeDepth: 3,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_BUILD_ENV || 'local',
  release: import.meta.env.VITE_BUILD_STAMP,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      // When this switch is set to true, Sentry will capture JS console logs but FullStory won't catch them.
      // When this switch is not set, FullStory will capture JS console logs and Sentry won't
      // When this switch is set to false, neither Sentry or FullStory will capture logs
      // There seems to be some conflict
      // Depending on which service we want JS console logs to be captured by, we can either set this to true or comment it out
      // console: true,
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  trackComponents: true,
  //  import.meta.env.PROD is a boolean
  tracesSampleRate: import.meta.env.PROD ? 0.03 : 0.001,
  ignoreErrors,
  denyUrls: [
    // blocks 3rd-party errors from being caught by sentry
  ],
});

Vue.prototype.$log.info('Grow build stamp', import.meta.env.VITE_BUILD_STAMP || 'development');

// With Sentry integration, errors occurring in Vue renderer won't show up in the developer console
// So configuring custom error handler based on https://github.com/vuejs/vue/issues/8433
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.config.errorHandler = (err: any, vm, info) => {
  if (axios.isAxiosError(err) && (err.response?.status === 401 || axios.isCancel(err))) {
    // Log 401 errors at warn level, as they can happen after a user logs out, and error logs fail e2e tests
    Vue.prototype.$log.warn(`Error in ${info}:`, err);
  } else {
    Vue.prototype.$log.error(`Error in ${info}:`, err);
  }
};

Vue.config.warnHandler = (msg, vm, trace) => {
  if (!globals.suppressedVueWarningMessages.includes(msg)) {
    Vue.prototype.$log.log(`[Vue warn]: ${msg}${trace}`);
  }
};

// Global loading state manager
Vue.use(VueWait);

// Do we need to wait
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wait = new ((VueWait as any)?.default || VueWait)({
  useVuex: false,
});

// OTHER Global VARS
Vue.prototype.$image = useImage;

// APP Entry
const app = new Vue({
  pinia,
  router,
  vuetify,
  wait,
  render: (h) => h(App),
});

app.$mount('#app');
