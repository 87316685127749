<template>
  <Btn
    v-if="isZendeskScriptLoaded"
    v-click-outside="closeHelpWidget"
    :input-value="isHelpOpen ? 'activated' : undefined"
    class="px-3 py-7"
    style="min-width: 0;"
    type="text"
    color="secondary4"
    dataE2e="globalHelpButton"
    @click="helpClicked"
  >
    <VIcon>help</VIcon>
  </Btn>
</template>

<script setup lang="ts">
import Vue, { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store';
import Btn from '@/components/elements/Button.vue';

const isZendeskScriptLoaded = ref(false);
const isHelpOpen = ref(false);
const { user } = storeToRefs(useUserStore());

const configZendeskWidget = () => {
  // Configure Zendesk widget https://developer.zendesk.com/api-reference/widget/core/
  // Note: settings here might override settings in Zendesk admin UI https://fluxwork.zendesk.com/agent/admin/widget
  // Changes made in the Web Widget settings in Zendesk admin UI will also affect this widget, with a delay of 1~3 minutes
  window.zESettings = {
    webWidget: {
      position: { horizontal: 'right', vertical: 'top' },
      offset: { vertical: '50px' },
      helpCenter: {
        filter: {
          category: '9921885593107,9940413281811,9912272579987,10724153704979',
        },
      },
    },
  };
  // zendeskWidgetToken won't be set for E2E tests
  // as Zendesk will return a "Domain not allowed" error when running in Cypress
  if (user.value.zendeskWidgetToken) {
    window.zESettings.authenticate = { jwt: user.value.zendeskWidgetToken };
  }
  const zendeskScript = document.createElement('script');
  zendeskScript.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=9778bb99-1945-499b-8fae-b6b24be5d13b');
  zendeskScript.setAttribute('id', 'ze-snippet');
  zendeskScript.onload = () => {
    Vue.prototype.$log.debug('zendeskScript onload');
    window.zE('webWidget', 'hide');
    window.zE('webWidget:on', 'open', () => {
      isHelpOpen.value = true;
    });
    window.zE('webWidget:on', 'close', () => {
      isHelpOpen.value = false;
      window.zE('webWidget', 'hide');
    });
    window.zE('webWidget', 'identify', {
      name: user.value.name,
      email: user.value.email,
      organization: user.value?.company?.name,
    });
    window.zE('webWidget', 'prefill', {
      name: {
        value: user.value.name,
        readOnly: true,
      },
      email: {
        value: user.value.email,
        readOnly: true,
      },
    });
    isZendeskScriptLoaded.value = true;
  };
  document.head.appendChild(zendeskScript);
};

onMounted(() => {
  configZendeskWidget();
});

const closeHelpWidget = () => {
  window.zE('webWidget', 'close');
  // Hiding the widget will be handled by the on:close callback
};

const helpClicked = () => {
  if (isHelpOpen.value) {
    closeHelpWidget();
  } else {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  }
};
</script>
