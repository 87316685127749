import type { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import defaultColors from '../../styles/color-map.module.scss';

const theme = {
  primary: {
    base: defaultColors.primary,
    lighten2: defaultColors.primary0,
    lighten1: defaultColors.primary1,
    darken1: defaultColors.primary3,
    darken2: defaultColors.primary4,
  },
  primary4: defaultColors.primary4,
  primary3: defaultColors.primary3,
  primary2: defaultColors.primary2,
  primary1: defaultColors.primary1,
  primary0: defaultColors.primary0,
  secondary: {
    base: defaultColors.secondary,
    lighten2: defaultColors.secondary0,
    lighten1: defaultColors.secondary1,
    darken1: defaultColors.secondary3,
    darken2: defaultColors.secondary4,
  },
  secondary4: defaultColors.secondary4,
  secondary3: defaultColors.secondary3,
  secondary2: defaultColors.secondary2,
  secondary1: defaultColors.secondary1,
  secondary0: defaultColors.secondary0,
  accent: {
    base: defaultColors.accent,
    lighten3: defaultColors.accent0,
    lighten2: defaultColors.accent1,
    lighten1: defaultColors.accent2,
    darken1: defaultColors.accent4,
  },
  accent0: defaultColors.accent0,
  accent1: defaultColors.accent1,
  accent2: defaultColors.accent2,
  accent3: defaultColors.accent3,
  accent4: defaultColors.accent4,
  error: defaultColors.error,
  success: defaultColors.success,
  warning: defaultColors.warning,
  anchor: defaultColors.anchor,
  extended1: defaultColors.extended1,
  extended2: defaultColors.extended2,
  extended3: defaultColors.extended3,
  extended4: defaultColors.extended4,
  extended5: defaultColors.extended5,
  extended6: defaultColors.extended6,
  extended7: defaultColors.extended7,
  extended8: defaultColors.extended8,
  extended9: defaultColors.extended9,
  extended10: defaultColors.extended10,
  'accent-secondary': defaultColors['accent-secondary'],
  'site-background': defaultColors['site-background'],
  'navigation-background': defaultColors['navigation-background'],
  'onboarding-grey1': defaultColors['onboarding-grey1'],
  'onboarding-grey2': defaultColors['onboarding-grey2'],
  'onboarding-grey3': defaultColors['onboarding-grey3'],
  'onboarding-grey4': defaultColors['onboarding-grey4'],
  'onboarding-grey5': defaultColors['onboarding-grey5'],
  status: defaultColors.status,
  'border-grey1': defaultColors['border-grey1'],
} as Partial<VuetifyThemeVariant>;

export default theme;
