import Vue from 'vue';
import Router from 'vue-router';
import {
  get,
  isFinite,
  isEmpty,
  cloneDeep,
} from 'lodash-es';
import * as Sentry from '@sentry/browser';
import {
  useUserStore,
  useCompanyStore,
  useAnalyticsStore,
  useGlobalStore,
  useAuthStore,
} from '@/store';
import talentRoutes from './profiles/talent';
import operationsRoutes from './profiles/operations';
import Callback from '../views/auth/Callback.vue';
import EventBus from '../event-bus';

Vue.use(Router);

/*
* When updating to vue-router version 3.1.3, a 'Navigation Duplicated' error was consistantly thrown when
* using the $router.push and $router.replace methods within our code
* This fix involves replacing Router's push/replace functions to silence the rejection and make the promise resolve
* SEE: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
*/
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

let position;

// Routes in this base file do not require authentication and are publicly accessible
const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    position = cloneDeep(savedPosition);
    if (position) {
      let counter = 0;
      return new Promise((resolve) => {
        setTimeout(function scroll() {
          counter += 1;
          if (!isEmpty(position)
            && document.body.scrollHeight < Math.floor(position.y + document.documentElement.clientHeight)) {
            // If a savedPosition value is set, but the height of the page is not tall enough to scroll to the position, keep waiting.
            // This is likely to happen on pages where async API calls are made before page data can load, such as search results.
            if (counter < 25) {
              setTimeout(scroll, 300);
            } else {
              // If we have already retried 25 times and the page height is not tall enough, return without scrolling to the position.
              Vue.prototype.$log.debug(`Body height never large enough to scroll to position: ${JSON.stringify(position)}`);
              resolve(false);
            }
          } else if (!isEmpty(position)) { // re-check that position exists in case the route changed before this resolved
            Vue.prototype.$log.debug(`Scroll to saved position: ${JSON.stringify(position)}`);
            resolve(position);
          }
        }, 300);
      });
    }

    if (to.hash && to.path !== '/auth/callback') {
      let counter = 0;
      return new Promise((resolve) => {
        setTimeout(function scroll() {
          counter += 1;
          try {
            if (document.querySelector(to.hash)) {
              const selector = document.querySelector(to.hash);

              // check to see if if selector specifies a relative offset value to scroll to
              // https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
              let offset;
              if (get(selector, 'dataset.scrollToOffset') && isFinite(Number(selector.dataset.scrollToOffset))) {
                offset = { x: 0, y: Number(selector.dataset.scrollToOffset) };
              }

              Vue.prototype.$log.debug(`Scrolling to hash ${to.hash}`);

              setTimeout(() => {
                resolve({
                  selector: to.hash,
                  offset,
                  behavior: 'smooth',
                });
              }, 1000);
            } else if (counter < 25) {
              setTimeout(scroll, 300);
            } else {
              // if to.hash is not a valid selector (ex. access_token during login) then do not scroll
              Vue.prototype.$log.debug(`Never found element to scroll to for hash ${to.hash}`);
              resolve(false);
            }
          } catch (error) {
            Vue.prototype.$log.debug(`Error finding element to scroll to for hash ${to.hash}`);
            resolve(false);
          }
        }, 300);
      });
    }

    // don't scroll to top when user is filtering on a page or opening a drawer over a page
    if ((to.path && from.path && to.path === from.path) || to.meta?.drawer === true) {
      return false;
    }

    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
  routes: [
    ...talentRoutes,
    ...operationsRoutes,
    {
      path: '/auth/callback',
      component: Callback, // Callback shouldn't be lazy loaded because of login flow
      meta: {
        title: 'Authentication',
        analyticsPageName: 'Sign In Callback',
      },
    },
    {
      path: '/login',
      component: () => import('../views/auth/Login.vue'),
      meta: {
        title: 'Authentication',
        analyticsPageName: 'Sign In Prompt',
      },
    },
    {
      path: '/logout',
      component: () => import('../views/auth/Logout.vue'),
      meta: {
        title: 'Logout',
        analyticsPageName: 'Logout',
      },
    },
    {
      path: '/auth/zendesk/sso',
      component: () => import('../views/auth/ZendeskSSO.vue'),
      meta: {
        title: 'SSO for Zendesk',
        analyticsPageName: 'Zendesk SSO',
        authRequired: true,
        suppressZendeskWidget: true,
        bypassInitialRedirects: true,
      },
    },
    {
      path: '/slack',
      component: () => import('../views/admin/CompanySlack.vue'),
      props: (route) => ({
        query: route.query,
      }),
      meta: {
        title: 'Slack Installation',
        analyticsPageName: 'Slack Installation',
      },
    },
    {
      path: '/error/user',
      component: () => import('../views/error/ErrorUser.vue'),
      meta: {
        title: 'Invalid Account',
        analyticsPageName: 'Invalid Account',
      },
    },
    {
      path: '/error/403',
      component: () => import('../views/error/ErrorCode.vue'),
      props: () => ({
        errorCode: 403,
      }),
      meta: {
        title: 'Error',
        analyticsPageName: '403 Error',
      },
    },
    {
      path: '/error/404',
      component: () => import('../views/error/ErrorCode.vue'),
      props: () => ({
        errorCode: 404,
      }),
      meta: {
        title: 'Error',
        analyticsPageName: '404 Error',
      },
    },
    {
      path: '/error/500',
      component: () => import('../views/error/ErrorCode.vue'),
      props: () => ({
        errorCode: 500,
      }),
      meta: {
        title: 'Error',
        analyticsPageName: '500 Error',
      },
    },
    {
      path: '/p/:programId/:referrerUserId',
      redirect: (to) => {
        const { params } = to;
        const userStore = useUserStore(); // connect to user store, must be done on func by func basis
        const analyticsStore = useAnalyticsStore();
        // track program recommendation
        const properties = {
          userId: userStore.userId,
          referrerUserId: params.referrerUserId,
          programId: params.programId,
        };
        analyticsStore.trackEvent({ eventName: 'User Visited Shareable Link', propertiesObj: properties });

        return `/talent/programs/${params.programId}`;
      },
    },
    {
      path: '/r/:requisitionId/:referrerUserId',
      redirect: (to) => {
        const { params } = to;
        const userStore = useUserStore(); // connect to user store, must be done on func by func basis
        const analyticsStore = useAnalyticsStore();
        // track requisition recommendation
        const properties = {
          userId: userStore.userId,
          referrerUserId: params.referrerUserId,
          requisitionId: params.requisitionId,
        };
        analyticsStore.trackEvent({ eventName: 'User Visited Shareable Link', propertiesObj: properties });

        return `/talent/opportunities/requisitions/${params.requisitionId}`;
      },
    },
    {
      path: '/snooze',
      component: () => import('../views/user/Snooze.vue'),
      props: (route) => ({
        token: route.query.token,
        notification: route.query.notification,
        months: route.query.months,
      }),
      meta: {
        title: 'Snooze',
        analyticsPageName: 'Snooze',
      },
    },
    {
      path: '/unsubscribe',
      component: () => import('../views/user/Unsubscribe.vue'),
      props: (route) => ({
        query: route.query,
      }),
      meta: {
        title: 'Unsubscribe',
        analyticsPageName: 'Unsubscribe',
      },
    },
    {
      path: '/verify-email',
      component: () => import('../views/user/VerifyEmail.vue'),
      props: (route) => ({
        query: route.query,
      }),
      meta: {
        title: 'Verify Email',
        analyticsPageName: 'Verify Email',
      },
    },
    {
      path: '/download-profile',
      component: () => import('../views/user/DownloadProfile.vue'),
      props: (route) => ({
        query: route.query,
      }),
      meta: {
        title: 'Download Profile',
        analyticsPageName: 'Download Profile',
      },
    },
    {
      path: '/request-profile-download',
      component: () => import('../views/user/RequestProfileDownloadEmail.vue'),
      meta: {
        title: 'Request Profile Download',
        analyticsPageName: 'Request Profile Download',
      },
    },
    {
      // No page exists for this URL
      // Router config in main.js redirects user to homepage of current profile view
      path: '/',
      component: () => import('../views/BaseRouter.vue'),
    },
    {
      path: '*',
      redirect: '/error/404',
    },
    {
      path: '/onboarding',
      component: () => import('../views/onboarding/Onboarding.vue'),
      name: 'userOnboarding',
      meta: {
        title: 'Welcome',
        analyticsPageName: 'Onboarding',
        authRequired: true,
      },
    },
  ],
});

// Configure Router to load current user if not loaded, set current view and check for links to / and redirect
router.beforeEach(async (to, from, next) => {
  Vue.prototype.$log.debug('router beforeEach', 'to.path:', to.path, 'from.path', from.path);

  // '/auth/callback' has its own logic and should skip this route guard
  // e.g. if a user already has a valid token of user A in localstorage and is logging in as another user B via /auth/callback,
  // we should not load user A here
  // if (to.path === '/test') return next();
  if (to.path === '/auth/callback') return next();
  if (to.path === '/logout' || to.path === '/login') return next();

  const authStore = useAuthStore();
  const userStore = useUserStore(); // connect to user store, must be done on func by func basis
  const companyStore = useCompanyStore();

  // Will process token check first
  if (authStore.isAuthenticated) {
    if (authStore.accessToken === '') {
      // setup existing token from localStorage
      authStore.initToken();
    }

    if (to.path.startsWith('/error/')) return next();

    if (!userStore.isUserLoaded) {
      Vue.prototype.$log.info('Loading user and nav options for already authenticated user');
      await Promise.all([
        companyStore.loadNavigationOptions(),
        userStore.loadCurrentUser(),
      ]);

      EventBus.$emit('currentUserLoaded'); // this will trigger the "initializePage" function in App.vue
    }
  }

  // set current view based on router path or most recent view (for error pages, etc)
  if (to.matched.some((routeItem) => routeItem.meta.view === 'operations')) {
    userStore.toggleProfile('operations');
  } else if (to.matched.some((routeItem) => routeItem.meta.view === 'talent')) {
    userStore.toggleProfile('talent');
  } else if (authStore.isAuthenticated && !userStore.currentView) {
    const view = companyStore.hasOperationsAuthorization ? 'operations' : 'talent';
    Vue.prototype.$log.debug('Determining initial view', view);
    userStore.toggleProfile(view);
  }

  // if user is logged in and path is set to / redirect to homepage of current profile
  if (to.path === '/') {
    if (!authStore.isAuthenticated) {
      return next('/login');
    }
    if (userStore.currentView === 'operations') {
      return next('/operations');
    }
    if (userStore.currentView === 'talent') {
      return next('/talent');
    }
    return next();
  }
  return next();
});

// Configure Router to check for authentication and authorization, set current view
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const companyStore = useCompanyStore();

  // 1. Check to see if route requires authentication
  const requiresAuth = to.matched.some((routeItem) => routeItem.meta.authRequired);

  if (requiresAuth) {
    // if user is not logged in, redirect them to login page
    if (!authStore.isAuthenticated) {
      next({
        path: '/login',
        query: {
          nextUrl: to.path,
          queryParams: to.query,
        },
      });
    }

    const requireNavOptionsChecker = (matchedRoutes) => {
      // Go through all match routes level,
      // each level should has at lease one requireNavOptions passed
      const allRoutesResults = matchedRoutes.map((route) => {
        const requireNavOptions = route.meta?.requireNavOptions;

        // Skip empty
        if (isEmpty(requireNavOptions)) {
          return true;
        }

        // Check includes any requireNavOptions
        return companyStore.hasAnyOperationsNavOption(requireNavOptions);
      });

      // should not includes any `false`
      return !allRoutesResults.includes(false);
    };

    // 2. Check route required auth options
    if (userStore.isUserLoaded && !requireNavOptionsChecker(to.matched)
    ) {
      return next({ path: '/error/403' });
    }

    // 3. Check Operations role required
    const requireOperationsAuth = to.matched.some((routeItem) => routeItem.meta.operationsRequired);
    // check to see if route requires operations management authorization
    if (userStore.isUserLoaded && requireOperationsAuth && !companyStore.hasOperationsAuthorization) {
      // if user is not authorized to view operations pages, redirect to 403 page
      // On first page load, user data might not have loaded yet, so check is also done in App.vue
      if (!companyStore.hasOperationsAuthorization) {
        userStore.toggleProfile('talent');

        // check to see if 'talent' version of the route exists
        const talentPath = to.path.replace('/operations/', '/talent/');
        const matchedRoute = router.resolve(talentPath).resolved?.matched[0];
        if (matchedRoute && matchedRoute.meta?.operationsRequired !== true) {
          // redirect to 'talent' route
          return next({
            path: talentPath,
          });
        }

        return next({ path: '/error/403' });
      }
    // allow user to access operations routes if they have authorization and completed onboarding
    } else if (
      userStore.isUserLoaded
      && userStore.isUserOnboarded
      && companyStore.hasOperationsAuthorization
      && to.name === 'userOnboarding'
    ) {
      if (companyStore.companySettings.featureSwitches?.pursuitsCareerAssistant) {
        return next({ name: `${userStore.currentView}CareerChat` });
      }
      return next({
        name: `${userStore.currentView}Dashboard`,
        query: { welcomeModal: true },
      });
    // allow user to access talent routes if they have authorization and completed onboarding
    } else if (
      userStore.isUserLoaded
      && userStore.isUserOnboarded
      && to.name === 'userOnboarding'
    ) {
      if (companyStore.companySettings.featureSwitches?.pursuitsCareerAssistant) {
        return next({ name: `${userStore.currentView}CareerChat` });
      }
      return next({
        name: `${userStore.currentView}Dashboard`,
        query: { welcomeModal: true },
      });
    // make sure user has onboarded before allowing access to other routes
    } else if (
      userStore.isUserLoaded
      && !userStore.isUserOnboarded
      && to.name !== 'userOnboarding'
      && to.matched.some(
        (routeItem) => (routeItem.meta.view === 'operations' || routeItem.meta.view === 'talent'),
      )
    ) {
      return next({
        name: 'userOnboarding',
      });
    }
  }
  // no auth guard check
  return next();
});

// Track page events
router.afterEach((to, from) => {
  Vue.prototype.$log.debug('router afterEach', 'to.path:', to.path, 'from.path', from.path);
  Vue.nextTick(async () => {
    // nextTick to prevent rewriting history
    document.title = to.meta?.title ? `${to.meta.title} | Grow` : 'Grow';

    // Do Segment tracking in nextTick so it happens after DOM updates
    // Check to see if the base path changed;
    // We want to track tab changes as page views
    // And we do not want to track other query parameter updates as page views
    const userStore = useUserStore(); // connect to user store, must be done on func by func basis
    const analyticsStore = useAnalyticsStore();
    const globalStore = useGlobalStore();

    if (to.path !== from.path || to.query.t !== from.query.t) {
      analyticsStore.trackPage({
        pageName: to.meta.analyticsPageName,
        propertiesObj: {
          path: to.path,
          tab: to.query.t,
        },
      });

      // Update Zendesk Page Path
      if (window.zE) {
        try {
          // Update current path in chat widget
          window.zE('webWidget', 'updatePath');
          // Update Zendesk widget setting to limit search scope based on the current view
          window.zE('webWidget', 'updateSettings', {
            helpCenter: {
              filter: userStore.currentView === 'talent' ? { // limit help center article to articles labeled with "talent" in talent view
                label_names: 'talent',
              } : {},
            },
          });
          // Update context help search articles based on the current URL
          window.zE('webWidget', 'helpCenter:setSuggestions', {
            url: true,
            // The following line will cause web widget not refresh suggests when switching tabs in talent view. So we should not use it
            // labels: userStore.currentView === 'talent' ? ['talent'] : undefined,
          });
        } catch (error) {
          Vue.prototype.$log.error(`Error updating zendesk webWidget path: ${error}`);
          Sentry.captureException(error);
        }
      }
    }
    // check to make sure we don't display back button on the first page load
    if (from.path !== '/' && from.path !== '/auth/callback' && to.path !== from.path) {
      // check to see if route is configured to display back navigation button
      const displayBackNavigation = to.matched.some((routeItem) => routeItem.meta.displayBackNavigation);
      globalStore.setBackNavigation(displayBackNavigation);
    }
    // send click tracking event to the backend to track the user notification click from different channels
    // use backend event logging to avoid the stats error caused by client-side ad blocker
    if (to.query.notificationId && to.query.channel) {
      await analyticsStore.trackEventViaBackend({
        eventName: 'Notification Clicked',
        userId: userStore.userId,
        propertiesObj: {
          companyId: userStore.userCompanyId,
          notificationId: to.query.notificationId,
          channel: to.query.channel,
        },
      });
    }
  });
});

export default router;
