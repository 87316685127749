const workContent = [
  {
    id: 'things',
    name: 'Things (execution)',
    altName: 'Execution - Working with things',
  },
  {
    id: 'ideas',
    name: 'Ideas (planning)',
    altName: 'Planning - Working with ideas',
  },
  {
    id: 'data',
    name: 'Data (analyzing)',
    altName: 'Analyzing - Working with data',
  },
  {
    id: 'people',
    name: 'People (interacting)',
    altName: 'Interacting - Working with people',
  },
];

const careerSentimentSurveyKeyConfig = {
  showAction: 'Show survey key',
  hideAction: 'Hide survey key',
  value: [
    {
      questionName: 'job mastery',
      title: 'Job mastery',
      subtitle: 'In my job I am',
      description: `1. Still learning
2. Proficient
3. Expert`,
    },
    {
      questionName: 'job satisfaction',
      title: 'Job satisfaction',
      subtitle: 'In my job I am',
      description: `1. Very unsatisfied
2. Unsatisfied
3. Satisfied
4. Very satisfied`,
    },
    {
      questionName: 'career path',
      title: 'Career path',
      subtitle: 'I feel my career path is',
      description: `1. Very unclear
2. Somewhat unclear
3. Somewhat clear
4. Very clear`,
    },
    {
      questionName: 'career navigation',
      title: 'Career navigation',
      subtitle: 'I am confident in my ability to navigate that path',
      description: `1. Strongly disagree
2. Disagree
3. Agree
4. Strongly agree`,
    },
    {
      questionName: 'career options',
      title: 'Career options',
      subtitle: 'I have sufficient career options at my company',
      description: `1. Strongly disagree
2. Disagree
3. Agree
4. Strongly agree`,
    },
  ],
};

const subjectAgreementLink = 'https://beamery.com/terms-and-conditions/#beamery-grow-subject-agreement';
const privacyPolicyLink = 'https://beamery.com/privacy-policy/';

const resumeUploaderFormatsForDisplay = 'pdf, doc, docx, html, htm, rtf, odt, and txt';

export const satisfactionFeedbacksQuestions = [
  {
    type: 'radiogroup',
    name: 'opportunity accurate',
    title: 'The opportunity description and benefit was accurate',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    type: 'comment',
    name: 'opportunity accurate comment',
  },
  {
    type: 'radiogroup',
    title: 'I learned and developed the skills originally planned',
    name: 'learned and developed',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    type: 'comment',
    name: 'learned and developed comment',
  },
  {
    type: 'radiogroup',
    title: 'During my onboarding for the opportunity, I received instructions and resources to be successful',
    name: 'opportunity resources',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    type: 'comment',
    name: 'opportunity resources comment',
  },
  {
    title: 'The opportunity manager was available for my questions or escalations',
    name: 'manager available',
    type: 'radiogroup',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    type: 'comment',
    name: 'manager available comment',
  },
  {

    type: 'radiogroup',
    title: 'The opportunity manager provided relevant feedback to help me be successful during the opportunity',
    name: 'manager feedback',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    titleLocation: 'hidden',
    type: 'comment',
    name: 'manager feedback comment',
    placeHolder: 'Comment',
  },
  {
    title: 'My direct manager has been supportive of me balancing my role and my participation in this opportunity',
    name: 'manager support',
    type: 'radiogroup',
    isRequired: true,
    choices: [
      {
        text: 'Strongly disagree',
        value: '1',
      },
      {
        text: 'Disagree',
        value: '2',
      },
      {
        text: 'Agree',
        value: '3',
      },
      {
        text: 'Strongly agree',
        value: '4',
      },
    ],
  },
  {
    titleLocation: 'hidden',
    type: 'comment',
    name: 'manager support comment',
    placeHolder: 'Comment',
  },
];

// Global constants can be stored here
export default Object.freeze({
  accessibility: {
    dashboard: {
      skipToMainContentLink: 'Skip to main content',
    },
  },
  applicationActions: {
    confirm: {
      displayName: 'Confirm completion',
      configUrlField: 'confirmUrl',
      snackbar: {
        success: {
          icon: 'thumb_up',
          message: 'Experience is confirmed complete for user',
        },
      },
    },
    uncomplete: {
      displayName: 'Uncomplete',
      isSubmitting: false,
      configUrlField: 'uncompleteUrl',
      snackbar: {
        icon: 'done',
        message: 'Experience has been returned to in-progress',
      },
    },
  },
  links: {
    subjectAgreement: subjectAgreementLink,
    privacyPolicy: privacyPolicyLink,
  },
  workContent,
  pagination: {
    // Default number of results to request from a paginated API endpoint
    defaultRequestLimit: 10,
    defaultItemsPerPageOptions: [10, 20, 50],
  },
  insights: {
    // Default number of items to show in a chart before expand button shows
    chartPagination: {
      opsDashboardTeamSkillsCharts: 15,
      workforceCompositionJobFamilyGroup: 15,
      workforceCompositionJobFamily: 15,
      workforceCompositionJobArchitectureRole: 15,
      supplyDemandJobFamilyGroup: 15,
      supplyDemandJobFamily: 15,
      supplyDemandJARole: 15,
    },
    labels: {
      expand: 'Show more',
      collapse: 'Show less',
      supplyDemand: {
        currentEmployees: 'Current employees',
        openJobs: 'Open jobs',
        predictedJobs: 'Predicted open jobs',
        employeeMatches: 'Employee matches',
        externalMatches: 'External matches',
      },
    },
  },
  userSearch: {
    entityDisplayName: 'People',
  },
  experiences: {
    types: [
      { id: 'civilian', name: 'Full-time' },
      { id: 'military', name: 'Military service' },
    ],
    militaryBranches: ['Army', 'Air Force', 'Coast Guard', 'Marine Corps', 'Navy', 'Space Force'],
  },
  mentorshipV2: {
    text: {
      description: "Want to find a mentor? Want to be a mentor? Set up your preferences and we'll help match you with relevant opportunities.",
      iAmLookingForMentor: "👂 I'm looking for mentorship",
      needAMentor: '🧠 Need a mentor?',
      lookingForMentor: '👂 Looking for mentor',
      mentor: '🧠 Mentor',
      offerMentorshipTopic: 'You want to offer mentorship in the following topic(s):',
      requestMentorshipTopic: 'You want to learn the following topic(s):',
      lastRequestMessage: 'Your last mentorship request was successfully sent on',
      lastOfferMessage: 'Your last mentorship offer was successfully sent on',
    },
    frequency: {
      single: {
        label: 'Single session',
        value: 'SINGLE',
      },
      multiple: {
        label: 'Ongoing sessions',
        value: 'MULTIPLE',
      },
      noPreference: {
        label: "I'm open to either",
        value: 'NO_PREFERENCE',
      },
    },
    filterOptions: [
      { id: 'as_mentor', name: 'Mentor' },
      { id: 'as_mentee', name: 'Looking for mentor' },
    ],
  },
  clientLogos: {
    15663: {
      width: 54,
      height: 18,
    },
    15696: {
      width: 43,
      height: 15,
    },
    15762: {
      width: 69,
      height: 18,
    },
    15763: {
      width: 126,
      height: 20,
    },
  },
  vueWaitLoaders: {
    roles: 'roles',
    subordinates: 'subordinates',
    subordinateRoles: 'subordinate roles',
    requisitions: 'requisitions',
    programs: 'programs',
    talent: 'talent',
    userRequisitions: 'user requisitions',
    operationsFeed: 'operations feed',
    operationsTasks: 'operations tasks',
    domainRoles: 'domain roles',
  },
  onboarding: {
    enrichProfile: {
      exampleURL: 'https://www.linkedin.com/in/your-linkedin-id-here/',
      onboardingTitle: 'Welcome to Grow 👋',
      confirmationTitle: 'Review and confirm profile summary',
      retryTitle: "Alright, let's try this again",
      profileTitle: "Let's get to know you!",
      confirmationExistingText: 'Great news! It looks like we already have some information about you. Please review and edit the summary below. Once everything looks good, confirm all.',
      confirmationText: "Let's review the details we have about you to ensure they are correct. Once everything looks good, please confirm all.",
      linkedinUrlLabel: 'LinkedIn URL:',
      linkedinURLFormatError: 'Invalid URL. Please format as https://www.linkedin.com/in/your-linkedin-id-here/',
      linkedinURLNotFound: 'Oops! Something went wrong when we tried to import information using the URL you provided. Check the URL and try again.',
      linkedinURLEmptyParsedInfo: 'Oops! We tried but were not able to import your information. You can upload your resume below, or',
      linkedinURLEmptyParsedInfoLink: 'manually create your profile',
      importNotes: "In order for us to make your experience as relevant as possible it's important to get to know you better. You can do this by entering your LinkedIn profile URL or by uploading a recent resume.",
      confirmNoAddedExperience: "Thanks, but we couldn't find any additional experiences or education.",
      LINKEDIN_IMPORTER_TYPES: {
        ONBOARDING: 'onboarding',
        PROFILE: 'profile',
        RETRY: 'retry',
      },
      resumeUploader: {
        formatNotes: `Acceptable file formats: ${resumeUploaderFormatsForDisplay} (10MB maximum file size).`,
        dragNotes: 'Drop your resume here',
        uploadNotes: 'Upload your resume',
        wrongFormatMessage: `The file must be in one of these formats: ${resumeUploaderFormatsForDisplay}.`,
        fileSizeLimitMessage: 'Oops, the file is larger than 10MB. Please upload another one.',
      },
      confirmExperiencesButtonText: 'Confirm all',
      resetExperiencesButtonText: 'Clear and restart',
      resetExperiencesSnackbar: 'User information successfully cleared',
    },
    experiences: {
      header: 'Your experiences',
      description: 'You are so much more than your current job title. Create a profile to get better recommendations for your future.',
      confirmExperiencesDescription: 'These experiences will be added to your profile in Grow. You can go back to your profile at any time to make changes.',
    },
    skills: {
      header: 'Skills and languages',
      description: 'This section covers areas of proficiency like tools & technologies. Add your skills and languages and we will find opportunities tailored to you.',
    },
    survey: {
      header: 'Check in',
      description: 'Grow will check in occasionally to recalibrate and assess how we can best help. Answer five quick questions to set a baseline so we can get started.',
      disclaimer: 'This information is collected in aggregate to improve the Grow platform and won\'t be individually shared with anyone.',
    },
  },
  profileSummary: {
    summaryWidth: '75%',
    generatedBio: {
      cardTitle: 'Tell us about yourself',
      cardText: 'Generate a personalized bio that highlights your personality, skills, and aspirations. Those who complete this are more likely to secure an opportunity and connect with others.',
      modalTitle: 'Generate bio',
      modalText: 'Add your unique touch to this AI-generated bio, or start from scratch. Either way, this is your space to shine.',
      generateAnotherActionText: 'Generate another bio',
      buttonText: 'Generate bio',
      loadingText: 'Generating',
    },
    notGeneratedBio: {
      cardTitle: 'Tell us about yourself',
      cardText: 'Write a personalized bio that highlights your personality, skills, and aspirations. Those who complete this are more likely to secure an opportunity and connect with others.',
      modalTitle: 'Add bio',
      modalText: 'Add some color to your profile with a short blurb about your goals, aspirations or interests.',
      buttonText: 'Add bio',
    },
  },
  talentGpt: {
    welcomeButton: "Let's do it",
    welcomeTimeEstimate: 'Approx 4 minutes',
    welcomeText: "Let's get to know you",
    welcomeSubtitle: "In order for us to make your experience as relevant as possible we'd like to ask you a few questions.",
    terms: 'This feature is provided by Beamery TalentGPT.',
    termsLink: 'https://support.grow.beamery.com/hc/en-us/articles/16130432582803-What-is-TalentGPT-',
    termsLinkText: 'Learn more',
    staticIconPath: 'svg/ai.svg',
    chatOptions: {
      careerNavigation: ({ userName, jobFamilyAlias }) => ({
        message: `Hey there, ${userName}! Welcome to your personalized career navigation assistant. How can I assist you on your professional journey today?`,
        options: [
          { action: 'five_questions', text: 'Ask me anything, and I\'ll provide you with personalized career guidance' },
          { action: 'career_pathway', text: `Discover how to smoothly transition to an exciting new ${jobFamilyAlias}` },
        ],
      }),
      pursuits: ({ userName }) => ({
        message: `Hey there, ${userName}! Let's chat about your career goals. Do you know where you want to go in your career?`,
        options: [
          { action: 'role_selector', text: 'I know where I want to go' },
          { action: 'pursuits', text: "I'm not sure" },
        ],
      }),
      chatInputLoading: 'Grow is typing',
      chatInputEnabledText: 'Or answer in your own words...',
      chatInputDisabledText: 'Select an answer to continue',
      chatInputNoOptions: 'Start typing...',
    },
    aspirations: {
      bannerHeader: "Let's understand more about you",
      bannerContent: "In order for us to make your experience as relevent as possible, we'd like to ask you a few questions about your aspirations.",
      historyHeader: 'Conversation history',
      historyContent: "Below is a transcript of the conversation you had when you set up your Grow profile. We'll use your responses to fine-tune your experience. You can clear or restart this conversation at any time.",
      historyRestartButton: 'Restart',
      historyClearButton: 'Clear history',
    },
    mentorship: {
      beMentee: "👂 I'd love to find a mentor!",
      beMentor: "🧠 I'd love to be a mentor!",
      beMentorAndMentee: "⭐️ I'd be interested in both!",
      notInterested: "⛔️ I'm not interested",
    },
    profileSetup: {
      resumeUploaderInputId: 'resumeUploaderInput',
      message: "Thank you for the info! We've updated your profile based on your career aspirations. You can always edit your experiences, skills, and interests on your profile. \nLastly, you can further enrich your profile by providing your LinkedIn profile URL, or by uploading a resume.",
      options: [
        { action: 'upload_resume', text: '📄 Upload resume' },
        { action: 'go_to_profile', text: "🕐 Let's do this another time" },
      ],
    },
  },
  requisition: {
    saveAsDraftErrorMessage: 'Please add a title before saving as a draft',
    endDatePassedAcceptedApplicationAlert: 'The end date might have passed. Do you want to mark complete and add this experience to your profile?',
    submittedNotApprovedMessage: "Opportunity submitted, we'll notify when it's been approved and can be opened",
    submitted: 'Submitted',
    submittedReplacement: 'Submitted',
    anyLocation: 'Any office location',
    operations: {
      reopen: {
        reopenNoticeMessage: 'This should only be used if you need to reopen the opportunity in order to accept a different applicant on the candidate board. Otherwise, you can use the “Copy” functionality to re-create this opportunity.',
        reopenNoticeNote: [
          'All applicants who weren’t decided upon will be put back into consideration and notified as such.',
          'Time to fill stats will be based on the reopening date.',
        ],
      },
    },
    workContextFields: {
      workOversight: {
        name: 'Oversight',
        placeholder: 'Frequency of interaction',
        options: [
          {
            id: 'none',
            name: 'None',
          },
          {
            id: 'monthly_interaction',
            name: 'Monthly interaction',
          },
          {
            id: 'weekly_interaction',
            name: 'Weekly interaction',
          },
          {
            id: 'daily_interaction',
            name: 'Daily interaction',
          },
          {
            id: 'dependent_on_applicant',
            name: 'Dependent on applicant',
          },
        ],
      },
      workGuidance: {
        name: 'Guidance',
        placeholder: 'Level of direction',
        options: [
          {
            id: 'limited',
            name: 'Limited',
          },
          {
            id: 'responsive',
            name: 'Responsive',
          },
          {
            id: 'active',
            name: 'Active',
          },
        ],
      },
      workContentState: {
        name: 'State of what will be worked on',
        placeholder: 'New, evolving, established, ...',
        options: [
          {
            id: 'new',
            name: 'New',
          },
          {
            id: 'evolving',
            name: 'Existing but evolving',
          },
          {
            id: 'established',
            name: 'Established and scaling or optimizing',
          },
          {
            id: 'maintaining',
            name: 'Maintaining',
          },
        ],
      },
      workFocus: {
        name: 'Work focus',
        placeholder: 'Narrow or broad?',
        options: [
          {
            id: 'narrow',
            name: 'Narrow (within a department or line of business)',
          },
          {
            id: 'broad',
            name: 'Broad (across departments or lines of business)',
          },
        ],
      },
      workFrequency: {
        default: {
          name: 'Frequency',
          placeholder: 'E.g. daily / weekly / monthly interaction',
          options: [
            {
              id: 'daily',
              name: 'Daily',
            },
            {
              id: 'weekly',
              name: 'Weekly',
            },
            {
              id: 'monthly',
              name: 'Monthly',
            },
            {
              id: 'self_study',
              name: 'Self study',
            },
            {
              id: 'custom_period',
              name: 'Custom period',
            },
          ],
        },
        mentorship: {
          name: 'Frequency',
          placeholder: 'E.g. Only once / weekly / biweekly / monthly interaction',
          options: [
            {
              id: 'once',
              name: 'Once',
            },
            {
              id: 'weekly',
              name: 'Weekly',
            },
            {
              id: 'biweekly',
              name: 'Biweekly',
            },
            {
              id: 'monthly',
              name: 'Monthly',
            },

          ],
        },
      },
      workCompletionMethod: {
        name: 'Completion method',
        placeholder: 'Testing, project, acceptance, etc',
        options: [
          {
            id: 'tested',
            name: 'Tested',
          },
          {
            id: 'presentation',
            name: 'Presentation',
          },
          {
            id: 'honor_system',
            name: 'Honor system',
          },
        ],
      },
      workContentPrimary: {
        name: 'Primarily working with',
        options: workContent,
      },
      workContentSecondary: {
        name: 'Secondarily working with',
        options: workContent,
      },
    },
    filterLabels: {
      processStage: 'Process stage',
      type: 'Opportunity types',
      state: 'Opportunity states',
      matched: {
        label: 'Recommendations',
        switchLabel: 'Only show recommended opportunities',
        tooltip: 'Recommended opportunities are tailored to align with your skills, interests, and the frequency with which others in your role have successfully pursued similar paths. Only opportunities that are currently open and align with your eligibility criteria are recommended.',
      },
      eligibility: {
        label: 'Eligibility',
        switchLabel: 'Only show opportunities I\'m eligible for',
      },
      reportsTo: 'Reports to',
      program: 'Programs',
      location: 'Opportunity locations',
      group: 'Opportunity org groups',
      skillsGained: 'Skills utilized',
      role: '{{jobFamily}}',
      openToRemote: 'Open to remote',
      ownership: 'Opportunity ownership',
      date: 'Opportunity dates',
    },
    sortOrderFilterItems: [
      { id: 'newest', name: 'Newest first', e2eTag: 'newest' },
      { id: 'oldest', name: 'Oldest first', e2eTag: 'oldest' },
      { id: 'alphabet', name: 'A - Z', e2eTag: 'alphabet' },
      { id: 'reverse-alphabet', name: 'Z - A', e2eTag: 'reverse-alphabet' },
    ],
    ownershipFilterItems: [
      { id: 'createdByMe', name: 'Created by me' },
      { id: 'meAsHiringManager', name: "I'm the hiring manager" },
      { id: 'meAsCollaborator', name: "I'm a collaborator" },
    ],
    externalATSControlledFields: [
      'type',
      'title',
      'description',
      'state',
      'hiringManagerUserId',
      'locations',
      'groupId',
      'numberOfOpenings',
      'applicationDeadline',
      'collaborators',
    ],
    externalLMSControlledFields: [
      'type',
      'title',
      'description',
      'state',
      'hiringManagerUserId',
    ],
    workWeekHours: 40,
    // TODO: Once companies and users are able to create custom templates,
    // we will need to store this information in the DB
    templates: {
      10001: {
        color: '#A6E5CB',
        image: 'Product Launch.svg',
      },
      10000: {
        color: '#5BAEC8',
        image: 'Special Task Force.svg',
      },
      10002: {
        color: '#EFBAE2',
        image: 'Data Analysis.svg',
      },
      10003: {
        color: '#4BAFA9',
        image: 'Temporary Assignment.svg',
      },
      10004: {
        color: '#FEBF9C',
        image: 'Company Training.svg', // Needs new asset from Adam
      },
      10005: {
        color: '#F8D186',
        image: 'RFP Process.svg',
      },
      10006: {
        color: '#F8B9B9',
        image: 'Job Rotation.svg',
      },
      10007: {
        color: '#D2747B',
        image: 'Skunk Works.svg',
      },
      10008: {
        color: '#CAACF2',
        image: 'Internal roll out.svg',
      },
      10009: {
        color: '#A775E9',
        image: 'User Acceptance Testing.svg',
      },
      10010: {
        color: '#9BC9FF',
        image: 'Internal Focus Group.svg',
      },
      10011: {
        color: '#515592',
        image: 'Event Planning.svg',
      },
      default: {
        color: '#515592',
        image: 'Event Planning.svg',
      },
    },
    summaryItem: {
      ineligibilityLabel: {
        text: 'Not eligible to apply',
        tooltip: 'View full opportunity details to learn more',
      },
    },
  },
  program: {
    programState: 'Program states',
    programGroup: 'Opportunity org groups',
  },
  eligibility: {
    addRulesLabel: 'Add additional rules',
    relationOptions: [
      {
        text: 'All',
        value: 'all',
      },
      {
        text: 'Any',
        value: 'any',
      },
    ],
    everyoneEligibleCondition: {
      entityType: 'user',
      operator: 'is_anyone',
      value: [],
    },
  },
  role: {
    skills: {
      topSkillsByJobScore: {
        header: 'Skills from opportunities in this {{jobFamily}}',
      },
      topSkillsByUserScore: {
        header: 'Skills from employees in this {{jobFamily}}',
      },
    },
    jaRoleSelectorText: 'Select role manually...',
  },
  application: {
    applicantMessageTitle: "Anything else you'd like the hiring manager to know?",
    disqualificationOptions: [
      'Existing critical obligation',
      'Performance concern',
    ],
    snackbarOptions: {
      submit: {
        e2eTag: 'candidateAdded',
        message: 'Candidate has been added for consideration',
        icon: 'done',
      },
      extendOffer: {
        e2eTag: 'candidateOfferExtended',
        message: 'Offer has been extended to candidate!',
        icon: 'thumb_up',
      },
      retractOffer: {
        e2eTag: 'candidateOfferRetracted',
        message: 'Offer has been retracted',
        icon: 'thumb_down',
      },
      reject: {
        e2eTag: 'candidateRemoved',
        message: 'Candidate has been removed from consideration',
        icon: 'block',
      },
      reconsider: {
        e2eTag: 'candidateReturned',
        message: 'Candidate has been returned to consideration',
        icon: 'done',
      },
      accept: {
        e2eTag: 'applicationAccepted',
        message: 'Application accepted',
        icon: 'thumb_up',
      },
      acceptApplicant: {
        e2eTag: 'applicationToLearningOrMentorshipAccepted',
        message: 'Application accepted',
        icon: 'thumb_up',
      },
      cancel: {
        e2eTag: 'applicationCanceled',
        message: 'Application has been withdrawn',
        icon: 'done',
      },
      complete: {
        e2eTag: 'applicationCompleted',
        message: 'Experience is marked as completed for user!',
        icon: 'thumb_up',
      },
      uncomplete: {
        e2eTag: 'applicationUncompleted',
        message: 'Experience has been returned to in-progress',
        icon: 'done',
      },
      confirmComplete: {
        e2eTag: 'applicationConfirmedCompleted',
        message: 'Experience is confirmed complete for user!',
        icon: 'thumb_up',
      },
      processStage: {
        e2eTag: 'applicationStageUpdated',
        message: 'Application stage has been updated',
        icon: 'swap_horiz',
      },
    },
    secondaryActions: {
      complete: {
        display: 'Mark complete',
        eventType: 'complete-application',
      },
      confirmExternalComplete: {
        display: 'Confirm completion',
        eventType: 'confirm-external-complete',
      },
      view: {
        display: 'View application',
        eventType: 'state-clicked',
      },
      cancel: {
        display: 'Withdraw application',
        eventType: 'withdraw-application',
        dialogBody: 'Withdrawing your application will remove you from consideration for this opportunity. Are you sure?',
        primaryActionText: 'Withdraw',
      },
      cancelRequestToApply: {
        display: 'Withdraw request to apply',
        eventType: 'withdraw-request-to-apply',
        dialogBody: 'Are you sure you want to withdraw your request to apply to this opportunity?',
        primaryActionText: 'Withdraw request',
      },
      feedback: {
        display: 'View feedback',
        eventType: 'view-feedback',
      },
    },
    userStates: [
      {
        name: 'awaiting_approval',
        displayName: 'Pending manager approval',
        backgroundColor: 'status',
        classNames: 'primary--text',
        icon: 'slow_motion_video',
      },
      {
        name: 'awaiting_decision',
        displayName: 'Awaiting decision',
        backgroundColor: 'status',
        classNames: 'primary--text',
        icon: 'slow_motion_video',
      },
      {
        name: 'approved_to_apply',
        displayName: 'Approved to apply',
        backgroundColor: 'status',
        classNames: 'primary--text',
        icon: 'thumb_up_off_alt',
      },
      {
        name: 'offer_extended',
        displayName: 'Offer extended',
        backgroundColor: 'extended9',
        classNames: 'black--text',
        icon: null,
      },
      {
        name: 'declined',
        displayName: 'Declined',
        backgroundColor: 'secondary0',
        classNames: 'black--text',
        icon: null,
      },
      {
        name: 'accepted',
        displayName: 'Accepted',
        backgroundColor: 'success',
        classNames: 'white--text',
        icon: 'check',
      },
      {
        name: 'completed',
        displayName: 'Completed',
        backgroundColor: 'primary',
        classNames: 'white--text',
        icon: 'check',
      },
      {
        name: 'pending_completion_confirmation',
        displayName: 'Pending completion confirmation',
        backgroundColor: 'primary1',
        classNames: 'white--text',
      },
      {
        name: 'completion_confirmed',
        displayName: 'Completion confirmed',
        backgroundColor: 'primary',
        classNames: 'white--text',
        icon: 'check',
      },
      {
        name: 'missed',
        displayName: 'Missed',
        backgroundColor: 'secondary0',
        classNames: 'black--text',
        icon: null,
      },
      {
        name: 'manager_denied',
        displayName: 'Manager denied',
        backgroundColor: 'extended8',
        classNames: 'black--text',
        icon: null,
      },
      {
        name: 'withdrawn',
        displayName: 'Withdrawn',
        backgroundColor: 'secondary0',
        classNames: 'black--text',
        icon: null,
      },
    ],
  },
  recommendations: {
    shareLinkCopied: {
      message: 'Shareable link copied!',
      icon: 'link',
    },
  },
  errors: {
    code403: 'Oops! Insufficient authorization level. Please contact your administrator.',
    generic: 'Oops! An error occurred. Please refresh the page and try again.',
  },
  suppressedVueWarningMessages: [
    // ignoring errors when specifying v-treeview with selectionType "top"
    // https://github.com/vuetifyjs/vuetify/issues/9088
    'Invalid prop: custom validator check failed for prop "selectionType".',
  ],
  feedItem: {
    DISMISSED: 'dismissed',
    FINISHED: 'finished',
  },
  pathways: {
    noPathsText: 'We have not yet collected enough data on this {{jobFamily}} to display paths.',
    narrowDisplayWarningText: 'This view is not optimized for narrow displays',
  },
  qualities: {
    documentationUrl: 'https://support.grow.beamery.com/hc/en-us/articles/9982368491155',
    surveyPrompts: {
      default: {
        title: 'Get started with Qualities',
        description: 'This initial survey should take 5 minutes and will generate a visual representation of your Qualities',
      },
      outdated: {
        title: 'We\'ve updated Qualities',
        description: 'We have improved how we measure and represent the human parts of the work equation.  This survey takes 5 minutes and will generate an improved representation of your Quality values.',
      },
    },
    talentAlerts: {
      default: 'You are more than just a set of skills and past work history. Qualities represent the "human elements" that differentiate us from one other. Let\'s express your working preferences.',
    },
    operationsAlerts: {
      default: 'There are currently no quality values to display as we are still gathering information from this employee.',
    },
    alerts: {
      requisition: {
        currentUserWithQualitiesComparison: 'This is a representation of how your Quality values compare to the {{jobFamily}} related to this opportunity.  This is purely directional, and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
        currentUserWithoutQualitiesComparison: 'This is a representation Grow currently understands for this {{jobFamily}} based on what we\'ve observed.  If you take our Qualities survey you can see how your values align.  This is purely directional, and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
        talentApplicationWithQualitiesComparison: 'This is a representation of how this employee\'s Quality values compare to the {{jobFamily}} related to this opportunity. It is directional and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
        operationsUserNoComparison: 'This is a representation Grow currently understands for this {{jobFamily}} based on what we\'ve observed. This is purely directional, and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
      },
      role: {
        currentUserWithQualitiesComparison: 'This is a representation of how your Quality values compare to this {{jobFamily}}.  This is purely directional, and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
        currentUserWithoutQualitiesComparison: 'This is a representation Grow currently understands for this {{jobFamily}} based on what we\'ve observed.  If you take our Qualities survey you can see how your values align.  This is purely directional, and intended to help you understand how more personal factors may be similar or differ from those in the {{jobFamilies}} Grow has observed.',
        operationsUserNoComparison: 'This is a representation of values Grow currently understands for this {{jobFamily}} based on what we\'ve observed. Grow can use these values to compare candidates to opportunities you\'re staffing if they\'ve completed the Qualities survey.',
      },
    },
    groups: [
      {
        id: 'thinking',
        name: 'Thinking',
        color: 'primary1',
        features: ['Realist', 'Creative', 'Decisive', 'Contemplative', 'Systematic', 'Intuitive'],
        featureDescriptions: {
          Realist: 'prefers conventional thought and concrete approaches',
          Creative: 'prefers abstract ideas even if they diverge from mainstream',
          Decisive: 'prefers efficient, definitive decisions',
          Contemplative: 'prefers complete exploration',
          Systematic: 'prefers structure and linear thought',
          Intuitive: 'prefers approaching new topics or concepts by finding natural connections to past experiences',
        },
      },
      {
        id: 'working',
        name: 'Working',
        color: 'extended6',
        features: ['Completeness', 'Efficiency', 'Initiate', 'Optimize', 'Ambiguity', 'Structure'],
        featureDescriptions: {
          Completeness: 'prefers to approach work with belief that there is "right way" to do something and that is of paramount value',
          Efficiency: 'prefers to approach work by weighing constraints when choosing the prudent one to apply',
          Initiate: 'prefers to work unconstrained, starting with a blank sheet of paper and inventing an optimal solution',
          Optimize: 'prefers to work with something that exists - a problem, system or process - and improve it',
          Ambiguity: 'prefers work that is flexible and without strict instructions to follow',
          Structure: 'prefers work that has order and that allows for a focus on details',
        },
      },
      {
        id: 'interacting',
        name: 'Interacting',
        color: 'extended2',
        features: ['External', 'Internal', 'Independent', 'Collaborative', 'Direction', 'Lead'],
        featureDescriptions: {
          External: 'prefers motivation through things that are globally valued, such as rewards and public acknowledgement',
          Internal: 'prefers motivation through personal accomplishment and intrinsic goals',
          Independent: 'prefers having a lot of autonomy and time to work alone',
          Collaborative: 'prefers work that is shared by a team',
          Direction: 'prefers having a lot of guidance and instruction',
          Lead: 'prefers guiding and managing teams and individuals',
        },
      },
    ],
    descriptions: {
      thinking: 'Individual\'s cognitive approach and style. How they mentally engage with their work and environment',
      working: 'Individual\'s work preference and style. What type of work do they like best, and what approach is preferred',
      interacting: 'How an individual engages with others, including managers, as well as how others work with them to help motivate',
    },
    secondPersonDescriptions: {
      thinking: 'Your cognitive approach and style. How you mentally engage with your work and environment',
      working: 'Your work preference and style. What type of work you like best, and what approach you prefer',
      interacting: 'How you engage with others, including managers, as well as how others work with you to help motivate',
    },
  },
  survey: {
    viewTypes: {
      feedInline: 'feed_inline',
      surveyPage: 'survey_page',
      surveyPagePerExperience: 'survey_page_per_experience',
      dialog: 'dialog',
    },
  },
  collaborators: {
    program: {
      description: 'Collaborators will be able to edit, open, and close this program and its associated opportunities as well as view and manage any candidates. Grow will notify them once this permission is granted and you can remove a collaborator at any time by clicking the delete icon.',
    },
    requisition: {
      description: 'Collaborators will be able to edit, open, and close this opportunity as well as view and manage any candidates. Grow will notify them once this permission is granted and you can remove a collaborator at any time by clicking the delete icon.',
    },
  },
  promotedSkills: {
    uber: {
      label: 'Competencies',
      singular: 'competency',
      plural: 'competencies',
    },
  },
  feedback: {
    opportunityTypes: ['rotation', 'project'],
    // legacy text for feedback that is not shared between manager and employee
    notShared: {
      modalSections: {
        attributes: 'I enjoyed working...',
        skills: 'I enjoyed using these skills...',
        comments: {
          adding: 'Any additional comments?',
          reviewing: 'Additional comments',
        },
      },
      disclaimer: 'This information is collected to improve the Grow platform. Grow may also share information provided in an anonymized and aggregated form with your employer.',
    },
    // current text for feedback that is shared between manager and employee
    shared: {
      modalSections: {
        attributes: 'I enjoyed working...',
        skills: 'I enjoyed using these skills...',
        comments: {
          adding: 'Any comments to share with {sharedName}?',
          reviewing: 'Comments shared with {sharedName}',
          doingWell: 'What did {applicantName} do well?',
          impact: 'What could have enabled {applicantName} to have an even greater impact on this opportunity?',
        },
        satisfactionFeedbacksQuestions,
      },
      disclaimer: {
        talent: {
          default: 'This information is collected and shared with the manager(s). Grow may also share information provided in an anonymized and aggregated form with your employer.',
          mentorship: 'This information is collected and shared with the mentor(s). Grow may also share information provided in an anonymized and aggregated form with your employer.',
        },
        operations: { default: 'This information is collected and shared with the employee. Grow may also share information provided in an anonymized and aggregated form with your employer.' },
      },
    },
  },
  userProfileUpdates: {
    title: 'Congrats on completing',
  },
  reporting: {
    documentation: {
      metrics: {
        tooltip: 'Click here to see metric definitions',
        href: 'https://support.grow.beamery.com/hc/en-us/articles/10009215473939',
      },
    },
    segmentTypes: [
      {
        id: 'group',
        name: 'Employee Org Group',
        menuType: 'treeView',
      },
      {
        id: 'location',
        name: 'Employee Location',
        menuType: 'treeView',
      },
      {
        id: 'company_tenure',
        name: 'Company Tenure',
      },
      {
        id: 'title_tenure',
        name: 'Title Tenure',
      },
      {
        id: 'ethnicity',
        name: 'Ethnicity',
      },
      {
        id: 'gender',
        name: 'Gender',
      },
      {
        id: 'age',
        name: 'Age',
      },
    ],
    employeeStatusOptions: [{
      id: 'can_login',
      name: 'Can login',
    }, {
      id: 'deactivated',
      name: 'Deactivated',
    }],
    intervalOptions: [
      {
        id: 'week',
        name: 'Week over week',
      },
      {
        id: 'month',
        name: 'Month over month',
      },
      {
        id: 'quarter',
        name: 'Quarter over quarter',
      },
    ],
    engagement: {
      reports: [
        {
          type: 'companyUserEngagement',
          name: 'Engagement dashboard',
          method: 'inline',
          filterTypes: ['cannedDates', 'interval', 'groupsForUsers', 'locationsForUsers', 'levels'],
          apiParams: ['fromDate', 'toDate', 'interval', 'groupIds', 'locationIds', 'levelIds'],
          showMetricsInfoLink: true,
        },
        {
          type: 'companyUserEngagementBySegment',
          name: 'Engagement by segment',
          method: 'inline',
          filterTypes: ['cannedDates', 'segmentType', 'segmentIds'],
          apiParams: ['fromDate', 'toDate', 'segmentType', 'segmentIds'],
          showMetricsInfoLink: true,
        },
      ],
    },
    management: {
      reports: [
        {
          type: 'companyOpportunityManagement',
          name: 'Management dashboard',
          method: 'inline',
          filterTypes: ['cannedDates', 'interval', 'groupsForJobs', 'locationsForJobs', 'requisitionTypes'],
          apiParams: ['fromDate', 'toDate', 'interval', 'groupIds', 'locationIds', 'requisitionTypeIds'],
          showMetricsInfoLink: true,
        },
        {
          type: 'managementByOrgGroup',
          name: 'Management by org group',
          method: 'inline',
          filterTypes: ['cannedDates', 'groupsForJobs', 'locationsForJobs', 'requisitionTypes'],
          apiParams: ['fromDate', 'toDate', 'groupIds', 'locationIds', 'requisitionTypeIds'],
        },
      ],
    },
    population: {
      reports: [
        // Update 'useJFGWorkforceReportDefault'/InsightsFilters.vue when legacy report is deprecated.
        {
          type: 'workforceCompositionJobFamilyLegacy',
          name: 'Workforce composition',
          method: 'inline',
          filterTypes: ['segmentType', 'segmentIds', 'employeeStatus'],
          apiParams: ['segmentType', 'segmentIds', 'employeeStatus'],
          showMetricsInfoLink: true,
          featureSwitch: 'showLegacyWorkforceReport',
        },
        {
          type: 'workforceCompositionJobFamilyGroup',
          name: 'Workforce composition by job family group',
          method: 'inline',
          filterTypes: ['segmentType', 'segmentIds', 'employeeStatus'],
          apiParams: ['segmentType', 'segmentIds', 'employeeStatus'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJAWorkforceReports',
        },
        {
          type: 'workforceCompositionJobFamily',
          name: 'Workforce composition by job family',
          method: 'inline',
          filterTypes: ['segmentType', 'segmentIds', 'employeeStatus', 'singleDomainRequired'],
          apiParams: ['segmentType', 'segmentIds', 'employeeStatus', 'domainIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJAWorkforceReports',
        },
        {
          type: 'workforceCompositionJobArchitectureRole',
          name: 'Workforce composition by role',
          method: 'inline',
          filterTypes: ['segmentType', 'segmentIds', 'employeeStatus', 'singleRoleRequired'],
          apiParams: ['segmentType', 'segmentIds', 'employeeStatus', 'roleIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJAWorkforceReports',
        },
        {
          type: 'careerSentiment',
          name: 'Career sentiment',
          method: 'inline',
          filterTypes: ['cannedDates', 'interval', 'employeeStatus', 'groupsForUsers', 'locationsForUsers'],
          defaultFilterParamOverrides: {
            fd: {
              dataFunction: (t) => t.user.company.launchedAt?.substring(0, 10)
                || t.user.company.createdAt.substring(0, 10),
            },
            int: {
              default: 'quarter',
            },
          },
          apiParams: ['fromDate', 'toDate', 'interval', 'employeeStatus', 'groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          showHideDetails: careerSentimentSurveyKeyConfig,
        },
        {
          type: 'careerSentimentBySegment',
          name: 'Career sentiment by segment',
          method: 'inline',
          filterTypes: ['cannedDates', 'segmentType', 'segmentIds'],
          defaultFilterParamOverrides: {
            fd: {
              dataFunction: (t) => t.user.company.launchedAt?.substring(0, 10)
                || t.user.company.createdAt.substring(0, 10),
            },
          },
          apiParams: ['fromDate', 'toDate', 'segmentType', 'segmentIds'],
          showMetricsInfoLink: true,
          showHideDetails: careerSentimentSurveyKeyConfig,
        },
        {
          type: 'tenure',
          name: 'Tenure',
          method: 'amCharts',
          featureSwitch: 'showTenureReport',
          downloadOptions: ['pdf'],
        },
      ],
    },
    mobility: {
      reports: [
        {
          type: 'mobility',
          name: 'Historical Trends',
          method: 'inline',
          filterTypes: ['cannedDates', 'segmentType', 'segmentIds', 'requisitionTypes'],
          apiParams: ['fromDate', 'toDate', 'segmentType', 'segmentIds', 'requisitionTypeIds'],
          defaultFilterParamOverrides: {
            rtid: {
              dataFunction: (data) => data.requisitionReadTypeOptions
                .filter((typeObj) => typeObj.settings.isIncludedInMobilityReport)
                .map((obj) => obj.id),
            },
          },
        },
        {
          type: 'mobilityFromJARole',
          name: 'Talent Export to Open Jobs',
          method: 'inline',
          filterTypes: ['singleDomainRequired', 'singleRoleRequired', 'singleJARoleRequired'],
          apiParams: ['domainIds', 'roleIds', 'jaRoleIds'],
          featureSwitch: 'showMobilityFromJARoleReport',
        },
      ],
    },
    supplyDemand: {
      reports: [
        {
          type: 'supplyDemandByDomain',
          name: 'Overall breakdown',
          method: 'inline',
          filterTypes: ['groupsForUsers', 'locationsForUsers'],
          apiParams: ['groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showLegacySupplyDemandReports',
        },
        {
          type: 'supplyDemandByRole',
          name: 'Job family breakdown',
          method: 'inline',
          filterTypes: ['singleDomainRequired', 'groupsForUsers', 'locationsForUsers'],
          apiParams: ['domainIds', 'groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showLegacySupplyDemandReports',
        },
        {
          type: 'supplyDemandByJobFamilyGroup',
          name: 'Job family group comparison',
          method: 'inline',
          filterTypes: ['groupsForUsers', 'locationsForUsers'],
          apiParams: ['groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJASupplyDemandReports',
        },
        {
          type: 'supplyDemandByJobFamily',
          name: 'Job family comparison',
          method: 'inline',
          filterTypes: ['singleDomainRequired', 'groupsForUsers', 'locationsForUsers'],
          apiParams: ['domainIds', 'groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJASupplyDemandReports',
        },
        {
          type: 'supplyDemandByJARole',
          name: 'Role comparison',
          method: 'inline',
          filterTypes: ['singleRoleRequired', 'groupsForUsers', 'locationsForUsers'],
          apiParams: ['roleIds', 'groupIds', 'locationIds'],
          showMetricsInfoLink: true,
          featureSwitch: 'showJASupplyDemandReports',
        },
      ],
    },
    dataFeeds: {
      reports: [
        {
          type: 'userFeed',
          name: 'User feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'opportunityFeed',
          name: 'Opportunity feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'applicationFeed',
          name: 'Application feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'hiringManagerSlaBreachFeed',
          name: 'Hiring manager SLA breach feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'pendingApplicationApprovalFeed',
          name: 'Pending application approval feed',
          method: 'inline',
          apiParams: [],
        },
      ],
    },
    program: {
      reports: [
        {
          type: 'programUserEngagement',
          name: 'Engagement dashboard',
          method: 'inline',
          modeTypes: ['eligibleUsers'],
          filterTypes: ['cannedDates', 'interval', 'groupsForUsers', 'locationsForUsers', 'levels'],
          apiParams: ['fromDate', 'toDate', 'interval', 'groupIds', 'locationIds', 'levelIds', 'eligibleUserOnly'],
          showMetricsInfoLink: true,
        },
        {
          type: 'programOpportunityManagement',
          name: 'Management dashboard',
          method: 'inline',
          filterTypes: ['cannedDates', 'interval', 'groupsForJobs', 'locationsForJobs'],
          apiParams: ['fromDate', 'toDate', 'interval', 'groupIds', 'locationIds'],
          showMetricsInfoLink: true,
        },
        {
          type: 'hiringManagerSlaBreachFeed',
          name: 'Hiring manager SLA breach feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'pendingApplicationApprovalFeed',
          name: 'Pending application approval feed',
          method: 'inline',
          apiParams: [],
        },
        {
          type: 'applicationFeed',
          name: 'Application feed',
          method: 'inline',
          apiParams: [],
        },
      ],
      queryParams: ['programId', 'programIds'],
    },
    requisition: {
      reports: [
        {
          type: 'requisitionUserEngagement',
          name: 'Engagement dashboard',
          method: 'inline',
          modeTypes: ['eligibleUsers'],
          filterTypes: ['cannedDates', 'interval', 'levels'],
          apiParams: ['fromDate', 'toDate', 'interval', 'levelIds', 'eligibleUserOnly'],
          showMetricsInfoLink: true,
        },
      ],
      queryParams: ['requisitionId', 'requisitionIds'],
    },
    inlineReports: {
      userFeed: {
        fields: [
          {
            type: 'heading',
            value: 'User feed',
          },
          {
            type: 'table',
            dataSource: 'userFeed',
            isServerSidePaginated: true,
          },
        ],
      },
      opportunityFeed: {
        fields: [
          {
            type: 'heading',
            value: 'Opportunity feed',
          },
          {
            type: 'table',
            dataSource: 'opportunityFeed',
            isServerSidePaginated: true,
          },
        ],
      },
      applicationFeed: {
        fields: [
          {
            type: 'heading',
            value: 'Application feed',
          },
          {
            type: 'table',
            dataSource: 'applicationFeed',
            isServerSidePaginated: true,
          },
        ],
      },
      hiringManagerSlaBreachFeed: {
        queryNames: ['hiringManagerSlaBreachFeed'],
        fields: [
          {
            type: 'heading',
            value: 'Hiring manager SLA breach feed',
          },
          {
            type: 'table',
            dataSource: 'hiringManagerSlaBreachFeed',
            isReportSearchable: true,
            isServerSidePaginated: false,
          },
        ],
      },
      pendingApplicationApprovalFeed: {
        queryNames: ['pendingApplicationApprovalFeed'],
        fields: [
          {
            type: 'heading',
            value: 'Pending application approvals feed',
          },
          {
            type: 'table',
            dataSource: 'pendingApplicationApprovalFeed',
            isReportSearchable: true,
            isServerSidePaginated: false,
          },
        ],
      },
      opsDashboardUserEngagement: {
        queryNames: ['userEngagement'],
        fields: [
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'usersWithAccessCount',
                'onboardedUserCount',
                'activeUserCount',
                'opportunityView',
                'interestCount',
                'applicationCount',
              ],
              dataSourceSubMetrics: 'userEngagement',
              subMetricValue: [
                'onboardedUserRatio',
                'activeUserRatio',
              ],
            }],
          },
        ],
      },
      opsDashboardOpportunityManagement: {
        queryNames: [
          'opportunityManagement',
          'opportunityManagementCurrent',
        ],
        fields: [
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'openOpeningCount',
                'newOpeningCount',
                'fillRate',
                'avgDaysToFill',
                'applicationAvgDaysToDecision',
                'acceptedApplicationCount',
              ],
              dataSourceSubMetrics: 'opportunityManagementCurrent',
              subMetricValue: [
                'applicationSlaInBreachCount',
                'applicationPendingOfferCount',
                'applicationPendingApprovalCount',
              ],
            }],
          },
        ],
      },
      companyUserEngagement: {
        queryNames: [
          'userEngagement',
          'userEngagementTimeSeries',
        ],
        queryParams: {
          includeBookmarks: true,
          includeInterests: true,
        },
        fields: [
          {
            type: 'heading',
            value: 'Users',
          },
          {
            type: 'metrics',
            sections: [
              {
                dataSource: 'userEngagement',
                value: [
                  'usersWithAccessCount',
                  'onboardedUserCount',
                  'newOnboardedUserCount',
                  'activeUserCount',
                ],
                dataSourceSubMetrics: 'userEngagement',
                subMetricValue: [
                  'onboardedUserRatio',
                  'activeUserRatio',
                ],
              },
            ],
          },
          {
            type: 'chart',
            value: [{
              id: 'onboardedTimeSeries',
              methodName: 'createOnboardTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'User trends',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                usersWithAccessCount: {},
                onboardedUserCount: {},
                activeUserCount: {},
                onboardedUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
                activeUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Opportunity views',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'opportunityView',
                'opportunityViewerCount',
                'opportunityViewerToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityViewTimeSeries',
              methodName: 'createOpportunityViewTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Opportunity view trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                opportunityViewCount: {},
                opportunityViewerCount: {},
                avgOpportunityViewCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Bookmarks',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'bookmarkCount',
                'bookmarkUserCount',
                'bookmarkUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'bookmarkTimeSeries',
              methodName: 'createBookmarkTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Bookmark trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                bookmarkCount: {},
                bookmarkUserCount: {},
                avgBookmarkCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Interests',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'interestCount',
                'interestUserCount',
                'interestUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'interestTimeSeries',
              methodName: 'createInterestTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Interest trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                interestCount: {},
                interestUserCount: {},
                avgInterestCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Applications',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'applicationCount',
                'applicantCount',
                'applicantToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationTimeSeries',
              methodName: 'createApplicationTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Application trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                applicationCount: {},
                applicantCount: {},
                avgApplicationCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Application acceptance',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'acceptedApplicationCount',
                'acceptedApplicationUserCount',
                'acceptedApplicationUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'heading',
            value: 'Marked complete',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'completedApplicationCount',
                'completedApplicationUserCount',
                'completedApplicationUserToActiveUserRatio',
              ],
            }],
          },
        ],
      },
      programUserEngagement: {
        queryNames: [
          'userEngagement',
          'userEngagementTimeSeries',
        ],
        queryParams: {
          includeBookmarks: true,
          includeInterests: false,
        },
        fields: [
          {
            type: 'heading',
            value: 'Users',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'usersWithAccessCount',
                'onboardedUserCount',
                'newOnboardedUserCount',
                'activeUserCount',
              ],
              dataSourceSubMetrics: 'userEngagement',
              subMetricValue: [
                'onboardedUserRatio',
                'activeUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'onboardedTimeSeries',
              methodName: 'createOnboardTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'User trends',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                usersWithAccessCount: {},
                onboardedUserCount: {},
                activeUserCount: {},
                onboardedUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
                activeUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Opportunity views',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'opportunityView',
                'opportunityViewerCount',
                'opportunityViewerToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityViewTimeSeries',
              methodName: 'createOpportunityViewTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Opportunity view trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                opportunityViewCount: {},
                opportunityViewerCount: {},
                avgOpportunityViewCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Bookmarks',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'bookmarkCount',
                'bookmarkUserCount',
                'bookmarkUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'bookmarkTimeSeries',
              methodName: 'createBookmarkTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Bookmark trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                bookmarkCount: {},
                bookmarkUserCount: {},
                avgBookmarkCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Applications',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'applicationCount',
                'applicantCount',
                'applicantToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationTimeSeries',
              methodName: 'createApplicationTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Application trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                applicationCount: {},
                applicantCount: {},
                avgApplicationCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Application acceptance',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'acceptedApplicationCount',
                'acceptedApplicationUserCount',
                'acceptedApplicationUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'heading',
            value: 'Marked complete',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'completedApplicationCount',
                'completedApplicationUserCount',
                'completedApplicationUserToActiveUserRatio',
              ],
            }],
          },
        ],
      },
      requisitionUserEngagement: {
        queryNames: [
          'userEngagement',
          'userEngagementTimeSeries',
        ],
        queryParams: {
          includeBookmarks: true,
          includeInterests: false,
        },
        fields: [
          {
            type: 'heading',
            value: 'Opportunity views',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'opportunityView',
                'opportunityViewerCount',
                'opportunityViewerToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityViewTimeSeries',
              methodName: 'createOpportunityViewTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Opportunity view trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                opportunityViewCount: {},
                opportunityViewerCount: {},
                avgOpportunityViewCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Bookmarks',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'bookmarkCount',
                'bookmarkUserCount',
                'bookmarkUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'bookmarkTimeSeries',
              methodName: 'createBookmarkTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Bookmark trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                bookmarkCount: {},
                bookmarkUserCount: {},
                avgBookmarkCountPerUser: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Applications',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'applicationCount',
                'applicantCount',
                'applicantToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationTimeSeries',
              methodName: 'createApplicationTimeSeries',
              dataSource: 'userEngagementTimeSeries',
              filters: ['interval'],
              title: 'Application trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                applicationCount: {},
                applicantCount: {},
                avgApplicationCountPerUser: {},
              },
            }],
          },
          {
            type: 'heading',
            value: 'Application acceptance',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'acceptedApplicationCount',
                'acceptedApplicationUserCount',
                'acceptedApplicationUserToActiveUserRatio',
              ],
            }],
          },
          {
            type: 'heading',
            value: 'Marked complete',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userEngagement',
              value: [
                'completedApplicationCount',
                'completedApplicationUserCount',
                'completedApplicationUserToActiveUserRatio',
              ],
            }],
          },
        ],
      },
      companyOpportunityManagement: {
        queryNames: [
          'opportunityFunnel',
          'opportunityManagement',
          'opportunityManagementTimeSeries',
          'opportunityCountByState',
          'opportunityCountByType',
        ],
        fields: [
          {
            type: 'heading',
            value: 'Opportunity availability',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'opportunitiesSubmittedCount',
                'opportunitiesOpenedCount',
                'opportunitiesClosedCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityTimeSeries',
              methodName: 'createOpportunityTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Opportunity trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                opportunitiesSubmittedCount: {},
                opportunitiesOpenedCount: {},
                opportunitiesClosedCount: {},
                opportunitiesOpenCount: {},
              },
            }],
          },
          {
            type: 'heading',
            value: 'State of all opportunities',
          },
          {
            type: 'description',
            value: 'Tracks the current state of opportunities submitted during the selected time period',
          },
          {
            type: 'chart',
            value: [
              {
                id: 'opportunityByType',
                methodName: 'createOpportunityByType',
                dataSource: 'opportunityCountByType',
                title: 'Opportunity types',
                columnSize: 5,
                dataColumns: {
                  type: {},
                  opportunityCount: {},
                },
              },
              {
                id: 'opportunityByState',
                methodName: 'createOpportunityByState',
                dataSource: 'opportunityCountByState',
                title: 'Opportunity pipeline',
                columnSize: 7,
                dataColumns: {
                  state: {},
                  opportunityCount: {},
                },
              },
            ],
          },
          {
            type: 'heading',
            value: 'Opportunity progression',
            featureSwitch: 'showOpportunityFunnelReport',
          },
          {
            type: 'description',
            value: 'This funnel tracks the current stage of opportunities submitted during the selected time period',
            featureSwitch: 'showOpportunityFunnelReport',
          },
          {
            type: 'chart',
            value: [
              {
                id: 'opportunityFunnel',
                methodName: 'createOpportunityFunnel',
                dataSource: 'opportunityFunnel',
                title: 'Opportunity funnel',
                // actions allow users to be called to action
                actions: {
                  description: 'Take action on opportunities',
                  values: [
                    {
                      displayName: 'View outstanding tasks',
                      path: '/operations/insights?t=tasks',
                    },
                  ],
                },
                dataColumns: {
                  opportunityStage: {},
                  opportunityCount: {},
                  percentage: 0,
                },
              },
            ],
            featureSwitch: 'showOpportunityFunnelReport',
          },
          {
            type: 'heading',
            value: 'Openings',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'openOpeningCountForPeriod',
                'unfilledOpeningCountForPeriod',
                'newOpeningCountForPeriod',
                'agDaysToFillForPeriod',
                'fillRateForPeriod',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'openingTimeSeries',
              methodName: 'createOpeningTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Opening trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                unfilledOpeningCount: {},
                filledOpeningCount: {},
                fillRate: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Applications',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'applicationsCreatedCount',
                'newUniqueApplicantCount',
                'applicationAvgDaysToDecisionForPeriod',
                'extendedOfferCount',
                'acceptedApplicationCountForPeriod',
                'acceptedUniqueApplicantCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationEventTimeSeries',
              methodName: 'createApplicationEventTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Application activity trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                applicationsCreatedCount: {},
                offerExtendedCount: {},
                applicationAcceptedCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationSnapshotTimeSeries',
              methodName: 'createApplicationSnapshotWeeklyTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Total application & acceptance trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                totalApplicationCount: {},
                totalAcceptedApplicationCount: {},
                acceptanceRate: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
        ],
      },
      programOpportunityManagement: {
        queryNames: [
          'opportunityManagement',
          'opportunityManagementTimeSeries',
          'opportunityCountByState',
          'opportunityCountByType',
        ],
        fields: [
          {
            type: 'heading',
            value: 'Opportunity availability',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'opportunitiesSubmittedCount',
                'opportunitiesOpenedCount',
                'opportunitiesClosedCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityTimeSeries',
              methodName: 'createOpportunityTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Opportunity trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                opportunitiesSubmittedCount: {},
                opportunitiesOpenedCount: {},
                opportunitiesClosedCount: {},
                opportunitiesOpenCount: {},
              },
            }],
          },
          {
            type: 'heading',
            value: 'State of all opportunities',
          },
          {
            type: 'description',
            value: 'Tracks the current state of opportunities submitted during the selected time period',
          },
          {
            type: 'chart',
            value: [
              {
                id: 'opportunityByType',
                methodName: 'createOpportunityByType',
                dataSource: 'opportunityCountByType',
                title: 'Opportunity types',
                columnSize: 5,
                dataColumns: {
                  type: {},
                  opportunityCount: {},
                },
              },
              {
                id: 'opportunityByState',
                methodName: 'createOpportunityByState',
                dataSource: 'opportunityCountByState',
                title: 'Opportunity pipeline',
                columnSize: 7,
                dataColumns: {
                  state: {},
                  opportunityCount: {},
                },
              },
            ],
          },
          {
            type: 'heading',
            value: 'Openings',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'openOpeningCountForPeriod',
                'unfilledOpeningCountForPeriod',
                'newOpeningCountForPeriod',
                'agDaysToFillForPeriod',
                'fillRateForPeriod',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'openingTimeSeries',
              methodName: 'createOpeningTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Opening trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                unfilledOpeningCount: {},
                filledOpeningCount: {},
                fillRate: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
          {
            type: 'heading',
            value: 'Applications',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'opportunityManagement',
              value: [
                'applicationsCreatedCount',
                'newUniqueApplicantCount',
                'applicationAvgDaysToDecisionForPeriod',
                'extendedOfferCount',
                'acceptedApplicationCountForPeriod',
                'acceptedUniqueApplicantCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationEventTimeSeries',
              methodName: 'createApplicationEventTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Application activity trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                applicationsCreatedCount: {},
                offerExtendedCount: {},
                applicationAcceptedCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationSnapshotTimeSeries',
              methodName: 'createApplicationSnapshotWeeklyTimeSeries',
              dataSource: 'opportunityManagementTimeSeries',
              filters: ['interval'],
              title: 'Total application & acceptance trend',
              dataColumns: {
                firstDay: {},
                lastDay: {},
                totalApplicationCount: {},
                totalAcceptedApplicationCount: {},
                acceptanceRate: { formatOptions: { maximumFractionDigits: 1 } },
              },
            }],
          },
        ],
      },
      managementByOrgGroup: {
        queryNames: [
          'opportunityCountByGroup',
          'openingCountByGroup',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'opportunities',
              methodName: 'createOpportunitiesByOrgGroup',
              dataSource: 'opportunityCountByGroup',
              title: 'Opportunities by org group',
              dataColumns: {
                groupName: {},
                submittedOpportunityCount: {},
                openOpportunityCount: {},
                pausedOpportunityCount: {},
                closedOpportunityCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'openings',
              methodName: 'createOpeningsByOrgGroup',
              dataSource: 'openingCountByGroup',
              title: 'Openings by org group',
              dataColumns: {
                groupName: {},
                openOpeningCount: {},
                offerExtendedOpeningCount: {},
                filledOpeningCount: {},
              },
            }],
          },
        ],
      },
      companyUserEngagementBySegment: {
        queryNames: [
          'userEngagementBySegment',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'onboardedUsersBySegment',
              methodName: 'createOnboardeUsersBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Users newly onboarded by segment',
              dataColumns: {
                segmentName: {},
                userOnboardedCount: {},
                onboardedUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
              },
              toggles: [
                { icon: 'numbers', value: 'numbers' },
                { icon: 'percent', value: 'percent' },
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'activeUsersBySegment',
              methodName: 'createActiveUsersBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Active users by segment',
              dataColumns: {
                segmentName: {},
                activeUserCount: {},
                activeUserRatio: { formatOptions: { style: 'percent', maximumFractionDigits: 1 } },
              },
              toggles: [
                { icon: 'numbers', value: 'numbers' },
                { icon: 'percent', value: 'percent' },
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'opportunityViewBySegment',
              methodName: 'createOpportunityViewBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Opportunity views by segment',
              dataColumns: {
                segmentName: {},
                opportunityView: {},
                opportunityViewerCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'BookmarksBySegment',
              methodName: 'createBookmarksBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Bookmarks by segment',
              dataColumns: {
                segmentName: {},
                bookmarkCount: {},
                bookmarkUserCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'interestsBySegment',
              methodName: 'createInterestsBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Interests by segment',
              dataColumns: {
                segmentName: {},
                interestCount: {},
                interestUserCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationsBySegment',
              methodName: 'createApplicationsBySegment',
              dataSource: 'userEngagementBySegment',
              title: 'Applications by segment',
              dataColumns: {
                segmentName: {},
                applicationCount: {},
                applicantCount: {},
              },
            }],
          },
        ],
      },
      mobility: {
        queryNames: [
          'applicationsByLocation',
          'acceptancesByLocation',
          'applicationsByOrgGroup',
          'acceptancesByOrgGroup',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'applicationsByGroup',
              methodName: 'createApplicationsByGroup',
              dataSource: 'applicationsByOrgGroup',
              title: 'Applications by org group',
              height: 800,
              dataColumns: {
                applicantGroupName: {},
                opportunityGroupName: {},
                applicationCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'acceptancesByGroup',
              methodName: 'createAcceptancesByGroup',
              dataSource: 'acceptancesByOrgGroup',
              title: 'Acceptances by org group',
              height: 800,
              dataColumns: {
                applicantGroupName: {},
                opportunityGroupName: {},
                acceptanceCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'applicationsByLocation',
              methodName: 'createApplicationsByLocation',
              dataSource: 'applicationsByLocation',
              title: 'Applications by location',
              height: 800,
              dataColumns: {
                applicantLocationName: {},
                hiringManagerLocationName: {},
                applicationCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'acceptancesByLocation',
              methodName: 'createAcceptancesByLocation',
              dataSource: 'acceptancesByLocation',
              title: 'Acceptances by location',
              height: 800,
              dataColumns: {
                applicantLocationName: {},
                hiringManagerLocationName: {},
                acceptanceCount: {},
              },
            }],
          },
        ],
      },
      mobilityFromJARole: {
        queryNames: [
          'jaRoleMatches',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'mobilityFromJARole',
              methodName: 'createAvailableTransitionsByJARole',
              dataSource: 'jaRoleMatches',
              title: 'Potential Mobility to Open Jobs',
              dataColumns: {
                fromJaRoleId: { overrideText: 'From Role ID' },
                fromJaRoleName: { overrideText: 'From Role Name' },
                currentEmployeeCount: {},
                toJaRoleId: { overrideText: 'To Role ID' },
                toJaRoleName: { overrideText: 'To Role Name' },
                currentJobOpenings: {},
              },
            }],
          },
        ],
      },
      supplyDemandByDomain: {
        queryNames: [
          'supplyDemandDomainView',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'domainSupplyDemand',
              methodName: 'createSupplyDemandByDomain',
              dataSource: 'supplyDemandDomainView',
              dataFunction: (chartData) => chartData
                .filter((cd) => cd.currentEmployeeCount !== 0 || cd.currentJobOpenings !== 0),
              title: 'Supply vs demand by {{jobFamilyGroup}}',
              description: 'This chart shows your talent demand and internal talent supply by {{jobFamilyGroup}}, highlighting opportunities for internal mobility. Click on explore to view supply vs demand for roles within the {{jobFamilyGroup}}.',
              dataColumns: {
                toDomainName: { overrideText: 'job family group' },
                currentEmployeeCount: { overrideText: 'current employees' },
                currentJobOpenings: { overrideText: 'open jobs' },
                predictedJobOpenings: { overrideText: 'predicted open jobs' },
                potentialInternalTalentCount: { overrideText: 'employee matches' },
              },
            }],
          },
        ],
      },
      supplyDemandByRole: {
        queryNames: [
          'supplyDemandDomainView',
          'supplyDemandRoleView',
        ],
        fields: [
          {
            type: 'link',
            path: '/operations/insights?t=supplyDemand',
            icon: 'chevron_left',
            text: 'Overall breakdown',
            methodName: 'navigateToPathWithParams',
            excludedParams: ['dm'],
          },
          {
            type: 'heading',
            value: 'Overall stats for selection',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'supplyDemandDomainView',
              value: [
                'currentEmployeeCount',
                'currentJobOpenings',
                'predictedJobOpenings',
                'potentialInternalTalentCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'roleSupplyDemand',
              methodName: 'createSupplyDemandByRole',
              dataSource: 'supplyDemandRoleView',
              dataFunction: (chartData) => chartData
                .filter((cd) => cd.currentEmployeeCount !== 0 || cd.currentJobOpenings !== 0),
              title: 'Supply vs demand by {{jobFamily}}',
              description: 'This chart shows your talent demand and internal talent supply by role within the selected {{jobFamilyGroup}}, highlighting opportunities for internal mobility',
              dataColumns: {
                toRoleName: { overrideText: 'job family' },
                currentEmployeeCount: { overrideText: 'current employees' },
                currentJobOpenings: { overrideText: 'open jobs' },
                predictedJobOpenings: { overrideText: 'predicted open jobs' },
                potentialInternalTalentCount: { overrideText: 'employee matches' },
              },
            }],
          },
        ],
      },
      supplyDemandByJobFamilyGroup: {
        queryNames: [
          'supplyDemandDomainViewJARollup',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'supplyDemandJobFamilyGroup',
              methodName: 'createSupplyDemandByJobFamilyGroup',
              dataSource: 'supplyDemandDomainViewJARollup',
              dataFunction: (chartData) => chartData
                .filter((cd) => cd.currentEmployeeCount !== 0 || cd.currentJobOpenings !== 0),
              title: 'Supply vs demand by {{jobFamilyGroup}}',
              description: 'This chart shows your talent demand and internal talent supply by {{jobFamilyGroup}}, highlighting opportunities for internal mobility. Click on explore to view supply vs demand for job families within the {{jobFamilyGroup}}.',
              dataColumns: {
                toDomainName: { overrideText: 'job family group' },
                currentEmployeeCount: { overrideText: 'current employees' },
                currentJobOpenings: { overrideText: 'open jobs' },
                predictedJobOpenings: { overrideText: 'predicted open jobs' },
                potentialInternalTalentCount: { overrideText: 'employee matches' },
                potentialExternalTalentCount: { overrideText: 'external matches' },
              },
            }],
          },
        ],
      },
      supplyDemandByJobFamily: {
        queryNames: [
          'supplyDemandDomainViewJARollup',
          'supplyDemandRoleViewJARollup',
        ],
        fields: [
          {
            type: 'link',
            path: '/operations/insights?t=supplyDemand',
            icon: 'chevron_left',
            text: 'Job family group breakdown',
            methodName: 'navigateToPathWithParams',
            excludedParams: ['dm'],
          },
          {
            type: 'heading',
            value: 'Overall stats for selection',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'supplyDemandDomainViewJARollup',
              value: [
                'currentEmployeeCount',
                'currentJobOpenings',
                'predictedJobOpenings',
                'potentialInternalTalentCount',
                'potentialExternalTalentCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'supplyDemandJobFamily',
              methodName: 'createSupplyDemandByJobFamily',
              dataSource: 'supplyDemandRoleViewJARollup',
              dataFunction: (chartData) => chartData
                .filter((cd) => cd.currentEmployeeCount !== 0 || cd.currentJobOpenings !== 0),
              title: 'Supply vs demand by {{jobFamily}}',
              description: 'This chart shows your talent demand and internal talent supply by job family within the selected {{jobFamilyGroup}}, highlighting opportunities for internal mobility.',
              dataColumns: {
                toRoleName: { overrideText: 'job family' },
                currentEmployeeCount: { overrideText: 'current employees' },
                currentJobOpenings: { overrideText: 'open jobs' },
                predictedJobOpenings: { overrideText: 'predicted open jobs' },
                potentialInternalTalentCount: { overrideText: 'employee matches' },
                potentialExternalTalentCount: { overrideText: 'external matches' },
              },
            }],
          },
        ],
      },
      supplyDemandByJARole: {
        queryNames: [
          'supplyDemandRoleViewJARollup',
          'supplyDemandJARoleViewJARollup',
        ],
        fields: [
          {
            type: 'link',
            path: '/operations/insights?t=supplyDemand&rpt=supplyDemandByJobFamily',
            icon: 'chevron_left',
            text: 'Job family breakdown',
            methodName: 'navigateToPathWithParams',
            excludedParams: ['r'],
          },
          {
            type: 'heading',
            value: 'Overall stats for selection',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'supplyDemandRoleViewJARollup',
              value: [
                'currentEmployeeCount',
                'currentJobOpenings',
                'predictedJobOpenings',
                'potentialInternalTalentCount',
                'potentialExternalTalentCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'supplyDemandJARole',
              methodName: 'createSupplyDemandByJARole',
              dataSource: 'supplyDemandJARoleViewJARollup',
              dataFunction: (chartData) => chartData
                .filter((cd) => cd.currentEmployeeCount !== 0 || cd.currentJobOpenings !== 0),
              title: 'Supply vs demand by {{jobArchitectureRole}}',
              description: 'This chart shows your talent demand and internal talent supply by role within the selected {{jobFamily}}, highlighting opportunities for internal mobility.',
              dataColumns: {
                toJaRoleName: { overrideText: 'role' },
                currentEmployeeCount: { overrideText: 'current employees' },
                currentJobOpenings: { overrideText: 'open jobs' },
                predictedJobOpenings: { overrideText: 'predicted open jobs' },
                potentialInternalTalentCount: { overrideText: 'employee matches' },
                potentialExternalTalentCount: { overrideText: 'external matches' },
              },
            }],
          },
        ],
      },
      opsDashboardTeamSkillsMetrics: {
        queryNames: [
          'totalUniqueSkillUserCount',
        ],
        // queryParams: {
        //   // managerUserId passed as prop in OperationsDashboard.
        // },
        fields: [
          {
            type: 'heading',
            value: 'Your team\'s skills',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'totalUniqueSkillUserCount',
              value: [
                'totalSkills',
              ],
              dataSourceSubMetrics: 'totalUniqueSkillUserCount',
              subMetricValue: [
                'totalUsers',
              ],
            }],
          },
        ],
      },
      opsDashboardTeamSkillsCharts: {
        queryNames: [
          'topSkillsAmongUsers',
        ],
        // queryParams: {
        //   // managerUserId passed as prop in OperationsDashboard.
        // },
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'userSkillsDistribution',
              methodName: 'createUserSkillsDistribution',
              dataSource: 'topSkillsAmongUsers',
              title: 'Skills distribution',
              description: 'Top skills held by employees in your reporting chain (max: 100 skills)',
              dataColumns: {
                skillName: {},
                userCount: { overrideText: 'EMPLOYEE COUNT' },
              },
              options: {
                shouldUseEmployeeLanguage: true,
              },
            }],
          },
        ],
      },
      workforceCompositionJobFamilyLegacy: {
        queryNames: [
          'userCount',
          'topRolesAmongUsers',
          'topSkillsAmongUsers',
        ],
        fields: [
          {
            type: 'heading',
            value: 'Users',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'userCount',
              value: [
                'userCount',
                'onboardedUserCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'userRoleDistribution',
              methodName: 'createUserRoleDistribution',
              dataSource: 'topRolesAmongUsers',
              title: '{{JobFamily}} distribution',
              dataColumns: {
                roleName: {},
                userCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'userSkillsDistribution',
              methodName: 'createUserSkillsDistribution',
              dataSource: 'topSkillsAmongUsers',
              title: 'Skills distribution',
              description: 'Top skills held by users (max: 100 skills)',
              dataColumns: {
                skillName: {},
                userCount: {},
              },
            }],
          },
        ],
      },
      workforceCompositionJobFamilyGroup: {
        queryNames: [
          'userCount',
          'topDomainsAmongUsersJARollup',
          'topSingleJASkillAmongUsers',
          'topJASkillsAmongUsers',
        ],
        fields: [
          {
            type: 'heading',
            value: 'Employees',
          },
          {
            type: 'metrics',
            sections: [
              {
                dataSource: 'userCount',
                value: [
                  'userCount',
                  'onboardedUserCount',
                ],
              },
              {
                dataSource: 'topSingleJASkillAmongUsers',
                value: [
                  'skillName',
                ],
                dataSourceSubMetrics: 'topSingleJASkillAmongUsers',
                subMetricValue: [
                  'usersWithSkillRatio',
                ],
              },
            ],
          },
          {
            type: 'chart',
            value: [{
              id: 'userJobFamilyGroupDistribution',
              methodName: 'createJobFamilyGroupDistribution',
              dataSource: 'topDomainsAmongUsersJARollup',
              title: 'Employee distribution by {{jobFamilyGroup}}',
              dataColumns: {
                domainName: { overrideText: 'job family group' },
                userCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'userSkillsDistribution',
              methodName: 'createUserSkillsDistribution',
              dataSource: 'topJASkillsAmongUsers',
              title: 'Skills distribution',
              description: 'Top skills held by employees (max: 100 skills)',
              dataColumns: {
                skillName: {},
                userCount: {},
              },
            }],
          },
        ],
      },
      workforceCompositionJobFamily: {
        queryNames: [
          'userCount',
          'topRolesAmongUsersJARollup',
          'topSingleJASkillAmongUsers',
          'topJASkillsAmongUsers',
        ],
        fields: [
          {
            type: 'link',
            path: '/operations/insights?t=population',
            icon: 'chevron_left',
            text: 'Workforce composition by job family group',
            methodName: 'navigateToPathWithParams',
            excludedParams: ['dm'],
          },
          {
            type: 'heading',
            value: 'Employees',
          },
          {
            type: 'metrics',
            sections: [
              {
                dataSource: 'userCount',
                value: [
                  'userCount',
                  'onboardedUserCount',
                ],
              },
              {
                dataSource: 'topSingleJASkillAmongUsers',
                value: [
                  'skillName',
                ],
                dataSourceSubMetrics: 'topSingleJASkillAmongUsers',
                subMetricValue: [
                  'usersWithSkillRatio',
                ],
              },
            ],
          },
          {
            type: 'chart',
            value: [{
              id: 'userJobFamilyDistribution',
              methodName: 'createJobFamilyDistribution',
              dataSource: 'topRolesAmongUsersJARollup',
              title: 'Employee distribution by {{jobFamily}}',
              dataColumns: {
                roleName: { overrideText: 'job family' },
                userCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'userSkillsDistribution',
              methodName: 'createUserSkillsDistribution',
              dataSource: 'topJASkillsAmongUsers',
              title: 'Skills distribution',
              description: 'Top skills held by employees (max: 100 skills)',
              dataColumns: {
                skillName: {},
                userCount: {},
              },
            }],
          },
        ],
      },
      workforceCompositionJobArchitectureRole: {
        queryNames: [
          'userCount',
          'topJARolesAmongUsersJARollup',
          'topSingleJASkillAmongUsers',
          'topJASkillsAmongUsers',
        ],
        fields: [
          {
            type: 'link',
            path: '/operations/insights?t=population&rpt=workforceCompositionJobFamily',
            icon: 'chevron_left',
            text: 'Workforce composition by job family',
            methodName: 'navigateToPathWithParams',
            excludedParams: ['r'],
          },
          {
            type: 'heading',
            value: 'Employees',
          },
          {
            type: 'metrics',
            sections: [
              {
                dataSource: 'userCount',
                value: [
                  'userCount',
                  'onboardedUserCount',
                ],
              },
              {
                dataSource: 'topSingleJASkillAmongUsers',
                value: [
                  'skillName',
                ],
                dataSourceSubMetrics: 'topSingleJASkillAmongUsers',
                subMetricValue: [
                  'usersWithSkillRatio',
                ],
              },
            ],
          },
          {
            type: 'chart',
            value: [{
              id: 'userJARoleDistribution',
              methodName: 'createJARoleDistribution',
              dataSource: 'topJARolesAmongUsersJARollup',
              title: 'Employee distribution by {{jobArchitectureRole}}',
              dataColumns: {
                jaRoleName: { overrideText: 'role' },
                userCount: {},
              },
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'userSkillsDistribution',
              methodName: 'createUserSkillsDistribution',
              dataSource: 'topJASkillsAmongUsers',
              title: 'Skills distribution',
              description: 'Top skills held by employees (max: 100 skills)',
              dataColumns: {
                skillName: {},
                userCount: {},
              },
            }],
          },
        ],
      },
      careerSentiment: {
        queryNames: [
          'sentimentSurveyCount',
          'currentSentiment',
          'sentimentTimeSeries',
        ],
        fields: [
          {
            type: 'heading',
            value: 'Users who completed career sentiment surveys',
          },
          {
            type: 'metrics',
            sections: [{
              dataSource: 'sentimentSurveyCount',
              value: [
                'onboardingSurveyUserCount',
                'ongoingSurveyUserCount',
                'postOpportunitySurveyUserCount',
              ],
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'currentSentimentAcrossSurveys',
              methodName: 'createCurrentSentimentAcrossSurveys',
              dataSource: 'currentSentiment',
              dataFunction: (chartData) => chartData
                .sort((a, b) => careerSentimentSurveyKeyConfig.value.findIndex((r) => a.questionName === r.questionName)
                  - careerSentimentSurveyKeyConfig.value.findIndex((r) => b.questionName === r.questionName)),
              title: 'Current career sentiment across surveys',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'jobMasteryTimeSeries',
              methodName: 'createCareerSatisfactionTimeSeries',
              dataSource: 'sentimentTimeSeries',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'job mastery'),
              filters: ['interval'],
              title: 'Job mastery over time',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'jobSatisfactionTimeSeries',
              methodName: 'createCareerSatisfactionTimeSeries',
              dataSource: 'sentimentTimeSeries',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'job satisfaction'),
              filters: ['interval'],
              title: 'Job satisfaction over time',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerPathTimeSeries',
              methodName: 'createCareerSatisfactionTimeSeries',
              dataSource: 'sentimentTimeSeries',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career path'),
              filters: ['interval'],
              title: 'Career path over time',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerNavigationTimeSeries',
              methodName: 'createCareerSatisfactionTimeSeries',
              dataSource: 'sentimentTimeSeries',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career navigation'),
              filters: ['interval'],
              title: 'Career navigation over time',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerOptionsTimeSeries',
              methodName: 'createCareerSatisfactionTimeSeries',
              dataSource: 'sentimentTimeSeries',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career options'),
              filters: ['interval'],
              title: 'Career options over time',
            }],
          },
        ],
      },
      careerSentimentBySegment: {
        queryNames: [
          'sentimentBySegment',
        ],
        fields: [
          {
            type: 'chart',
            value: [{
              id: 'jobMasteryBySegment',
              methodName: 'createCareerSatisfactionBySegment',
              dataSource: 'sentimentBySegment',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'job mastery'),
              filters: ['interval'],
              title: 'Job mastery by segment',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'jobSatisfactionBySegment',
              methodName: 'createCareerSatisfactionBySegment',
              dataSource: 'sentimentBySegment',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'job satisfaction'),
              filters: ['interval'],
              title: 'Job satisfaction by segment',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerPathBySegment',
              methodName: 'createCareerSatisfactionBySegment',
              dataSource: 'sentimentBySegment',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career path'),
              filters: ['interval'],
              title: 'Career path by segment',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerNavigationBySegment',
              methodName: 'createCareerSatisfactionBySegment',
              dataSource: 'sentimentBySegment',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career navigation'),
              filters: ['interval'],
              title: 'Career navigation by segment',
            }],
          },
          {
            type: 'chart',
            value: [{
              id: 'careerOptionsBySegment',
              methodName: 'createCareerSatisfactionBySegment',
              dataSource: 'sentimentBySegment',
              dataFunction: (chartData) => chartData.filter((cd) => cd.surveyQuestionName === 'career options'),
              filters: ['interval'],
              title: 'Career options by segment',
            }],
          },
        ],
      },
    },
  },
  options: {
    numWeekdays: [1, 2, 3, 4, 5],
    numWeeks: [2, 4, 6, 8],
  },
  dateFormat: 'MMM dd, yyyy',
  datePickerFormat: 'yyyy-MM-dd',
  dateFormatMonthYearOnly: 'yyyy-MM',
  dateTimeFormat: 'MMM dd, yyyy t ZZZZ',
  dateFormatAbbrMonthYearOnly: 'LLL yyyy',
  dateFormatSlash: 'MM/dd/yyyy',
  applicationDateLabel: "MMM d 'at' t ZZZZ",
  interests: {
    maxTopResults: 12,
    roles: {
      secondaryActions: [{
        display: 'Go to {{jobFamily}} details',
        eventType: 'view-role',
      }],
    },
    types: {
      role: {
        displayName: '{{jobFamily}}',
        displayNamePlural: '{{jobFamily}}',
      },
      skill: {
        displayName: 'Skill',
        displayNamePlural: 'Skills',
      },
      group: {
        displayName: 'Org group',
        displayNamePlural: 'Organization groups',
      },
      location: {
        displayName: 'Location',
        displayNamePlural: 'Locations',
      },
      work_content: {
        displayName: 'Way of working',
        displayNamePlural: 'Ways of working',
      },
      open_to_remote: {
        displayName: 'Location',
        displayNamePlural: 'Locations',
      },
      requisition_type: {
        displayName: 'Type of work',
        displayNamePlural: 'Types of work',
      },
    },
  },
  matchedRequisitionDismissal: {
    snackbarConfirmMessage: 'Your feedback is collected to improve our recommendations.',
    snackbarDoNotAskMessage: 'You will no longer be prompted to provide feedback when hiding recommendations.',
    modalTitle: 'OK! This opportunity will not be recommended again',
    modalPreSubtitle: 'Optional feedback:',
    modalSubtitle: 'Why did this opportunity not feel relevant? (Select all that apply)',
    dismissForm: {
      interests: "Doesn't align with my interests",
      skills: "Won't help me improve the skills I care about",
      level: 'Not an appropriate level for me',
      role: 'Not relevant to my current or desired role',
    },
    modalPrimaryActionText: 'Submit feedback',
    modalSecondaryActionText: 'Do not ask me again',
  },
  dashboards: {
    operations: {
      taskIcon: 'grading',
      color: 'primary',
      emptyTasksMsg: 'No tasks right now',
      emptyDashboardsMsg: 'Insights on opportunities you manage will be shown here',
      openOpportunitiesDashboardHeader: 'My open opportunities',
      submittedOpportunitiesDashboardHeader: 'My submitted opportunities',
      employeeEngagementDashboardHeader: 'Employee engagement',
      opportunityMgmtDashboardHeader: 'Business demand',
    },
    talent: {
      banner: {
        latestOpportunitiesBanner: 'This is where we recommend opportunities that are a good fit for you based on your career journey and interests. You do not have any recommendations right now, but seeing the latest opportunities at your organization is still valuable.',
        noOpportunitiesBanner: 'As opportunities are posted on the platform, they will start appearing here! In the meantime, you can add interests for better recommendations.',
        suggestedOpportunitiesBanner: 'These opportunities have either been suggested by your coworkers or recommended to you based on your career journey and interests.',
        recommendationOpportunitiesBanner: 'Expand or refine your recommendations by managing your interests. We use this information to connect you with relevant opportunities.',
        noRecommendationOpportunitiesBanner: 'You do not have any recommended opportunities at this time. Consider expanding your match criteria by managing your interests. If you are ineligible to apply for new roles as per your company’s eligibility policy, you can view the latest opportunities at your organization. It’s never too early to start planning for what comes next! ',
        noPeerRecommendationOpportunityBanner: 'You do not have any peer-recommended opportunities at this time.',
      },
    },
  },
  icons: {
    isInterestIcon: 'favorite',
    isNotInterestIcon: 'favorite_border',
    isAddedToProfileIcon: 'cancel',
    isNotAddedToProfileIcon: 'add',
    isUserRecommendedOpportunityIcon: 'person',
  },
  remoteVirtualLocationId: -1,
  noInformationText: '(No information)',
  groups: {
    types: {
      organization: {
        displayNames: {
          singular: 'org group',
          plural: 'org groups',
        },
      },
      cost_center: {
        displayNames: {
          singular: 'cost center',
          plural: 'cost centers',
        },
      },
      job_family: {
        displayNames: {
          singular: 'job family',
          plural: 'job families',
        },
      },
    },
  },
  ingestionWarning: 'ATS or HRIS updates may overwrite this setting',
  richTextEditorDefaultValue: '<p></p>',
  skills: {
    userSuggestion: {
      maxSkillSuggestions: 9,
      label: 'Suggestions:',
      roleBasedLabel: 'Skills you may have based on your current role:',
    },
  },
  profileDownloadEmail: {
    submittedMessage: (email) => `Request submitted. If we find a matching record, we will send a notification to ${email}.`,
    infoText: 'Enter an email address to download your profile',
    infoLabel: 'Email address',
    btnText: 'Request profile download',
    inputRules: {
      REQUIRED: 'Email is required',
      INVALID: 'Email must be valid',
    },
  },
});

// TASK TYPES
export const TASK_TYPES = {
  APPLICATION_APPROVAL_REQUIRED: 'applicationApprovalRequired',
  APPLICATION_COMPLETION_CONFIRMATION: 'applicationCompletionConfirmation',
  APPLICATION_COMPLETION_FEEDBACK: 'applicationCompletionFeedback',
  EXTERNAL_APPLICATION_COMPLETION_FEEDBACK: 'externalApplicationCompletionFeedback',
  APPLICATION_COMPLETION_REMINDER: 'applicationCompletionReminder',
  APPLICATION_IN_BREACH_OF_SLA: 'applicationInBreachOfSla',
  APPLICATION_OFFER_EXTENDED: 'applicationOfferExtended',
  NOT_YET_COMPLETED_SURVEY: 'notYetCompletedSurvey',
  EXPERIENCE_PREDICTED_ROLE_UNCONFIRMED: 'experiencePredictedRoleUnconfirmed',
  REQUISITION_OPENINGS_FILLED: 'requisitionOpeningsFilled',
  REQUISITION_APPROVAL_REQUIRED: 'requisitionApprovalRequired',
  REQUISITION_OPENING_REMINDER: 'requisitionOpeningReminder',
  TALENT_CHECKIN_SURVEY: 'talentCheckinSurvey',
};

// OPEN REQUISITION TYPES
export const OPEN_REQUISITION_MODAL_TYPES = {
  NONE: 'openRequisitionNone',
  ACKNOWLEDGEMENTS: 'openRequisitionAcknowledgements',
  ALLOWED: 'openAllowed',
  NOT_ALLOWED: 'openNotAllowed',
};

export const ApplicationDisqualifiedFeedback = {
  message: {
    full: "Unfortunately you weren't selected for this opportunity. Here is the feedback provided by the hiring manager.",
    short: "Unfortunately you weren't selected for this opportunity.",
    noFeedback: "Unfortunately you weren't selected for this opportunity. Please reach out to the hiring manager for feedback.",
  },
};

// Given an icon type name, return an object with detailed info on the associated icon.
export const iconMap = {
  info: {
    icon: 'info',
    color: 'secondary',
  },
  offerExtended: {
    icon: 'feedback',
    color: 'success',
  },
  accepted: {
    icon: 'check_circle_outline',
    color: 'success',
  },
  applied: {
    icon: 'pan_tool',
    color: 'primary',
  },
  withdrew: {
    icon: 'notification_important',
    color: 'error',
  },
  recommendation: {
    icon: 'person_outline',
    color: 'primary',
  },
  program: {
    icon: 'ballot',
    color: 'secondary',
  },
  applicationApproved: {
    icon: 'check_circle_outline',
    color: 'primary',
  },
  applicationRejected: {
    icon: 'remove_circle_outline',
    color: 'secondary2',
  },
  profile: {
    icon: 'person_outline',
    color: 'primary',
  },
};

export const footerLinks = [
  { title: `${new Date().getFullYear()} Beamery, Inc`, path: 'https://beamery.com/', showSymbol: true },
  { title: 'Privacy Policy', path: privacyPolicyLink },
  { title: 'Subject Agreement', path: subjectAgreementLink },
];

export const labelAlias = {
  jobFamily: {
    sentenceCase: {
      singular: 'Job family',
      plural: 'Job families',
    },
    lowerCase: {
      singular: 'job family',
      plural: 'job families',
    },
  },
  jobFamilyGroup: {
    sentenceCase: {
      singular: 'Job family group',
      plural: 'Job family groups',
    },
    lowerCase: {
      singular: 'job family group',
      plural: 'job family groups',
    },
  },
  jobArchitectureRole: {
    sentenceCase: {
      singular: 'Role',
      plural: 'Roles',
    },
    lowerCase: {
      singular: 'role',
      plural: 'roles',
    },
  },
};

export const jobArchitecture = {
  associatedRoles: {
    tooltip: 'Beamery has automatically mapped similar roles based on skill similarity',
  },
};
