<script>
import { storeToRefs } from 'pinia';
import { useUserStore, useAuthStore } from '@/store';
import Avatar from '../elements/Avatar.vue';
import Tooltip from '../elements/Tooltip.vue';
import Btn from '../elements/Button.vue';

export default {
  name: 'TheTopNavigationAccountMenu',
  components: {
    Avatar,
    Tooltip,
    Btn,
  },
  props: {
    profileDrawerItems: { required: true, type: Array },
  },
  setup() {
    const {
      currentView,
      user,
    } = storeToRefs(useUserStore());

    const {
      isAuthenticated,
    } = storeToRefs(useAuthStore());

    return {
      currentView,
      user,
      isAuthenticated,
    };
  },
  computed: {
    usersName() {
      if (this.user && this.user.name) {
        return this.user.name;
      }
      return null;
    },
    usersInitial() {
      if (this.user && this.user.name) {
        return this.user.name.charAt(0);
      }
      return '';
    },
    view() {
      if (this.currentView === 'talent') return 'Employee view';
      return 'Operations view';
    },
  },
  methods: {
    handleMenuClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else if (item.method) {
        item.method();
      }
    },
  },
};
</script>

<template>
  <VMenu
    v-if="isAuthenticated"
    offsetY
  >
    <VList>
      <VListItem
        v-for="profileDrawerItem in profileDrawerItems"
        :key="profileDrawerItem.title"
        :to="profileDrawerItem.path"
        @click="handleMenuClick(profileDrawerItem)"
      >
        <VListItemContent
          :data-e2e="profileDrawerItem.dataE2E"
        >
          {{ profileDrawerItem.title }}
        </VListItemContent>
      </VListItem>
    </VList>
    <template
      #activator="{ on }"
    >
      <VHover>
        <!-- Regarding ripple="false" see notes on https://fluxwork.atlassian.net/browse/FX-3204 -->
        <Btn
          :class="`account-button ml-3 px-2 hidden-sm-and-down`"
          :ripple="false"
          height="85% !important"
          type="text"
          color="secondary4"
          data-e2e="accountMenu"
          data-ext="accountMenu"
          outlined
          v-on="on"
        >
          <span
            class="hidden-xs-only user-name"
            data-e2e="navigationHeader"
            :style="{
              width: '165px',
              display: 'flex',
              'justify-content': 'space-between',
              'align-items': 'center',
            }"
          >
            {{ view }}
            <Tooltip
              :text="usersName"
              :onCondition="usersName && !$vuetify.breakpoint.mobile"
              position="bottom"
            >
              <Avatar
                color="primary"
              >
                {{ usersInitial }}
              </Avatar>
            </Tooltip>
          </span>
        </Btn>
      </VHover>
    </template>
  </VMenu>
</template>

<style lang="scss" scoped>
  .user-name {
    font-size: 16px;
    font-family: $heading-font-family;
  }
  .account-button {
    margin:5px 16px 0 0;
    border-radius:6px !important;
  }
  .hover {
    border: 1px solid $secondary4;
    background: #F3F3F3;
    box-shadow: 0 4px 12px $secondary1;
  }
</style>
