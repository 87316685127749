import type { AxiosError } from 'axios';
import type { RetryConfig } from 'retry-axios';
import * as rax from 'retry-axios';

/**
 * Function copied from retry-axios source codes to determine if retry-axios will retry
 * Filed a feature request to retry-axios to expose this info
 * https://github.com/JustinBeckwith/retry-axios/blob/main/src/index.ts#L289
 *
 * Determine based on config if we should retry the request.
 * @param err The AxiosError passed to the interceptor.
 */
export function shouldRetryRequest(err: AxiosError) {
  const config: RetryConfig = rax.getConfig(err) || {};

  // If there's no config, or retries are disabled, return.
  if (!config || config.retry === 0) {
    return false;
  }

  // Check if this error has no response (ETIMEDOUT, ENOTFOUND, etc)
  if (!err.response && (config.currentRetryAttempt || 0) >= (config?.noResponseRetries ?? 2)) {
    return false;
  }

  // Only retry with configured HttpMethods.
  if (!err?.config?.method || (config.httpMethodsToRetry || ['GET', 'PUT', 'HEAD', 'OPTIONS', 'DELETE']).indexOf(err?.config?.method?.toUpperCase() ?? '') < 0) {
    return false;
  }

  // If this wasn't in the list of status codes where we want
  // to automatically retry, return.
  if (err.response && err.response.status) {
    let isInRange = false;
    for (const [min, max] of (config?.statusCodesToRetry || [[100, 199], [429, 429], [500, 599]])) {
      const { status } = err.response;
      if (status >= min && status <= max) {
        isInRange = true;
        break;
      }
    }
    if (!isInRange) {
      return false;
    }
  }

  // If we are out of retry attempts, return
  config.currentRetryAttempt = config.currentRetryAttempt || 3;
  if (config.currentRetryAttempt >= (config.retry || 3)) {
    return false;
  }

  return true;
}
