<template>
  <div v-if="debugEnabled">
    <div
      class="debug-bar text-center"
    >
      <h4 class="ml-4">
        Debugging mode enabled!!
      </h4>
      <div class="debug-bar__content">
        <Btn
          class="debug-bar__button--dismiss"
          @click="dismissDebugHandler"
        >
          Disable
        </Btn>
      </div>
    </div>
    <div
      id="debug-color"
      class="debug-color"
    >
      <VExpansionPanels accordion>
        <VExpansionPanel>
          <VExpansionPanelHeader>
            Colors
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <div style="overflow-y: scroll; max-height: 400px;">
              <VForm>
                <template v-for="color, name of primaryThemeRef">
                  <VTextField
                    :key="name"
                    v-model="primaryThemeRef[name]"
                    :label="name"
                    required
                  />
                </template>
              </VForm>
            </div>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useTheme } from '@/composables/useTheme';
import { storeToRefs } from 'pinia';
import {
  useGlobalStore,
} from '@/store';
import Btn from './Button.vue';

export default {
  name: 'DebugModePanel',
  components: { Btn },
  setup() {
    const { primaryTheme } = useTheme();

    const route = useRoute();

    const globalStore = useGlobalStore();
    const {
      debugModeEnabled,
    } = storeToRefs(globalStore);

    const {
      setDebugMode,
    } = globalStore;

    const primaryThemeRef = ref(primaryTheme.value);
    watch(primaryThemeRef, (v) => {
      primaryTheme.value = v;
    }, { deep: true });

    // debug mode enable watch
    watch(() => route.query, (v) => {
      // enabled debug mode once
      if (v.debug) {
        setDebugMode(true);
      }
    }, { deep: true });

    return {
      debugModeEnabled,
      setDebugMode,
      primaryThemeRef,
    };
  },
  computed: {
    debugEnabled: {
      get() { return this.debugModeEnabled; },
      set(value) { this.setDebugMode(value); },
    },
  },
  mounted() {
    this.setupExtensionListener();
  },
  methods: {
    setupExtensionListener() {
      document.addEventListener('enableDebug', () => {
        this.debugEnabled = true;
      });
    },
    dismissDebugHandler() {
      this.debugEnabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.debug-bar {
  height: 64px;
  background-color: rgba(255, 0, 0, 0.2);
  bottom: 0;
  left:80%;
  display: flex;
  /*justify-content: space-evenly;*/
  align-items: center;
  position: fixed;
  width: 20%;
  z-index: 10;

  &__button {
    &--dismiss {
      margin: 0 auto;
    }
  }

  &__content {
    width: 100%;
  }
}

.debug-color {
  z-index: 909999;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 300px;
  padding:12px;
  border-radius: 8px;

}
</style>
