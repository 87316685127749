/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rax from 'retry-axios';
import Axios from 'axios';
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import type { RetryConfig } from 'retry-axios';

/**
 * Default methods API client
 */
export interface IApiClient {
  get<T>(path: string, config?: AxiosRequestConfig): Promise<T>;
  post<R, T>(path: string, object: R, config?: AxiosRequestConfig): Promise<T>;
  patch<R, T>(path: string, object: R): Promise<T>;
  put<R, T>(path: string, object: R, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(path: string): Promise<T>;
  setToken(token: string): void;
  clearToken(): void;
}

interface ApiClientExtendOptions {
  withRetry?: boolean;
  requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig;
  requestInterceptorError?: (error: any) => any;
  responseInterceptor?: (response: AxiosResponse) => AxiosResponse;
  responseInterceptorError?: (error: any) => any;
}

// Extend axios configs with retry-axios
type ApiClientConfigs = { raxConfig?: RetryConfig; } & AxiosRequestConfig;

/**
 * @class ApiClient
 * ApiClient class is a warpper for axios.
 * It takes configures, and create a axios instance with custom functions.
 * Also it includes Typescript type definitions for the request/response data and methods.
 *
 * @example
 * const apiClient = new ApiClient([configs], [options]);
 */
export default class ApiClient implements IApiClient {
  private instance: AxiosInstance;

  constructor(
    configs: ApiClientConfigs,
    {
      withRetry = true,
      requestInterceptor = (config) => config,
      requestInterceptorError = (error) => Promise.reject(error),
      responseInterceptor = (response) => response,
      responseInterceptorError = (error) => Promise.reject(error),
    }: ApiClientExtendOptions,
  ) {
    this.instance = Axios.create(configs);

    if (withRetry) {
      rax.attach(this.instance);
    }

    this.initInterceptors({
      requestInterceptor,
      requestInterceptorError,
      responseInterceptor,
      responseInterceptorError,
    });
  }

  private initInterceptors({
    requestInterceptor,
    requestInterceptorError,
    responseInterceptor,
    responseInterceptorError,
  }) {
    this.instance.interceptors.request.use(
      requestInterceptor || ((c) => c),
      requestInterceptorError || ((e) => Promise.reject(e)),
    );

    this.instance.interceptors.response.use(
      responseInterceptor || ((c) => c),
      responseInterceptorError || ((e) => Promise.reject(e)),
    );
  }

  public setToken(token: string) {
    if (!token && token.length === 0) {
      throw new Error('Token is required');
    }
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  public clearToken() {
    this.instance.defaults.headers.common.Authorization = '';
  }

  async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.get<T>(path, config);
    return response.data;
  }

  async post<R, T>(path: string, payload: R, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.post<T>(path, payload, config);
    return response.data;
  }

  async patch<R, T>(path: string, payload: R): Promise<T> {
    const response = await this.instance.patch<T>(path, payload);
    return response.data;
  }

  async put<R, T>(path: string, payload: R, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.put<T>(path, payload, config);
    return response.data;
  }

  async delete<R>(path: string): Promise<R> {
    const response = await this.instance.delete<R>(path);
    return response.data;
  }
}
