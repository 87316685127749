<template>
  <div>
    <div
      v-if="isSurveyLoading"
      class="text-center"
    >
      <progress-circular
        class="text-center ma-12"
        indeterminate
      />
    </div>
    <!-- need to use v-show, not v-if here to allow survey to load, but not show -->
    <v-card
      v-show="!isSurveyLoading"
      v-if="surveyModel && (!isSurveyComplete || !displayCompletion)"
      :class="`py-6 ${cardClasses}`"
    >
      <v-row
        v-if="showProgress"
        class="px-6 pb-0"
        no-gutters
      >
        <v-col class="shrink text-no-wrap mr-4 step-text primary--text">
          {{ progressTextValue }}
        </v-col>
        <v-col class="grow mt-2">
          <progress-bar
            color="primary"
            rounded
            :value="progressBarValue"
          />
        </v-col>
      </v-row>
      <survey
        :survey="surveyModel"
        data-e2e="surveyQuestion"
      />
      <v-row
        v-if="showBackNextButtons"
        :class="`flex-nowrap px-6 pt-0 pb-0`"
        no-gutters
      >
        <slot name="additional-footer" />
        <v-spacer />
        <slot name="actions" />
        <btn
          v-show="surveyModel.currentPageNo !== 0"
          type="secondary"
          class="mx-1"
          @click="surveyModel.prevPage();"
        >
          Back
        </btn>
        <btn
          v-show="!surveyModel.isLastPage && displayNextButton"
          type="primary"
          class="mx-1"
          data-e2e="nextSurveyQuestion"
          @click="surveyModel.nextPage();"
        >
          Next
        </btn>
        <btn
          v-show="surveyModel.isLastPage"
          type="primary"
          class="mx-1"
          data-e2e="complete"
          @click="surveyModel.completeLastPage()"
        >
          {{ submissionButtonText }}
        </btn>
        <v-spacer />
      </v-row>
    </v-card>
    <v-card
      v-if="isSurveyComplete && displayCompletion"
      :class="cardClasses"
    >
      <v-row
        class="pa-12"
        justify="center"
        align="center"
      >
        <loading />
      </v-row>
    </v-card>
  </div>
</template>
<script>
import * as SurveyVue from 'survey-vue';
import * as widgets from 'surveyjs-widgets';
import { isEmpty, isNil, forEach } from 'lodash-es';
import goTo from 'vuetify/lib/services/goto';
import axios from 'axios';
import { mapStores, storeToRefs } from 'pinia';
import {
  useSurveysStore,
  useUserStore,
  useCompanyStore,
  useAnalyticsStore,
  useGlobalStore,
} from '@/store';
import surveyClassOverrides from './surveyClassOverrides';
import globals from '../../globals';
import Loading from '../elements/Loading.vue';
import ProgressBar from '../elements/ProgressBar.vue';
import ProgressCircular from '../elements/ProgressCircular.vue';
import Btn from '../elements/Button.vue';
// Using Object Destructuring
// https://github.com/airbnb/javascript#destructuring--object-over-array
const { Survey } = SurveyVue;
widgets.sortablejs(SurveyVue);

function getDefaultState() {
  return {
    survey: {}, // Survey object returned from API
    surveyModel: undefined, // SurveyVue.Model instance
    isSurveyComplete: false,
    runId: null,
    surveyResult: {},
    displayNextButton: true,
    isSurveyLoading: true,
    currentPageNo: 1,
  };
}

export default {
  name: 'SurveyUnit',
  components: {
    Survey,
    Loading,
    ProgressBar,
    ProgressCircular,
    Btn,
  },
  props: {
    surveyId: { type: Number, required: false, default: null },
    surveyName: { type: String, required: false, default: null },
    surveyVariables: { type: Object, required: false, default: () => ({}) },
    minimal: { type: Boolean, required: false, default: false },
    showPageIndicator: { type: Boolean, required: false, default: true },
    showBackNextButtons: { type: Boolean, required: false, default: true },
    submissionButtonText: { type: String, required: false, default: 'Submit' },
    context: { type: Object, required: false, default: () => ({}) },
    displayCompletion: { type: Boolean, required: false, default: true },
    loadAnswers: { type: Boolean, required: false, default: true },
    saveRunId: { type: Boolean, required: false, default: false },
    viewType: {
      type: String,
      required: true,
      validator: (prop) => Object.values(globals.survey.viewTypes).includes(prop),
    },
  },
  setup() {
    const userStore = useUserStore();
    const {
      user,
      userCompanyName,
    } = storeToRefs(userStore);
    const {
      completeFeedItem,
    } = userStore;

    const {
      companyDomains,
    } = storeToRefs(useCompanyStore());
    const {
      trackEvent,
    } = useAnalyticsStore();

    const { showSnackbar } = useGlobalStore();

    return {
      completeFeedItem,
      user,
      userCompanyName,
      companyDomains,
      trackEvent,
      showSnackbar,
    };
  },
  data() {
    return getDefaultState();
  },
  computed: {
    ...mapStores(useSurveysStore),
    cardClasses() {
      if (this.minimal) {
        return 'flat elevation-0 minimal';
      }

      return '';
    },
    progressBarValue() {
      return this.surveyModel?.pageCount > 0
        ? (this.currentPageNo / this.surveyModel.pageCount) * 100 : 0;
    },
    progressTextValue() {
      return `${this.currentPageNo} / ${(this.surveyModel?.pageCount)}`;
    },
    showProgress() {
      return this.showPageIndicator === true && this.surveyModel?.pageCount > 1;
    },
  },
  async created() {
    await this.loadSurvey();
  },
  beforeDestroy() {
    // Delay execution until the next tick to allow any in-flight calls to complete. Specifically
    // this addresses a race condition that can otherwise occur if the user submits a response and
    // then immediately exits the survey: in this case, the survey model can be cleared before
    // sendDataToServer is called, which erases the survey id and causes an invalid API request.
    this.$nextTick(() => {
      if (this.survey.clear) {
        this.survey.clear(true, false);
      }
    });
  },
  methods: {
    async loadSurvey() {
      if (!this.surveyName && !this.surveyId) throw new Error('Require surveyName or surveyId');
      try {
        let surveyResponse;
        if (this.surveyName) {
          // get survey by name
          const { data } = await axios.get('/v1/surveys/', {
            params: {
              name: this.surveyName,
              latestVersion: true,
              results: this.loadAnswers,
              includeConfig: true,
            },
          });
          // uses list endpoint, so assigning to first array element
          [surveyResponse] = data;
        } else {
          // get survey by id
          const { data } = await axios.get(`/v1/surveys/${this.surveyId}`, {
            params: {
              results: this.loadAnswers,
            },
          });
          surveyResponse = data;
        }

        if (isEmpty(surveyResponse)) {
          this.$log.error('Response is empty', surveyResponse);
          throw new Error('No survey returned');
        }

        this.survey = surveyResponse;
        if (!isEmpty(surveyResponse.surveyResults)) {
          [this.surveyResult] = surveyResponse.surveyResults;
        }
      } catch (error) {
        // Reset data
        Object.assign(this.$data, getDefaultState());
        throw new Error(`Failed to load survey with ${this.surveyName ? this.surveyName : this.surveyId}`);
      }
      this.createSurveyModel();
      this.$emit('survey-loaded', this.survey);
      this.trackSurveyPresented();
    },
    createSurveyModel() {
      const s = new SurveyVue.Model(this.survey.config);
      if (!isNil(this.surveyResult.currentPageNo)) {
        // If currentPageNo is returned from API, use it
        s.currentPageNo = this.surveyResult.currentPageNo;
      } else if (!isEmpty(this.surveyResult.result)) {
        // If currentPageNo is not returned from API, find the first page that
        // has unanswered questions
        // This is not as reliable as stored currentPageNo, because some questions could be optional
        // So it's mainly to deal with legacy data
        s.currentPageNo = this.survey.config.pages
          .findIndex((page) => page.elements.some(
            (question) => isNil(this.surveyResult.result[question.name]),
          ));
      }

      s.data = this.surveyResult.result;
      s.css = surveyClassOverrides;
      s.showNavigationButtons = false;
      // update sortable widget to rank items within result box instead of dragging from source box
      s.getAllQuestions().forEach((question) => {
        const q = question;
        if (q.getType() === 'sortablelist') {
          q.value = q.choices.map((c) => c.value);
          q.isRequired = false;
        }
      });
      // Set survey variables
      forEach(this.surveyVariables, (value, key) => {
        s.setVariable(key, value);
      });
      s.onPartialSend.add(this.sendDataToServer);
      s.onComplete.add((sender) => {
        if (!this.displayCompletion) {
          // By default, the 'clear' method clears all results data and navigates to the first page
          // Pass params to keep the results in the UI and remain on the last page
          sender.clear(false, false);
        }

        this.completeSurvey();
      });

      // if this.survey.config?.goNextPageAutomatic is true, do not show the next button if there are unanswered questions
      // because navigation is automatic (survey.js displays next button for required questions)
      const { goNextPageAutomatic } = this.survey.config;
      s.onAfterRenderQuestion
        .add((sender) => {
          const totalRequiredQuestions = (sender.currentPage.questions.filter((q) => q.isRequired === true)).length;
          const totalRequiredAnswers = (
            sender.currentPage.questions.filter((q) => !isNil(q.value) && q.isRequired === true)
          ).length;
          if (
            totalRequiredQuestions > totalRequiredAnswers
            && goNextPageAutomatic

          ) {
            this.displayNextButton = false;
          } else {
            this.displayNextButton = true;
          }
        });

      // cannot use onAfterRenderSurvey, because it fires before the question(s)/answer(s) have rendered
      // which causes the flashing message bug
      s.onAfterRenderPage.add(() => {
        if (this.isSurveyLoading) this.isSurveyLoading = false;
      });

      // for a smooth transition, if this.survey.config?.goNextPageAutomatic is true, add a delay between the automatic transitions
      let doDelay = goNextPageAutomatic;
      s.onCurrentPageChanging
        /* eslint-disable no-param-reassign */
        .add((sender, options) => {
          if (!doDelay) return;
          options.allowChanging = false;
          setTimeout(() => {
            // this code taken from Survey.JS animation example
            // see example: https://surveyjs.io/Examples/Library/?id=survey-animation#content-js
            // flip doDelay so no additional delay occurs when setting sender.currentPage
            doDelay = false;
            sender.currentPage = options.newCurrentPage;
            /* eslint-enable no-param-reassign */
            doDelay = true;
          }, 500);
        });
      // ensure that the survey will not automatically be completed when user answers final question
      // allowCompleteSurveyAutomatic cannot be set in the JSON without making it serializable.
      // See https://github.com/surveyjs/survey-library/issues/2638 for more info from SurveyJS
      s.allowCompleteSurveyAutomatic = false;
      this.surveyModel = s;
      this.isSurveyComplete = false;
      if (s.currentPageNo === 0) {
        this.$emit('has-navigated-to-first-question', true);
      } else {
        this.$emit('has-navigated-to-first-question', false);
      }
      // see https://surveyjs.answerdesk.io/ticket/details/t7565/survey-currentpageno-value-not-always-updating-via-previous-button
      // about setting currentPageNo here. it is done to overcome a bug with surveyModel.currentPageNo not updating
      s.onCurrentPageChanged.add(() => {
        this.currentPageNo = this.surveyModel.currentPageNo + 1;
        this.handleNavigation();
      });
      this.currentPageNo = this.surveyModel.currentPageNo + 1;
    },
    async sendDataToServer() {
      // Scroll to top
      await goTo(0);

      // Send survey answers to fm-api
      const payload = {
        surveyId: this.survey.id,
        result: this.surveyModel.data,
        currentPageNo: this.surveyModel.currentPageNo,
      };
      if (this.runId) {
        payload.runId = this.runId;
      }
      if (!isEmpty(this.context)) {
        payload.context = this.context;
      }
      if (this.isSurveyComplete) {
        // Only set this when isSurveyComplete is true
        // So we don't revert isCompleted from true to false on server
        payload.isCompleted = true;
      }
      const resp = await axios.put(`/v1/surveys/${this.survey.id}/results`, payload);

      if (resp && !this.runId) {
        this.runId = resp.data.runId;

        if (this.saveRunId) {
          await this.surveysStore.updateSurveyRunId({
            runId: this.runId,
            identifier: this.surveyName || this.surveyId,
          });
        }
      }
    },
    trackSurveyPresented() {
      const properties = {
        userId: this.user.id,
        companyId: this.user.company.id,
        viewType: this.viewType,
        ...this.survey.id && { surveyId: this.survey.id },
        ...this.survey.name && { surveyName: this.survey.name },
        ...this.survey.category && { surveyCategory: this.survey.category },
      };
      this.trackEvent({ eventName: 'Survey Presented', propertiesObj: properties });
    },
    async completeSurvey() {
      this.$emit('completion-triggered');
      this.isSurveyComplete = true;
      await this.sendDataToServer();
      if (this.survey.config?.completedSnackbarMessage) {
        await this.showSnackbar({
          message: this.survey.config.completedSnackbarMessage,
          icon: 'check',
        });
      }
      await this.completeFeedItem({ type: 'survey', data: { surveyId: this.survey.id } });
      if (this.survey.category === 'qualities') {
        this.trackEvent({ eventName: 'Qualities Survey Completed' });
      }
      this.$emit('completed');
    },
    handleNavigation() {
      this.$emit('has-navigated-to-first-question', this.surveyModel.currentPageNo === 0);
    },
  },
};
</script>
<style lang="scss">
  /******************************************************/
  /* customizations of surveyJS elements                */
  /******************************************************/
  .surveyJS-radiogroup-root, .surveyJS-checkbox-root {border: 0 none}
  .surveyJS-checkbox-root label span:last-child {
    top: 0 !important;
  }
  .surveyJS-radiogroup-root input {
    border-style: inherit;
    border-radius: inherit;
    background-color: inherit;
  }
  .surveyJS-root {
    padding:8px 24px 24px 24px;
    font-size:15px;
  }
  .v-application .surveyJS-root h5.text-body-1 {
    font-size: 20px !important;
  }
  .surveyJS-root h5 {
    font-family: 'Avenir45', sans-serif;
    font-size: 20px !important;
    font-weight: 300;
    margin: 20px 0;
  }
  .surveyJS-root .sv-q-col-1 {
    margin-bottom: 12px;
  }
  .sv-q-col-1 input[type="radio"] {
    height: 20px;
    width: 20px;
  }
  .surveyJS-root label {
    display: inline-flex;
    align-items: center;
    span:last-child {
      padding-left: 2px;
      position: relative;
    }
  }
  .surveyJS-root .sv_container h3 {
    display:none;
  }
  .surveyJS-text {
    padding-left:5px;
    border:1px solid $secondary;
  }
  .surveyJS-root textarea {
    border:1px solid $secondary;
    padding:10px;
    margin-top:10px;
    resize: none;
    width:100%;
  }
  .surveyJS-control {
    min-width: 150px;
    padding: 5px 35px 5px 5px;
    border: 1px solid $secondary;
    min-height: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../assets/arrow_down.svg) 96% / 15% no-repeat;
  }
  .surveyJS-control::-ms-expand {display: none;} /* remove default arrow in IE 10 and 11 */
  .surveyJS-rating-root {
    position: relative;
    display:inline-block;
    vertical-align: middle;
    overflow: hidden;
    border-right:1px solid;
    cursor:pointer;}
  .surveyJS-rating-item {
    position: relative;
    float:left;
    text-align:center;
    padding: 0.5em 1em;
    min-width: 6em;
    border-left:1px solid;
    border-top:1px solid;
    border-bottom:1px solid;}
  .surveyJS-rating-selected {background-color: $secondary;}
  .surveyJS-root .sv_qstn_error_top {
    width: 100%;
  }
  .sv_q_required_text {display:none;}

  /******************************************************/
  /* customizations of sortablejs widget within surveys */
  /******************************************************/
  .sjs-sortablejs-root {margin-top: 16px;}
  .sjs-sortablejs-source {display:none;}
  .sjs-sortablejs-result {border: 1px solid #D8D8D8;margin-top: 10px;min-height: 50px;}
  .sjs-sortablejs-result .sjs-sortablejs-item {margin:5px;padding:10px;}
  .sjs-sortablejs-item {background-color: #FFFFFF;border: 1px solid #D8D8D8;cursor:pointer;}
</style>
