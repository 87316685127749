<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useVuetify } from '@/composables/useVuetify';
import Btn from './Button.vue';

const props = defineProps({
  value: { type: Boolean, required: true },
  allowClose: { type: Boolean, required: false, default: true },
  size: {
    type: String,
    required: false,
    default: 'default',
    validator: (value: string) => ['small', 'large', 'x-large', 'default'].includes(value),
  },
  title: { type: String, required: false, default: null },
  bodyText: { type: String, required: false, default: null },
  footerText: { type: String, required: false, default: null },
  preHeaderText: { type: String, required: false, default: null },
  primaryActionName: { type: String, required: false, default: 'Confirm' },
  secondaryActionName: { type: String, required: false, default: 'Cancel' },
  closeAfterAction: { type: Boolean, required: false, default: true },
  // action buttons are hidden if isLoading = true
  isLoading: { type: Boolean, required: false, default: false },
  e2eTag: { type: String, required: false, default: null },
  primaryActionProps: { type: Object, required: false, default: () => ({}) },
  persistent: { type: Boolean, required: false, default: true },
  color: { type: String, required: false, default: null },
  dataExtTag: { type: String, required: false, default: null },
});

const emits = defineEmits(['input', 'secondary', 'primary', 'close']);

const displayModal = ref(props.value);

const modalWidth = computed(() => {
  switch (props.size) {
    case 'small':
      return 400;
    case 'large':
      return 800;
    case 'x-large':
      return 960;
    default:
      return 600;
  }
});

const titleFontSize = computed(() => (props.size === 'small' ? 'text-subtitle-1' : 'text-h1'));

const footerTextStyle = computed(() => (props.size === 'small' ? 'flex-wrap: wrap;' : undefined));

const dialogClass = computed(() => {
  const vuetify = useVuetify();
  return vuetify.breakpoint.xs ? 'elevation-0' : 'top-align-dialog elevation-0';
});

watch(() => props.value, (updated) => {
  displayModal.value = updated;
});

const handleSecondaryAction = () => {
  if (props.closeAfterAction) emits('input', false);
  emits('secondary');
};

const handlePrimaryAction = () => {
  if (props.closeAfterAction) emits('input', false);
  emits('primary');
};

const handleCloseAction = () => {
  emits('input', false);
  emits('close');
};

const handleModalInput = (value: boolean) => {
  emits('input', value);
  if (!value) emits('close');
};

const scrollToTop = () => {
  // Vuetify goTo() functionality not scrolling properly within a dialog
  // so scrolling to top of modal using native JS here
  const header = document.querySelector(`[data-e2e='${props.e2eTag}']`);
  if (header) header.scrollIntoView(true);
};

defineExpose({ scrollToTop });
</script>

<template>
  <VDialog
    v-model="displayModal"
    :width="modalWidth"
    :persistent="persistent"
    :content-class="`${dialogClass} grow-modal`"
    @input="handleModalInput"
  >
    <VCard
      :data-e2e="e2eTag"
      :color="color"
      class="overflow-x-hidden"
    >
      <slot name="pre-header">
        <VCardText
          id="modal-pre-header"
          class="pt-5 pr-5 mb-2"
        >
          <slot name="pre-header-text">
            <h4
              v-if="preHeaderText"
              class="text-h4"
            >
              {{ preHeaderText }}
            </h4>
          </slot>
          <Btn
            v-if="allowClose"
            type="icon"
            top
            right
            absolute
            data-e2e="modalClose"
            @click="handleCloseAction"
          >
            <VIcon color="secondary4">
              close
            </VIcon>
          </Btn>
        </VCardText>
      </slot>
      <slot :action-handlers="{ handleSecondaryAction, handlePrimaryAction }">
        <div class="pa-8 pt-1">
          <slot name="header">
            <div
              v-if="title"
              :class="`${titleFontSize} pb-6`"
            >
              {{ title }}
            </div>
          </slot>

          <slot name="body">
            <VCardText class="px-0 py-4">
              <p v-if="bodyText">
                {{ bodyText }}
              </p>
            </VCardText>
          </slot>
          <VSpacer class="my-5" />
          <slot name="additional-body" />
          <slot name="footer">
            <VCardActions
              class="pa-0"
              :style="footerTextStyle"
            >
              <slot name="additional-footer">
                <span
                  v-if="footerText"
                  class="font-italic text-caption secondary2--text"
                >
                  {{ footerText }}
                </span>
              </slot>
              <slot name="actions">
                <template v-if="!isLoading">
                  <VSpacer
                    class="pa-5"
                  />
                  <Btn
                    type="secondary"
                    data-e2e="modalSecondaryAction"
                    @click="handleSecondaryAction()"
                  >
                    {{ secondaryActionName }}
                  </Btn>
                  <Btn
                    v-bind="primaryActionProps"
                    type="primary"
                    data-e2e="modalPrimaryAction"
                    :data-ext="dataExtTag"
                    @click="handlePrimaryAction()"
                  >
                    {{ primaryActionName }}
                  </Btn>
                </template>
              </slot>
            </VCardActions>
          </slot>
        </div>
      </slot>
    </VCard>
  </VDialog>
</template>

<style lang="css" scoped>
  /* override vuetify font size */
  .v-application .text-caption {
    font-size: 0.875rem !important;
  }

  :deep(.top-align-dialog) {
    align-self: flex-start;
    margin-top: 48px;
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior */
    /* No scroll chaining occurs to neighboring scrolling areas, and default scroll overflow behavior is prevented. */
    overscroll-behavior: none;
  }
</style>
