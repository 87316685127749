<script setup lang="ts">
import Vue, { onMounted } from 'vue';
import { useAuthStore, useCompanyStore, useUserStore } from '@/store';
import { useRouter } from 'vue-router/composables';
import LoadingDialog from '@/components/elements/LoadingDialog.vue';
import EventBus from '@/event-bus';
import type { Auth0ParseHashError } from 'auth0-js';

const router = useRouter();

Vue.prototype.$log.info('Callback initial');

onMounted(async () => {
  const auth = useAuthStore();
  const user = useUserStore();
  const company = useCompanyStore();
  try {
    await auth.handleAuth();
    Vue.prototype.$log.debug('Callback handleAuth done');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: unknown) {
  // auth0-config repository/rules/limit-login-method will return this code and description when peloton users use restricted login method
    if (typeof error !== 'string'
        && (error as Auth0ParseHashError).code === 'access_denied'
        && (error as Auth0ParseHashError).description === 'samlConnectionRequired') {
      Vue.prototype.$log.info('Error login method restricted', error);
      router.push('/error/user?errorType=samlConnectionRequired');
    } else {
      Vue.prototype.$log.info('Error handling authentication', error);
      router.push('/error/user?errorType=unrecognizedAccount');
    }

    return; // prevent loadCurrentUser below from being executed
  }

  await Promise.all([
    company.loadNavigationOptions(),
    user.loadCurrentUser(),
  ]);
  EventBus.$emit('currentUserLoaded'); // this will trigger the "initializePage" function in App.vue
});
</script>

<template>
  <LoadingDialog />
</template>
