import adminRoutes from './admin';

// Routes used in the operations view of the app
// All operations routes require authentication with operations authorization
// All operations routes are prepended with /operations in the path

export default [
  {
    path: '/talent/opportunities/requisitions/:requisitionId/preview',
    component: () => import('../../views/requisitions/Requisition.vue'),
    props: (route) => ({
      requisitionId: Number.parseInt(route.params.requisitionId, 10) || undefined,
      displayTalentOptions: true,
      isPreviewing: true,
      query: route.query,
    }),
    name: 'previewRequisition',
    meta: {
      authRequired: true,
      operationsRequired: true,
      title: 'Preview Opportunity',
      analyticsPageName: 'Preview Requisition',
      displayBackNavigation: true,
    },
  },
  {
    path: '/talent/opportunities/requisitions/:requisitionId/applications/preview',
    component: () => import('../../views/requisitions/ApplicationChecklist.vue'),
    props: (route) => ({
      requisitionId: Number.parseInt(route.params.requisitionId, 10) || undefined,
      isPreviewing: true,
      query: route.query,
    }),
    meta: {
      authRequired: true,
      operationsRequired: true,
      title: 'Preview Application',
      analyticsPageName: 'Preview Application',
    },
  },
  {
    path: '/operations',
    component: () => import('../../views/BaseRouter.vue'),
    meta: {
      authRequired: true,
      operationsRequired: true,
      view: 'operations',
    },
    children: [
      ...adminRoutes,
      {
        path: 'talent/search/:userId',
        components: {
          drawerView: () => import('../../views/search/Profile.vue'),
          default: () => import('../../views/dashboard/OperationsDashboard.vue'),
        },
        props: {
          drawerView: (route) => ({
            userId: Number.parseInt(route.params.userId, 10) || undefined,
            query: route.query,
          }),
          default: (route) => ({
            query: route.query,
          }),
        },
        meta: {
          title: 'Talent Search',
          analyticsPageName: 'Talent Search Result Profile',
          drawer: true,
        },
      },
      {
        path: 'talent/search',
        component: () => import('../../views/search/Search.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'People',
          analyticsPageName: 'Talent Search',
        },
      },
      {
        path: 'talent',
        redirect: 'talent/search',
      },
      {
        path: 'opportunities/roles/:roleId',
        component: () => import('../../views/roles/Role.vue'),
        props: (route) => ({
          // pass thru URL parameters
          roleId: Number.parseInt(route.params.roleId, 10) || undefined,
          query: route.query,
        }),
        meta: {
          title: 'Role',
          analyticsPageName: 'Role',
          displayBackNavigation: true,
        },
      },
      {
        name: 'createOpportunity',
        path: 'opportunities/requisition/add',
        component: () => import('../../views/requisitions/Add.vue'),
        props: (route) => ({
          // pass thru URL parameters
          programId: Number.parseInt(route.query.programId, 10) || undefined,
          copyRequisitionId: Number.parseInt(route.query.copyRequisitionId, 10) || undefined,
          templateId: Number.parseInt(route.query.templateId, 10) || undefined,
          reqType: route.query.reqType,
        }),
        meta: {
          title: 'Create Opportunity',
          analyticsPageName: 'Add Requisition',
          requiredRequisitionActions: ['write'],
        },
      },
      {
        path: '/operations/opportunities/requisitions/choose-template',
        name: 'templateLibrary',
        component: () => import('../../views/requisition_templates/TemplateLibrary.vue'),
        props: (route) => ({
          programId: Number.parseInt(route.query.programId, 10) || undefined,
        }),
        meta: {
          title: 'Opportunity Template Library',
          analyticsPageName: 'Choose Requisition Template',
        },
      },
      {
        path: 'opportunities/requisitions/:requisitionId/edit',
        component: () => import('../../views/requisitions/Edit.vue'),
        props: (route) => ({
          requisitionId: Number.parseInt(route.params.requisitionId, 10) || undefined,
        }),
        meta: {
          title: 'Edit Opportunity',
          analyticsPageName: 'Edit Requisition',
          requiredRequisitionActions: ['write'],
        },
      },
      {
        path: 'opportunities/requisitions/:requisitionId',
        component: () => import('../../views/requisitions/Requisition.vue'),
        props: (route) => ({
          requisitionId: Number(route.params.requisitionId),
          displayOperationsOptions: true,
          query: route.query,
        }),
        name: 'operationsRequisition',
        meta: {
          title: 'Opportunity',
          analyticsPageName: 'View Requisition',
          displayBackNavigation: true,
        },
      },
      // redirect old requisitions search page to combined search page
      {
        path: 'opportunities/requisitions',
        redirect: (to) => {
          const { query } = to;
          return { path: '/operations/opportunities', query: { ...query } };
        },
      },
      {
        path: 'opportunities',
        component: () => import('../../views/opportunities/Opportunities.vue'),
        props: (route) => ({
          query: route.query,
        }),
        name: 'operationsOpportunities',
        meta: {
          title: 'Opportunities',
          analyticsPageName: 'Opportunities',
        },
      },
      {
        path: 'opportunities/program/add',
        component: () => import('../../views/programs/Add.vue'),
        props: true,
        meta: {
          title: 'Create Program',
          analyticsPageName: 'Add Program',
          requiredProgramActions: ['write'],
        },
      },
      {
        path: 'opportunities/programs/:programId/edit',
        component: () => import('../../views/programs/Edit.vue'),
        props: (route) => ({
          programId: Number.parseInt(route.params.programId, 10) || undefined,
        }),
        meta: {
          title: 'Edit program',
          analyticsPageName: 'Edit Program',
          requiredProgramActions: ['write'],
        },
      },
      {
        path: 'opportunities/programs/:programId',
        component: () => import('../../views/programs/Program.vue'),
        props: (route) => ({
          programId: Number.parseInt(route.params.programId, 10),
          displayOperationsOptions: true,
          query: route.query,
        }),
        name: 'operationsProgram',
        meta: {
          title: 'Program',
          analyticsPageName: 'View Program',
          displayBackNavigation: true,
        },
      },
      // redirect /programs/ routes to /opportunities/programs
      {
        path: 'programs/:programId',
        redirect: (to) => {
          const { params, query } = to;
          return { path: `/operations/opportunities/programs/${params.programId}`, query: { ...query } };
        },
      },
      {
        path: 'program/add',
        redirect: (to) => {
          const { query } = to;
          return { path: '/operations/opportunities/program/add', query: { ...query } };
        },
      },
      {
        path: 'programs/:programId/edit',
        redirect: (to) => {
          const { params, query } = to;
          return { path: `/operations/opportunities/programs/${params.programId}/edit`, query: { ...query } };
        },
      },
      // redirect old programs search page to combined search page
      {
        path: 'programs',
        redirect: (to) => {
          const { query } = to;
          return { path: '/operations/opportunities', query: { t: 'programs', ...query } };
        },
      },
      {
        path: 'insights',
        component: () => import('../../views/insights/Dashboard.vue'),
        props: (route) => ({
          // pass thru URL parameters
          query: route.query,
        }),
        meta: {
          title: 'Insights',
          analyticsPageName: 'Insights Dashboard',
        },
      },
      {
        path: 'user/settings',
        component: () => import('../../views/user/Settings.vue'),
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'User Settings',
          analyticsPageName: 'User Settings',
        },
      },
      {
        path: 'dashboard',
        component: () => import('../../views/dashboard/OperationsDashboard.vue'),
        props: (route) => ({
          query: route.query,
        }),
        name: 'operationsDashboard',
        meta: {
          title: 'Dashboard',
          analyticsPageName: 'Operations Dashboard',
        },
      },
      {
        path: 'user/profile',
        components: {
          drawerView: () => import('../../views/user/Profile.vue'),
          default: () => import('../../views/dashboard/OperationsDashboard.vue'),
        },
        props: {
          drawerView: (route) => ({
            query: route.query,
          }),
        },
        name: 'operationsProfile',
        meta: {
          title: 'Profile',
          analyticsPageName: 'Profile',
          drawer: true,
        },
      },
      {
        path: 'chat',
        components: {
          drawerView: () => import('../../components/chat/CareerAssistant.vue'),
          default: () => import('../../views/dashboard/OperationsDashboard.vue'),
        },
        name: 'operationsCareerChat',
        meta: {
          title: 'Chat',
          analyticsPageName: 'Pursuits Career Assistant',
          drawer: true,
          allowClose: true,
          color: 'white',
        },
      },
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: 'roles/:roleId',
        components: {
          drawerView: () => import('../../views/ja_roles/JARoleDetail.vue'),
          default: () => import('../../views/dashboard/OperationsDashboard.vue'),
        },
        props: {
          drawerView: (route) => ({
            query: route.query,
            roleId: Number.parseInt(route.params.roleId, 10) || undefined,
          }),
        },
        meta: {
          title: 'JA Role',
          analyticsPageName: 'JA Role',
          drawer: true,
        },
      },
    ],
  },
];
