<script setup lang="ts">
import { computed } from 'vue';
import { useTheme } from '@/composables/useTheme';
import ImageWrapper from '@/components/elements/ImageWrapper.vue';
import { useImage } from '@/utils/image';
import CenterLayout from '@/layouts/Center.vue';
import { footerLinks } from '@/globals';
import { isNil } from 'lodash-es';

const { logoBranding } = useTheme();

const hasCustomLogo = computed(() => !isNil(logoBranding.value?.header?.logo));
</script>

<template>
  <VFooter
    data-e2e="navFooter"
    class="text-center text-caption footer flex px-0"
  >
    <CenterLayout class="pa-0">
      <div
        :class="`d-flex flex-column flex-md-row px-3 justify-center ${hasCustomLogo ? 'justify-md-space-between' : ''}`"
        style="width:100%; justify-content:center; align-items:center;"
      >
        <div
          v-if="hasCustomLogo"
          class="ma-0 mb-2 mb-md-0 d-flex align-center"
        >
          <span class="mr-2">
            Powered by
          </span>
          <ImageWrapper
            e2eTag="powerByGrowLogo"
            contain
            class="d-inline-block"
            :src="useImage('svg/beamery_grow_logo.svg')"
            height="24"
            maxWidth="82"
          />
        </div>
        <div
          id="footer-links"
          class="ma-0 d-flex align-center"
        >
          <!-- footer items currently set to open links into new browser tab -->
          <template v-for="footerLink in footerLinks">
            <a
              :key="footerLink.title"
              :href="footerLink.path"
              target="_blank"
              rel="noopener noreferrer"
              class="footer-link primary-link"
              style="text-decoration:none"
            >
              <!-- copyright (&copy;) cannot be set from footerLinks data, will render text not symbol -->
              <span v-if="footerLink.showSymbol">&copy;</span>{{ footerLink.title }}
            </a>
          </template>
        </div>
      </div>
    </CenterLayout>
  </VFooter>
</template>

<style lang="scss">
.v-footer.footer {
  &.theme--light {
    background-color: var(--grow-site-background);
  }

  padding-top: 1rem;
  padding-bottom: 1rem;

  .left {
    text-align: left;
    flex: 1;
  }

  .footer-link {
    padding-left: 8px;
    padding-right: 8px;

    &:last-child {
      border: 0px;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}
</style>
