const getLocale = () => navigator.languages;

export const getEnLocale = () => {
  const localeList = getLocale();

  if (localeList[0]?.startsWith('en')) {
    return localeList[0];
  }

  return 'en-US';
};
