/* eslint-disable no-bitwise */
import { ref, watch, computed } from 'vue';
import { type VuetifyThemeVariant } from '@/plugins/vuetify';
import { pick } from 'lodash-es';
import { assignGlobalCss } from '@/utils/theme';
import { useVuetify } from './useVuetify';

type ThemeConfigs = Partial<VuetifyThemeVariant>;

interface HeaderBranding {
  logo?: string
  width?: number
  height?: number
}

interface FooterBranding {
  slogan?: string
  logo?: string
}

interface LogoBranding {
  header?: HeaderBranding
  footer?: FooterBranding
  favicon?: string
  pdfLogo?: string
}

const logoBranding = ref<LogoBranding>({});

export const useTheme = () => {
  // vuetify's current theme
  const vuetify = useVuetify();
  const currentTheme = (): VuetifyThemeVariant => vuetify.theme.themes.light;

  const vuetifyThemeConfig = ref<VuetifyThemeVariant>(currentTheme());

  const colorNames = [
    'accent',
    'accent0',
    'accent1',
    'accent2',
    'accent3',
    'accent4',
    'error',
    'primary',
    'primary0',
    'primary1',
    'primary2',
    'primary3',
    'primary4',
    'secondary',
    'secondary0',
    'secondary1',
    'secondary2',
    'secondary3',
    'secondary4',
    'success',
    'warning',
  ];

  const setColorConfigs = (newColorConfigs: ThemeConfigs): void => {
    const newVuetifyTheme = { ...vuetifyThemeConfig.value };

    if (newColorConfigs) {
      // generate`--grow-[name]` configs

      const cssVarMaps = {};

      Object.keys(newColorConfigs).forEach((key) => {
        cssVarMaps[`--grow-${key}`] = newColorConfigs[key];
      });

      // set to root
      assignGlobalCss(cssVarMaps);

      /**
       * Set theme configs to vuetify
       * NOTE: Assign directly to this object since we are using the same format as Vuetify theme.
      */
      Object.assign(newVuetifyTheme, newColorConfigs);
      vuetifyThemeConfig.value = newVuetifyTheme;
    }
  };

  const setLogoBranding = (newConfig: Partial<LogoBranding>) => {
    logoBranding.value = newConfig;
  };

  // Update to Vuetify when new theme configurations are available.
  watch(vuetifyThemeConfig, (newThemeConfigs) => {
    Object.assign(currentTheme(), newThemeConfigs);
  }, { deep: true });

  const primaryTheme = computed({
    get: () => pick(vuetifyThemeConfig.value, colorNames),
    set: (v) => {
      setColorConfigs(v);
    },
  });

  const applyFavicon = (icon: string) => {
    // favicon should come from `/public/favicon`
    document?.head
      .querySelectorAll<HTMLLinkElement>('link[rel*="icon"]')
      .forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.href = `/favicon/${icon}`;
      });
  };

  watch(
    () => logoBranding.value.favicon,
    (newIcon, old) => {
      if (typeof newIcon === 'string' && newIcon !== old) {
        applyFavicon(newIcon);
      }
    },
    { immediate: true },
  );

  return {
    // COLORS
    setColorConfigs,
    primaryTheme,
    // LOGO
    logoBranding,
    setLogoBranding,
  };
};
