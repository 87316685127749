<template>
  <v-btn
    :class="classType"
    :data-e2e="dataE2e"
    :data-ext="dataExt"
    :disabled="disabled"
    :loading="loading"
    :outlined="isOutlined"
    :href="href"
    :to="to"
    :target="target"
    :icon="type === 'icon' || icon"
    :color="computedColor"
    :text="type === 'text'"
    :fab="type === 'fab'"
    :plain="plain"
    :x-small="size === 'x-small'"
    :small="size === 'small'"
    :large="size === 'large'"
    :x-large="size === 'x-large'"
    :depressed="depressed"
    :tile="tile"
    :rounded="rounded"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :absolute="absolute"
    :ripple="ripple"
    :block="block"
    :width="width"
    :height="height"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :min-width="minWidth"
    :min-height="minHeight"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    type: {
      type: String,
      required: false,
      default: null,
      validator: (value) => ['primary', 'secondary', 'text', 'icon', 'fab'].includes(value),
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) => ['x-small', 'small', 'medium', 'large', 'x-large'].includes(value),
    },
    dataE2e: { type: String, required: false, default: null },
    dataExt: { type: String, required: false, default: null },
    href: { type: String, required: false, default: undefined },
    color: { type: String, required: false, default: null },
    target: { type: String, required: false, default: '_self' },
    disabled: { type: Boolean, required: false, default: false },
    plain: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
    to: { type: [String, Object], required: false, default: null },
    icon: { type: Boolean, required: false, default: false },
    depressed: { type: Boolean, required: false, default: false },
    tile: { type: Boolean, required: false, default: false },
    rounded: { type: Boolean, required: false, default: false },
    top: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
    right: { type: Boolean, required: false, default: false },
    left: { type: Boolean, required: false, default: false },
    absolute: { type: Boolean, required: false, default: false },
    ripple: { type: Boolean, required: false, default: true },
    block: { type: Boolean, required: false, default: false },
    width: { type: String, required: false, default: null },
    height: { type: String, required: false, default: null },
    minWidth: { type: String, required: false, default: null },
    minHeight: { type: String, required: false, default: null },
    maxWidth: { type: String, required: false, default: null },
    maxHeight: { type: String, required: false, default: null },
  },
  computed: {
    classType() {
      if (this.type === 'secondary') return 'background-white border-primary2';
      return this.type;
    },
    isOutlined() {
      if (this.type === 'secondary') return true;
      return this.outlined;
    },
    computedColor() {
      if (this.color) return this.color;
      if (this.type === 'primary' || this.type === 'secondary' || this.type === 'text') {
        return 'primary';
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.v-btn {
  text-transform: none;
  letter-spacing: normal;
}

.v-btn.v-size--small {
  font-size: 0.875rem;
}

.deactivated.v-btn:hover:before {
  background-color: transparent;
}
</style>
