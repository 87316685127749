// eslint-disable-next-line import/no-cycle
import fmApiClient from '@/services/fmApiClient';
import type { Requisition } from '@/types/requisition';
import type { RecommendationPreference } from '@/types/user';
import globals from '@/globals';
import { VERSION_V1 } from '../config';

interface MatchedOppyOptions {
  eagerLoad: string[]
  limit
  offset
  narrowCriteria: boolean
  locationFiltering: RecommendationPreference['locationFiltering'],
  requisitionTypeIds: number[]
}

export const getUserMatchedOpportunities = async (
  userId: number,
  options: MatchedOppyOptions = {
    eagerLoad: ['openings', 'locations', 'group',
      'role', 'program', 'processStage', 'bookmarks', 'recommendations'],
    limit: globals.pagination.defaultRequestLimit,
    offset: 10,
    narrowCriteria: false,
    locationFiltering: 'anywhere',
    requisitionTypeIds: [],
  },
): Promise<{ results: Requisition[], totalResults: number }> => fmApiClient
  .get(`/${VERSION_V1}/users/${userId}/matched-requisitions`, { params: options });
