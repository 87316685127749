import type { Education } from '@/types/education';
import { VERSION_V1 } from '../config';
// eslint-disable-next-line import/no-cycle
import fmApiClient from '../fmApiClient';

export const getUserEducations = async (
  userId: number,
): Promise<Education[]> => fmApiClient.get(`/${VERSION_V1}/users/${userId}/educations`);

export const patchUserEducations = async (
  userId: number,
  data: Partial<Education>[],
): Promise<void> => fmApiClient.patch(`/${VERSION_V1}/users/${userId}/educations`, data);

export const postNewUserEducation = async (
  userId: number,
  data,
): Promise<void> => fmApiClient.post(`/${VERSION_V1}/users/${userId}/educations`, { ...data });

export const patchUserEducationById = async (
  eduId: number,
  userId: number,
  data,
): Promise<void> => fmApiClient.patch(`/${VERSION_V1}/users/${userId}/educations/${eduId}`, { ...data });
