/* eslint-disable no-param-reassign */
import { isLocalOrDev } from '@/utils/env';
import VueLogger, { type LoggerOptions, type LogLevel } from 'vue-logger-plugin/dist/index';
// define options
const loggerLevel = (isLocalOrDev ? 'debug' : 'info') as LogLevel;

// Configure Logger
const options: LoggerOptions = {
  enabled: true,
  consoleEnabled: true,
  callerInfo: true,
  level: loggerLevel,
  prefixFormat: ({ level, caller }) => (caller
    ? `[${level.toUpperCase()}][${caller.fileName || ''}:${caller.functionName || ''}:${caller.lineNumber || ''}]`
    : `[${level.toUpperCase()}]`),
};

// export logger with applied options
// eslint-disable-next-line new-cap
const logger = VueLogger.default !== undefined ? (new VueLogger.default(options)) : new VueLogger(options);
logger.log(`Logger initialized with "${loggerLevel}"`);
export default logger;
