/**
 * NOTE:
 *
 * All admin routes require authentication with operations and admin authorization
 * All admin routes are prepended with /operations/admin in the path
 * Routes are used in the operations view of the app
 *
 * `meta.requireNavOptions` includes all the required navigation options,
 * but users only need to have one of these options in order to access this route.
 *
 * */

import { union } from 'lodash-es';

/**
 * Add authentication guard information to the meta for all admin routes.
 *
 * @param {*} adminRoutes
 * @returns
 */
const adminAuthGuardMeta = (adminRoutes) => adminRoutes.map((route) => {
  const parsedRoute = { ...route };
  parsedRoute.meta = {
    authRequired: true,
    operationsRequired: true,
    view: 'operations',
    ...(route.meta ?? {}),
    requireNavOptions: union(['admin_settings'], route.meta?.requireNavOptions ?? []),
  };
  return parsedRoute;
});

const adminRoutes = [
  {
    path: 'admin/settings',
    component: () => import('../../views/admin/CompanyAdmin.vue'),
    props: (route) => ({
      query: route.query,
    }),
    meta: {
      title: 'Settings',
      analyticsPageName: 'Settings',
      requireNavOptions: ['user_admin', 'location_admin', 'reminders', 'group_admin', 'user_ingest', 'templates'],
    },
  },
  {
    path: 'admin/requisition-template/add',
    component: () => import('../../views/requisition_templates/Add.vue'),
    meta: {
      title: 'Create Opportunity Template',
      analyticsPageName: 'Add Requisition Template',
    },
  },
  {
    path: 'admin/requisition-templates/:templateId/edit',
    component: () => import('../../views/requisition_templates/Edit.vue'),
    meta: {
      title: 'Edit Opportunity Template',
      analyticsPageName: 'Edit Requisition Template',
    },
  },

];
export default adminAuthGuardMeta(adminRoutes);
