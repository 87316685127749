import defaultColors from '@/styles/color-map.module.scss';
import { isEmpty } from 'lodash-es';

/**
 *
 * @param varString custom variable name. ex: --grow-[color]
 * @param fallbackHex fallback color hex if not find target variable
 *                    if fallbackHex not provided, will find color from our default theme
 * @returns
 */
export const getHexFromVar = (varString: string, fallbackHex: string | null = null): string => {
  if (isEmpty(varString)) throw new Error('variable name is required');
  const varName = varString.replace(/^var\(([\S]+)\)/g, '$1');
  const root = document.documentElement;
  const findColor = getComputedStyle(root).getPropertyValue(varName);
  if (!findColor) {
    console.debug(`Cannot find ${varName}`);
    if (fallbackHex) {
      return fallbackHex;
    }
    // fallback colors
    return defaultColors[varName.replace('--grow-', '')];
  }
  return findColor;
};

/**
 * Assign css variables to element
 *
 * @param cssMap
 * @param selector
 */
export const assignGlobalCss = (cssMap: Record<string, string>, selector = ':root') => {
  let el: HTMLElement | null = document.querySelector(selector);
  if (!el) {
    el = document.querySelector(':root') as HTMLElement;
  }

  // Assign variables to `:root`
  Object.keys(cssMap).forEach((key: string) => {
    el?.style?.setProperty(key, cssMap[key]);
  });
};
