<template>
  <v-tooltip v-bind="tooltipProps">
    <template #activator="{ on }">
      <span v-on="onCondition && on">
        <slot name="default" />
      </span>
    </template>

    <slot name="content">
      <span
        v-if="text"
        v-html="text"
      />
    </slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    position: { type: String, required: false, default: 'top' },
    text: { type: String, required: false, default: null },
    onCondition: { type: Boolean, required: false, default: true },
    minWidth: { type: String, required: false, default: undefined },
    maxWidth: { type: String, required: false, default: 'auto' },
    disabled: { type: Boolean, required: false, default: false },
  },
  computed: {
    tooltipProps() {
      return {
        [this.position]: true,
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        disabled: this.disabled,
      };
    },
  },
};
</script>
