<script setup lang="ts">
import {
  onBeforeMount, ref, computed, unref,
} from 'vue';
import { isNil } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useUserStore, useCompanyStore, useAuthStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';
import { useImage } from '@/utils/image';
import { useVuetify } from '@/composables/useVuetify';
import { useTheme } from '@/composables/useTheme';
import TopNavigationTab from './TheTopNavigationTab.vue';
import AccountMenu from './TheTopNavigationAccountMenu.vue';
import MobileNavigationMenu from './TheTopNavigationMobileMenu.vue';
import NotificationMenu from './NotificationMenu.vue';
import CustomerSupport from './CustomerSupport.vue';
import ImageWrapper from '../elements/ImageWrapper.vue';
import type { AccountMenuItem, NavItem } from './types';
import globals from '../../globals';

const operationsTabs: NavItem[] = [
  {
    title: 'Home',
    type: 'home',
    icon: 'dashboard',
    path: '/operations/dashboard',
  },
  {
    title: 'Opportunities',
    type: 'opportunities',
    icon: 'search',
    path: '/operations/opportunities',
  },
  {
    title: globals.userSearch.entityDisplayName,
    type: 'talent',
    icon: 'people',
    path: '/operations/talent/search',
  },
  {
    title: 'Insights',
    type: ['insights_dashboards', 'insights_feeds', 'admin_task_queue'],
    icon: 'bar_chart',
    path: '/operations/insights',
  },
  {
    title: 'Profile',
    type: 'profile',
    icon: 'person',
    path: '/operations/user/profile',
  },
];

const talentTabs: NavItem[] = [
  {
    title: 'Home',
    type: 'home',
    icon: 'home',
    path: '/talent/dashboard',
  },
  {
    title: 'Explore',
    type: 'explore',
    icon: 'explore',
    path: '/talent/explore',
  },
  {
    title: 'Opportunities',
    type: 'opportunities',
    icon: 'search',
    path: '/talent/opportunities',
  },
  {
    title: globals.userSearch.entityDisplayName,
    type: 'talent',
    icon: 'people',
    path: '/talent/talent/search',
  },
  {
    title: 'Profile',
    type: 'profile',
    icon: 'person',
    path: '/talent/user/profile',
  },
];
const isHamburgerOpen = ref(false);

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const { logout } = useAuthStore();
const { logoBranding } = useTheme();

const {
  currentView,
  user,
  isUserOnboarded,
} = storeToRefs(userStore);

const companyStore = useCompanyStore();
const {
  hasOperationsAuthorization,
  hasOperationsNavOption,
  hasTalentNavOption,
} = storeToRefs(companyStore);

onBeforeMount(async () => {
  await companyStore.loadNavigationOptions();
});

const operationsView = computed<boolean>(() => currentView?.value === 'operations');

const isCustomerSupportWidgetVisible = computed<boolean>(
  () => (!!(!route.meta?.suppressZendeskWidget && user?.value?.email)),
);

const openDocumentation = (): void => {
  // open documentation link in new tab
  window.open('https://support.grow.beamery.com', '_blank', 'noopener');
};

const hasNavOption = (types: string | string[] | undefined): boolean => {
  const t = Array.isArray(types) ? types : [types];
  return t.some((type) => {
    const fn = operationsView.value ? hasOperationsNavOption.value : hasTalentNavOption.value;
    return fn(type);
  });
};

const selectProfile = async () => {
  await userStore.toggleProfile(operationsView.value ? 'talent' : 'operations');
  router.push(operationsView.value ? '/operations' : '/talent');
};

const displayNavigation = computed<boolean>(() => !!(isUserOnboarded.value && currentView?.value === 'talent')
  || !!(user.value?.hasAcceptedPrivacyPolicy && currentView?.value === 'operations'));

const navTabs = computed(() => (operationsView.value ? operationsTabs : talentTabs)
  .filter((tab) => hasNavOption(tab.type)));

const accountMenuItems = computed<AccountMenuItem[]>(() => [
  {
    title: 'Switch to operations dashboard',
    method: selectProfile,
    view: 'talent',
    checkNavOption: false,
    conditionals: hasOperationsAuthorization.value,
    dataE2E: 'operationsView',
  },
  {
    title: 'Switch to employee dashboard',
    method: selectProfile,
    view: 'operations',
    checkNavOption: false,
    dataE2E: 'employeeView',
  },
  {
    title: 'Documentation',
    method: openDocumentation,
    view: 'default',
    checkNavOption: false,
    dataE2E: 'documentationView',
  },
  {
    title: 'Account settings',
    path: `/${currentView?.value}/user/settings`,
    view: 'default',
    checkNavOption: false,
    conditionals: displayNavigation.value,
    dataE2E: 'userSettings',
  },
  {
    title: 'Admin settings',
    type: 'admin_settings',
    path: '/operations/admin/settings',
    view: 'operations',
    checkNavOption: true,
    conditionals: hasOperationsAuthorization.value && displayNavigation.value,
    dataE2E: 'adminSettings',
  },
  {
    title: 'Log out',
    method: logout,
    view: 'default',
    checkNavOption: false,
    dataE2E: 'logout',
  },
]);

const profileDrawerItems = computed<AccountMenuItem[]>(() => accountMenuItems.value.filter((item) => (item.view === currentView.value || item.view === 'default')
        && (isNil(item.conditionals) || item.conditionals)
        && (!item.checkNavOption || hasNavOption(item.type))));

const logoConfig = computed(() => {
  // Default logo settings
  const config = { logo: 'svg/beamery_grow_logo.svg', width: 83, height: 36 };

  const { header = {} } = unref(logoBranding);
  // If has custom branding logo
  if (header?.logo) {
    config.logo = `/client-logos/${header.logo}`;
    // Custom logo dimensions
    if (header?.width && header?.height) {
      config.width = header.width;
      config.height = header.height;
      // Limits to max height
      if (header.height > 48) {
        config.width = (48 / header.height) * header.width;
        config.height = 48;
      }
    }
  }
  return config;
});

const logoColumnStyle = computed<string>(() => {
  const vuetify = useVuetify();
  // Adding custom column width so that the top navigation tabs
  // left align with the main view content at md, lg, and xl breakpoints
  if (vuetify.breakpoint.smAndDown) {
    return '';
  }

  const MAX_CONTAINER_WIDTH = 1185;

  // this.$vuetify.breakpoint.thresholds provides the upper width threshold of
  // each breakpoint, but we want the lower width threshold instead.
  // We can get the lower value by checking the upper threshold of the next smaller breakpoint
  const NEXT_SMALLER_BREAKPOINT = {
    xl: 'lg',
    lg: 'md',
    md: 'sm',
  };

  /* Vuetify takes the breakpoints lower threshold multiplied by 0.9375 to calculate the
     container width for the view. See container-max-widths calculation description here:
     https://vuetifyjs.com/en/customization/sass-variables/ */
  const containerWidth = 0.9375
        * vuetify.breakpoint
          .thresholds[NEXT_SMALLER_BREAKPOINT[vuetify.breakpoint.name]];

  const minWidth = logoConfig.value.width < 95 ? 95 : logoConfig.value.width;

  return `width: calc(((100% - ${containerWidth <= MAX_CONTAINER_WIDTH ? containerWidth : MAX_CONTAINER_WIDTH}px) / 2) - 16px); margin-left:16px; min-width: ${minWidth}px;`;
});
</script>

<template>
  <div>
    <VAppBar
      data-e2e="topNav"
      color="white"
      flat
      app
      class="topnav"
    >
      <div :style="logoColumnStyle">
        <RouterLink
          :to="displayNavigation ? '/' : ''"
          class="topnav__logo"
        >
          <VToolbarTitle class="d-flex flex-nowrap align-center">
            <ImageWrapper
              :src="useImage(logoConfig.logo)"
              :width="logoConfig.width"
              :height="logoConfig.height"
              classes="flex-grow-0 flex-shrink-0"
              styles="z-index: 8;"
              :contain="true"
              e2eTag="fluxLogo"
            />
          </VToolbarTitle>
        </RouterLink>
      </div>
      <VToolbarItems
        v-if="displayNavigation"
      >
        <VTabs
          :data-e2e="`${operationsView ? 'operations-view' : 'talent-view'}`"
          class="topnav__tabs"
          activeClass="active-tab"
          hideSlider
        >
          <TopNavigationTab
            v-for="tab in navTabs"
            :key="tab.title"
            :tab="tab"
            class="topnav__tab hidden-sm-and-down"
          />
        </VTabs>
      </VToolbarItems>
      <VSpacer />
      <CustomerSupport v-if="isCustomerSupportWidgetVisible" />
      <NotificationMenu v-if="displayNavigation" />
      <VToolbarItems>
        <AccountMenu :profileDrawerItems="profileDrawerItems" />
        <VAppBarNavIcon
          class="d-flex d-md-none"
          style="z-index: 8;"
          @click="isHamburgerOpen = !isHamburgerOpen"
        />
      </VToolbarItems>
    </VAppBar>
    <MobileNavigationMenu
      v-if="isHamburgerOpen"
      v-model="isHamburgerOpen"
      :navItems="displayNavigation ? navTabs : []"
      :profileDrawerItems="profileDrawerItems"
    />
  </div>
</template>

<style lang="scss">
  .topnav {
    border-bottom: 1px solid var(--grow-secondary0) !important; // vuetify border color override

    &__tabs {
      border-bottom: 1px solid var(--grow-secondary0) !important;

      & .v-icon {
        font-size: 18px;
        margin-right: 8px;
      }

      & .v-tabs-bar {
        height: 100%;
      }

      & .v-tab {
        text-transform: none;
      }

      & .active-tab {
        border-bottom: 2px solid  var(--grow-primary);
      }
    }
    &__tab {
      height: 100%;
    }
    &__logo {
      cursor: pointer;
    }
  }
</style>
