// Override the SurveyJS class names, and specify our own
// https://surveyjs.io/Examples/Library/?id=survey-customcss&platform=jQuery&theme=default

const surveyClassOverrides = {
  root: 'sv_main surveyJS-root',
  navigationButton: 'v-btn theme--light',
  body: '',
  header: '',
  footer: '',
  completedPage: '',
  navigation: {
    complete: '',
    prev: '',
    next: '',
    start: '',
  },
  progress: '',
  progressBar: '',
  page: {
    root: '',
    title: '',
    description: '',
  },
  pageTitle: 'text-body-1 strong',
  pageDescription: '',
  row: '',
  question: {
    mainRoot: '',
    title: '',
    description: '',
    comment: '',
    required: '',
    titleRequired: '',
    hasError: '',
  },
  panel: {
    title: '',
    description: '',
    container: '',
  },
  error: {
    root: '',
    icon: '',
    item: 'red--text accent3--text',
  },
  boolean: {
    root: '',
    item: '',
    label: '',
    materialDecorator: '',
  },
  checkbox: {
    root: 'surveyJS-checkbox-root',
    item: '',
    itemControl: '',
    controlLabel: '',
    materialDecorator: '',
    other: '',
  },
  comment: '',
  dropdown: {
    root: '',
    control: 'surveyJS-control',
    other: '',
  },
  matrix: {
    root: '',
    label: '',
    cellText: '',
    cellTextSelected: '',
  },
  matrixdropdown: {
    root: '',
  },
  matrixdynamic: {
    root: '',
    button: '',
    buttonAdd: '',
    buttonRemove: '',
  },
  paneldynamic: {
    root: '',
    button: '',
    buttonPrev: '',
    buttonNext: '',
    buttonAdd: '',
    buttonRemove: '',
  },
  multipletext: {
    root: '',
    itemTitle: '',
    itemValue: '',
  },
  radiogroup: {
    root: 'surveyJS-radiogroup-root',
    item: '',
    label: '',
    itemControl: '',
    controlLabel: '',
    materialDecorator: '',
    other: '',
    clearButton: '',
  },
  imagepicker: {
    root: '',
    item: '',
    label: '',
    itemControl: '',
    image: '',
    itemText: '',
    clearButton: '',
  },
  rating: {
    root: 'surveyJS-rating-root',
    item: 'surveyJS-rating-item',
    selected: 'surveyJS-rating-selected',
    minText: '',
    itemText: '',
    maxText: '',
  },
  text: 'surveyJS-text',
  expression: '',
  file: {
    root: '',
    placeholderInput: '',
    preview: '',
    removeButton: '',
    fileInput: '',
    removeFile: '',
  },
  saveData: {
    root: '',
    saving: '',
    error: '',
    success: '',
    saveAgainButton: '',
  },
  window: {
    root: '',
    body: '',
    header: {
      root: '',
      title: '',
      button: '',
      buttonExpanded: '',
      buttonCollapsed: '',
    },
  },
};

export default surveyClassOverrides;
