<template>
  <v-img
    :src="src"
    :height="height"
    :width="width"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :aspect-ratio="aspectRatio"
    :class="classes"
    :style="styles"
    :data-e2e="e2eTag"
    :contain="contain"
  />
</template>

<script>
export default {
  name: 'ImageWrapper', // Image is a reserved component name
  props: {
    src: { type: String, required: true, default: null },
    height: { type: [Number, String], required: false, default: null },
    width: { type: [Number, String], required: false, default: null },
    maxHeight: { type: [Number, String], required: false, default: null },
    maxWidth: { type: [Number, String], required: false, default: null },
    aspectRatio: { type: Number, required: false, default: null },
    classes: { type: String, required: false, default: null },
    styles: { type: String, required: false, default: null },
    e2eTag: { type: String, required: false, default: null },
    contain: { type: Boolean, required: false, default: false },
  },
};
</script>
