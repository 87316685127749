export const initializeStatusPage = () => {
  // Do not turn this on when local debug or run test on CI
  // because this widget may cover other buttons
  if (import.meta.env.VITE_BASE_URL.includes('localhost')) return;
  // See https://help.statuspage.io/help/configure-the-recommended-status-embed
  // Use statusEmbedTest() in dev console to test
  const statusPageScript = document.createElement('script');
  statusPageScript.setAttribute('src', 'https://qzn0t3txt3xy.statuspage.io/embed/script.js');
  document.head.appendChild(statusPageScript);
};
