<!--
Container component that renders a gray border around it's content

The slot allows the parent component to render elements inside this container

Additional class names can be passed through the classNames prop
-->
<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface PropsType {
  customStyle?: boolean
  classNames?: string | null
  width?: number | string | null
  plain?: boolean
}
const props = withDefaults(defineProps<PropsType>(), {
  plain: false,
  classNames: null,
  width: null,
});
const attrs = useAttrs();

const attributes = computed(() => {
  const newAttrs = {
    ...attrs,
    to: attrs.path,
    target: attrs.target, // allows links to be opened in a new window when set to '_blank'
    color: attrs.color || '',
    router: false,
  };

  if (attrs.path) {
    newAttrs.router = true;
    newAttrs.to = attrs.path;
  }

  return newAttrs;
});

const customClasses = computed<string[]>(() => {
  const classes: string[] = [];
  if (attrs.path) classes.push('pointer');
  if (props.customStyle) classes.push('custom-style');
  return classes;
});

const computedClass = computed(() => ['secondary-container', props.classNames ? props.classNames : '', ...customClasses.value]);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <VCard
    v-bind="attributes"
    :class="computedClass"
    :ripple="false"
    :flat="props.customStyle || plain"
    :width="width"
    :outlined="!plain"
  >
    <slot />
  </VCard>
</template>

<style lang="scss" scoped>
  // using the VCard color prop removes the outline
  // set background color with class
  .grey-background {
    background-color: var(--grow-onboarding-grey1);
  }
  .pointer {
    cursor: pointer;
  }
  .custom-style {
      background: var(--grow-site-background);
      color: var(--grow-secondary2);
  }
  .primary-border {
    border: 1px solid var(--grow-primary0);
  }
  // FX-3816 Overrides Vuetify behavior and forces v-card caret to be transparent/hidden.
  .theme--light.v-timeline .v-timeline-item .v-card:not(.v-card--link):before {
    border-right-color: rgba(0,0,0,0);
  }
</style>
