import { VERSION_V1 } from '../config';
// eslint-disable-next-line import/no-cycle
import fmApiClient from '../fmApiClient';

export const updateUserSlackSetting = async (
  userId: number,
  enable,
): Promise<void> => fmApiClient.put(`/${VERSION_V1}/users/${userId}/settings/slack`, { enable });

export const updateUserMSTeamsSetting = async (
  userId: number,
  enable,
): Promise<void> => fmApiClient.put(`/${VERSION_V1}/users/${userId}/settings/msteams`, { enable });

export const updateUserTimeZone = (userId: number, timeZone: string): Promise<{ timeZone: string }> => fmApiClient.put(`${VERSION_V1}/users/${userId}/settings/time-zone`, { timeZone });

export const updateUserRecommendationPreference = (userId: number, newSettings: { criteria?: string, locationFiltering?: string }): Promise<Record<string, { criteria: string, locationFiltering: string }>> => fmApiClient.put(`${VERSION_V1}/users/${userId}/settings/recommendation-preference`, newSettings);
