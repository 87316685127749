import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';

interface SurveyState {
  npsTriggered: boolean,
  npsTriggerEvent: string | undefined,
  latestRunIds: Record<string, string>,
}

export const useSurveysStore = defineStore('surveys', {
  state: (): SurveyState => ({
    // npsTriggered means trying to show or hide NPS
    // Then the NPS Survey component will check local storage timestamp and server
    // to determine if we should actually show NPS survey to the current user
    // If we shouldn't show NPS survey, npsTriggered will be set to false
    // When a NPS survey is completed, npsTriggered will also be set to false
    npsTriggered: false,
    npsTriggerEvent: undefined,
    latestRunIds: {},
  }),
  actions: {
    async fetchSurveys(params: object) {
      return axios.get('/v1/surveys/', { params });
    },
    triggerNpsSurvey(eventName: string) {
      this.setNPSTriggered(true);
      this.npsTriggerEvent = eventName;
    },
    hideNpsSurvey() {
      this.setNPSTriggered(false);
    },
    updateSurveyRunId({ identifier, runId }: { identifier: string, runId: string }) {
      this.latestRunIds[identifier] = runId;
    },
    setNPSTriggered(isTriggered: boolean) {
      this.npsTriggered = isTriggered;
    },
    removeSurveyRunId(runId: string) {
      delete this.latestRunIds[runId];
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSurveysStore, import.meta.hot));
}
