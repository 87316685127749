/* eslint-disable import/no-cycle */
import fmApiClient from '@/services/fmApiClient';
import type { TenureExtents } from '@/types/company';
import { VERSION_V1 } from '../config';

export const getTenureExtents = async (
  companyId: number,
): Promise<TenureExtents> => fmApiClient.get(`${VERSION_V1}/companies/${companyId}/tenure-extents`);

export * from './group';
export * from './location';
export * from './role';
