/* eslint-disable import/no-cycle */
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Skill } from '@/types/skill';
import { partition } from 'lodash-es';
import { useCompanyStore } from './company';

interface SkillsState {
  genericSkills: Skill[],
  languageSkills: Skill[],
  promotedSkills: Skill[],
  draftInferredSkills: Skill[],
}

const getDefaultState = (): SkillsState => (
  {
    genericSkills: [],
    languageSkills: [],
    promotedSkills: [],
    draftInferredSkills: [],
  }
);

export const useSkillsStore = defineStore('skills', {
  state: (): SkillsState => getDefaultState(),
  actions: {
    setAllSkills(skills) {
      const companyStore = useCompanyStore();
      const isPromotedSkillSwitchOn = companyStore.displayPromotedSkills;
      this.setAllSkillCategories(skills, isPromotedSkillSwitchOn);
    },
    setAllSkillCategories(skills, isPromotedSkillSwitchOn) {
      // First partition and then set the skills
      const partitionedSkills = partition(skills, (s: Skill) => s.userSkillState === 'draft' && s.userSkillSource === 'inferred');
      [this.draftInferredSkills] = partitionedSkills;
      const allOtherSkills = partitionedSkills[1];

      if (isPromotedSkillSwitchOn) {
        // isolate promoted skills so that they are not duplicated
        this.genericSkills = allOtherSkills.filter((skill) => skill.category === 'skill' && !skill.isPromoted);
        this.languageSkills = allOtherSkills.filter((skill) => skill.category === 'language' && !skill.isPromoted);
        this.promotedSkills = allOtherSkills.filter((skill) => skill.isPromoted);
      } else {
        this.genericSkills = allOtherSkills.filter((skill) => skill.category === 'skill');
        this.languageSkills = allOtherSkills.filter((skill) => skill.category === 'language');
      }
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSkillsStore, import.meta.hot));
}
