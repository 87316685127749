/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import {
  has,
  pick,
  uniqBy,
  isNil,
  cloneDeep,
  toString,
  concat,
  merge,
  partition,
} from 'lodash-es';
import * as Sentry from '@sentry/browser';
import * as UserService from '@/services/users';
import type {
  RecommendationPreference, User, UserCurrentView, UserSkill,
} from '@/types/user';
import type { Requisition } from '@/types/requisition';
import type { Education } from '@/types/education';
import type { Application } from '@/types/application';
import type { FeedItem } from '@/types/feedItem';
import type { Notification } from '@/types/notification';
import type { Skill } from '@/types/skill';
import type { Experience } from '@/types/experience';
import type { Interest } from '@/types/interest';
import type { Role } from '@/types/role';
import { getUserMentorshipSettings, getUserMentorshipsWithUser, getUserMentorships } from '@/services/users/mentorship';
import { useAnalyticsStore } from './analytics';
import { useCompanyStore } from './company';
import { useGlobalStore } from './global';
import { useAuthStore } from './auth';

// array of valid profile views
const PROFILE_VIEWS = ['talent', 'operations'];

interface OptedInAsMentee { id: number | undefined }
interface OptedInAsMentor {
  id: number | undefined,
  skills: Skill[],
  roles: Role[],
}

interface UserState {
  invalidUser: boolean,
  isUserLoaded: boolean,
  currentView: UserCurrentView | undefined,
  user: User,
  userRole: Partial<Role>,
  userExperiences: Experience[],
  userSkills: Skill[], // Array of Skill objects, not UserSkill objects
  usersGenericSkills: Skill[], // category = skill
  usersLanguageSkills: Skill[], // category = language
  usersPromotedSkills: Skill[], // isPromoted = true
  usersDraftInferredSkills: Skill[],
  userSubApplications: Application[],
  userEducations: Education[],
  talentFeedItems: FeedItem[],
  talentNotifications: Notification[],
  talentNotificationsTotalResults: number,
  operationsFeedItems: FeedItem[],
  operationsNotifications: Notification[],
  operationsNotificationsTotalResults: number,
  userMatchedOpportunities: undefined | Requisition[],
  userMatchedOpportunitiesTotalResults: number,
  subordinateSearchParams: object,
  subordinateSearchResults: {
    companyId: number,
    companyTenureDays: number,
    email: string,
    experiences: object[],
    firstName: string,
    hireDate: string,
    id: number,
    location: object,
    name: string,
    requireApplicationApprovalFor: object,
  }[],
  subordinateSearchTotalResults: number,
  userInterests: Interest[],
  userInterestsTotalResults: number,
  talentUserInterests: Interest[],
  talentUserInterestsTotalResults: number,
  mentorshipSettings: {
    optedInAsMentee: OptedInAsMentee | null,
    optedInAsMentor: OptedInAsMentor | null,
  },
  userMentorships: Record<string | number, {
    mentorToUsers: object[], // userId and notificationLastSentAt
    mentoredByUsers: object[], // same as above
  }>,
  userMentorshipMatches: User[],
  dataLoaded: Record<string, boolean>
}

const getDefaultState = (): UserState => (
  {
    invalidUser: false,
    isUserLoaded: false,
    currentView: undefined,
    user: {},
    userRole: {},
    userExperiences: [],
    userSkills: [], // Array of Skill objects, not UserSkill objects
    usersGenericSkills: [],
    usersLanguageSkills: [],
    usersPromotedSkills: [],
    usersDraftInferredSkills: [],
    userSubApplications: [],
    userEducations: [],
    talentFeedItems: [],
    talentNotifications: [],
    talentNotificationsTotalResults: 0,
    operationsFeedItems: [],
    operationsNotifications: [],
    operationsNotificationsTotalResults: 0,
    userMatchedOpportunities: undefined,
    userMatchedOpportunitiesTotalResults: 0,
    subordinateSearchParams: {},
    subordinateSearchResults: [],
    subordinateSearchTotalResults: 0,
    userInterests: [],
    userInterestsTotalResults: 0,
    talentUserInterests: [],
    talentUserInterestsTotalResults: 0,
    mentorshipSettings: {
      optedInAsMentee: null,
      optedInAsMentor: null,
    },
    userMentorships: {},
    userMentorshipMatches: [],
    dataLoaded: {
      userMentorship: false,
    },
  }
);

const FEED_ITEM_TYPE_FILTER_MAP = {
  survey(item, data) {
    return item.content.type === 'survey'
      && toString(item.content.config.id) === toString(data.surveyId);
  },
  experiences(item) {
    return item.content.type === 'action'
      && item.content.config.category === 'experiences';
  },
};

// For retrieving mentorship and mentorship_opt_in data, we want to define how many entities to load at a time
// For ease of use, we'll define it here and use it globally for all related methods until we see it needs tweaked
const numberOfMentorshipAndRelatedEntitiesToRequest = 5;

export const useUserStore = defineStore('user', {
  state: (): UserState => getDefaultState(),
  getters: {
    userId: (state) => {
      const authStore = useAuthStore();
      return state.user?.id || Number(authStore.fluxUserId);
    },
    userPrimaryExperience: (state) => (state.userExperiences.filter((e) => e.isPrimary))[0],
    hasDraftExperiences: (state) => state.userExperiences.some((experience) => experience.state === 'draft'),
    hasDraftUserSkills: (state) => state.user?.userSkills?.some((userSkill) => userSkill.state === 'draft'),
    hasDraftEducations: (state) => state.userEducations?.some((education) => education.state === 'draft'),
    hasExistingEnrichmentData: (state) => {
      // Loading current user + educations required
      const hasNonIngestExps = state.userExperiences.some((exp) => exp.provider !== 'ingest');
      const hasEducations = state.userEducations.length > 0;
      return hasNonIngestExps || hasEducations;
    },
    userQualitiesFeatures: (state) => state.user?.features?.filter((feature) => feature.type === 'qualities'),
    isUserOnboarded: (state) => !isNil(state.user.onboardedAt),
    userCompanyName: (state) => state.user?.company?.name,
    userCompanyId: (state) => {
      const authStore = useAuthStore();
      return state.user?.company?.id || authStore.fluxCompanyId;
    },
    isUserCompanyClient: (state) => state.user?.company?.isClient,
    userPrimaryGroup: (state) => state.user?.groups?.find((group) => group.isPrimary),
    isCurrentUserOptedInAsMentor: (state) => !isNil(state.mentorshipSettings?.optedInAsMentor),
    isCurrentUserOptedInAsMentee: (state) => !isNil(state.mentorshipSettings?.optedInAsMentee),
    doNotAskOpportunityDismissalReasons: (state) => state
      .user?.settings?.userAccessible?.doNotAskOpportunityDismissalReasons ?? false,
    recommendationPreference: (state): RecommendationPreference => (state.user?.settings?.recommendationPreference
      ?? state.user?.company?.settings.recommendationPreference) as RecommendationPreference,
  },
  actions: {
    async loadCurrentUser() {
      Vue.prototype.$log.debug('loadCurrentUser', this.userId);
      const authStore = useAuthStore();
      if (!this.userId) {
        /* This case can happen when OneLogin integration is enabled, and if a user is mistakenly
          authorized to use Flux via OneLogin, but hasn't been ingested into Flux system. */
        this.invalidUser = true;
        throw new Error(`Current user has no user id for loading user: ${authStore.idTokenPayload.email}`);
      }

      // get user
      const eagerLoad = {
        eagerLoad: ['experiences', 'skills', 'features', 'location', 'groups', 'programs', 'leadsGroups'],
      };
      const userData = await UserService.getUserData(this.userId, { params: eagerLoad });

      this.user = userData;

      // load user mentorship_opt_in data
      await Promise.all([
        this.loadUserMentorshipSettings(),
        this.loadUserInterests(),
      ]);

      // Capture user in Sentry
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: toString(this.userId),
        });
      });

      const companyStore = useCompanyStore();
      companyStore.setCompanySettingsFromCurrentUser(userData.company.settings || {});

      const skills = userData.userSkills.map((userSkill: UserSkill) => {
        const returnObj = {
          ...userSkill.skill,
          userSkillSource: userSkill.source,
          userSkillState: userSkill.state,
        };
        if (has(userSkill, 'requisitionHas')) {
          returnObj.requisitionHas = userSkill.requisitionHas;
        }
        return returnObj;
      });
      const isPromotedSkillSwitchOn = companyStore.displayPromotedSkills;
      this.userSkills = skills;
      // First partition and then set the skills
      const partitionedSkills = partition(skills, (s) => s.userSkillState === 'draft' && s.userSkillSource === 'inferred');
      [this.usersDraftInferredSkills] = partitionedSkills;
      const allOtherSkills = partitionedSkills[1];

      if (isPromotedSkillSwitchOn) {
        // isolate promoted skills so that they are not duplicated
        this.usersGenericSkills = allOtherSkills.filter((skill) => skill.category === 'skill' && !skill.isPromoted);
        this.usersLanguageSkills = allOtherSkills.filter((skill) => skill.category === 'language' && !skill.isPromoted);
        this.usersPromotedSkills = allOtherSkills.filter((skill) => skill.isPromoted);
      } else {
        this.usersGenericSkills = allOtherSkills.filter((skill) => skill.category === 'skill');
        this.usersLanguageSkills = allOtherSkills.filter((skill) => skill.category === 'language');
      }

      this.userExperiences = userData.experiences;
      const primaryExperience = userData.experiences.find((exp) => exp.isPrimary);
      if (primaryExperience && primaryExperience.role) {
        this.userRole = primaryExperience.role;
      }
      this.isUserLoaded = true;
    },
    clearCurrentUser() {
      Vue.prototype.$log.debug('clearCurrentUser');
      Object.assign(this, getDefaultState());
    },
    async patchCurrentUser({ params }) {
      const eagerLoad = [];
      const newUserData = await UserService.updateUser(this.userId, { ...eagerLoad, ...params });
      this.user = { ...this.user, ...newUserData };
    },
    async patchHasAcceptedPrivacyPolicy() {
      const analyticsStore = useAnalyticsStore();
      const newUserData = await UserService.updateUser(
        this.userId,
        { hasAcceptedPrivacyPolicy: true },
      );
      this.user.hasAcceptedPrivacyPolicy = newUserData.hasAcceptedPrivacyPolicy;
      // Identify the analytics user again so hasAcceptedPrivacyPolicy is set in segment
      analyticsStore.identifyAnalyticsUser();
      analyticsStore.trackEvent({ eventName: 'Privacy Policy Accepted' });
    },
    async detectAndSaveTimeZone() {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      // If detected timezone is different than saved, save it
      if (timeZone && timeZone !== this.user.settings?.userAccessible?.timeZone) {
        await UserService.updateUserTimeZone(this.userId, timeZone);
      }
    },
    toggleProfile(profileView) {
      Vue.prototype.$log.debug('Setting current view to', profileView);
      if (PROFILE_VIEWS.includes(profileView)) {
        this.currentView = profileView;
      } else {
        this.currentView = undefined;
        throw new Error('Invalid profile view');
      }
    },
    async loadUserSubApplications({ userId }) {
      // get applications that include attribute excludeFromProfile
      const data = await UserService.getUserApplications(
        userId,
        {
          sort: ['created_at'],
          states: ['completed', 'accepted'],
          excludeRequisitionTypes: ['full_time'],
          eagerLoad: ['requisition'],

        },
      );
      this.userSubApplications = data.results;
    },
    // skills
    async getUserSkills({ id }) {
      Vue.prototype.$log.info(`getUserSkills for userId ${this.userId}`);
      return UserService.getUserSkills(id);
    },
    async patchCurrentUserSkills({ skills }) {
      const analyticsStore = useAnalyticsStore();
      const responseData = await UserService.updateUserSkills(this.userId, skills);
      const updatedSkills = responseData.map((userSkill) => userSkill.skill);
      const companyStore = useCompanyStore();
      const isPromotedSkillSwitchOn = companyStore.displayPromotedSkills;
      this.userSkills = skills;
      if (isPromotedSkillSwitchOn) {
        // isolate promoted skills so that they are not duplicated
        this.usersGenericSkills = updatedSkills
          .filter((skill) => skill.category === 'skill' && !skill.isPromoted);
        this.usersLanguageSkills = updatedSkills
          .filter((skill) => skill.category === 'language' && !skill.isPromoted);
        this.usersPromotedSkills = updatedSkills
          .filter((skill) => skill.isPromoted);
      } else {
        this.usersGenericSkills = updatedSkills
          .filter((skill) => skill.category === 'skill');
        this.usersLanguageSkills = updatedSkills
          .filter((skill) => skill.category === 'language');
      }
      // Track 'Skills Updated' event
      analyticsStore.trackEvent({ eventName: 'User Skills Updated' });
    },
    async postCurrentUserSkills({ skillId, source }) {
      const analyticsStore = useAnalyticsStore();
      await axios.post(
        `/v1/users/${this.userId}/skills`,
        {
          skillId,
          state: 'confirmed',
          source,
        },
      );
      // Track 'Skills Updated' event
      analyticsStore.trackEvent({ eventName: 'User Skill Added' });
    },
    // experiences
    async loadCurrentUserExperiences() {
      const { data } = await axios.get(`/v1/users/${this.userId}/experiences`);
      this.userExperiences = data;
    },
    async postNewExperience({ data }) {
      await axios.post(
        `/v1/users/${this.userId}/experiences/`,
        data,
      );
      await this.loadCurrentUserExperiences();
    },
    async patchExperience({ data, experienceId }) {
      // we removed await this.loadCurrentUserExperiences(); from this method
      // and call it in the components it is used to prevent a race condition
      const analyticsStore = useAnalyticsStore();
      const response = await axios.patch(
        `/v1/users/${this.userId}/experiences/${experienceId}`,
        data,
      );
      // Track 'Experience Modified' event
      const properties = {
        ...(response.data.id && { experienceId: response.data.id }),
        ...(response.data.title && { experienceTitle: response.data.title }),
        ...(response.data.company && { experienceCompany: response.data.company }),
      };
      analyticsStore.trackEvent({ eventName: 'Experience Modified', propertiesObj: properties });
    },
    async confirmExperiences(data) {
      const analyticsStore = useAnalyticsStore();
      await axios.patch(
        `/v1/users/${this.userId}/experiences`,
        data,
      );
      await this.loadCurrentUserExperiences();
      // Track 'Experiences Confirmed' event
      analyticsStore.trackEvent({ eventName: 'Experiences Confirmed' });
    },
    async removeExperience(experienceId) {
      const analyticsStore = useAnalyticsStore();
      // flag the experience as 'rejected'
      const data = { state: 'rejected' };
      const response = await axios.patch(
        `/v1/users/${this.userId}/experiences/${experienceId}`,
        data,
      );
      // remove deleted experience
      const experiences = JSON.parse(JSON.stringify(this.userExperiences));
      const updatedExperiences = experiences.filter((exp) => exp.id !== experienceId);
      this.userExperiences = updatedExperiences;
      // Track 'Experience Deleted' event
      const properties = {
        ...(response.data.id && { experienceId: response.data.id }),
        ...(response.data.title && { experienceTitle: response.data.title }),
        ...(response.data.company && { experienceCompany: response.data.company }),
      };
      analyticsStore.trackEvent({ eventName: 'Experience Deleted', propertiesObj: properties });
    },
    async getUserPrimaryExperienceRole({ companyId, roleId }) {
      Vue.prototype.$log.info(`getUserPrimaryExperienceRole for userId ${this.userId}`);
      return axios.get(`/v1/companies/${companyId}/roles/${roleId}`);
    },
    // educations
    async loadCurrentUserEducations() {
      const data = await UserService.getUserEducations(this.userId);
      this.userEducations = data;
    },
    // patch a single education record
    async patchEducation(params) {
      const patchData = pick(params, [
        'state', 'school', 'fieldOfStudy', 'degree', 'startMonth', 'startYear', 'endMonth', 'endYear',
      ]);
      await UserService.patchUserEducationById(params.id, this.userId, patchData);
      // once the education is patched, reload user educations
      await this.loadCurrentUserEducations();
    },
    // batch patch multiple education records
    async patchEducations(params: Education[]) {
      // the API currently limits batch patching to the state field only
      const patchData: Pick<Education, 'state' | 'id'>[] = params.map((e) => pick(e, ['state', 'id']));
      await UserService.patchUserEducations(this.userId, patchData);
      // once the educations are patched, reload user educations
      await this.loadCurrentUserEducations();
    },
    // feeds and notifications
    async loadCurrentUserFeed() {
      Vue.prototype.$log.debug('loadCurrentUserFeed', this.userId);
      const { data } = await axios.get(`/v1/users/${this.userId}/feed`, {
        params: {
          sort: ['-id'],
          states: ['unread', 'read'],
          limit: 100,
          offset: 0,
        },
      });
      this.talentFeedItems = data.results;
    },
    async loadOperationsFeed() {
      const { data } = await axios.get(
        `/v1/users/${this.userId}/feed`,
        {
          params: {
            sort: ['id'],
            states: ['unread', 'read'],
            userView: 'operations',
            limit: 100,
            offset: 0,
          },
        },
      );
      // all operation feed items
      this.operationsFeedItems = data.results;
    },
    async loadCurrentUserNotificationFeed({ userView = 'talent', params }) {
      const { data } = await axios.get(
        `/v1/users/${this.userId}/feed`,
        {
          params: {
            ...params,
            sort: ['-id'],
            states: ['unread', 'read', 'finished', 'dismissed'],
            userView,
            type: 'notification',
          },
        },
      );

      const feedItems = uniqBy([
        ...data.results,
        ...this[userView === 'operations' ? 'operationsNotifications' : 'talentNotifications'],
      ], 'id').sort((a, b) => (a.id > b.id ? -1 : 1));

      if (userView === 'operations') {
        this.operationsNotifications = feedItems;
        this.operationsNotificationsTotalResults = data.totalResults;
      } else {
        this.talentNotifications = feedItems;
        this.talentNotificationsTotalResults = data.totalResults;
      }
    },
    async completeFeedItem({ type, data }) {
      let feedItemId;
      let state;
      if (data.feedItemId) {
        ({ feedItemId } = data);
        // note here that state is local to this function
        state = data.state === undefined ? 'dismissed' : data.state;
      } else {
        if (this.currentView !== 'operations') await this.loadCurrentUserFeed();
        // check to see if a related feed item exists for the user
        const feedItems = this.currentView === 'operations'
          ? this.operationsFeedItems
          : this.talentFeedItems
            .filter((item) => FEED_ITEM_TYPE_FILTER_MAP[type](item, data));
        if (feedItems[0]) {
          feedItemId = feedItems[0].id;
          state = 'finished';
        }
      }
      // updated feed item state
      if (feedItemId) {
        await axios.patch(
          `/v1/users/${this.userId}/feed/${feedItemId}`,
          { state: `${state}` },
        );
        // the completion of a feed item can trigger the creation or deletion of other feed items
        // so reload all feed items
        if (this.currentView === 'operations') {
          await this.loadOperationsFeed();
        } else if (this.currentView === 'talent') {
          await this.loadCurrentUserFeed();
        }
      }
    },
    async completeFeedItems({ feedItems }) {
      if (feedItems) {
        // batch patch feed items
        const updatedFeedItems = feedItems.map((item) => ({
          ...item,
          state: 'dismissed',
        }));
        await axios.patch(`/v1/users/${this.userId}/feed`, { feedItems: updatedFeedItems });
        // alike completeFeedItem, the completion of feed items can trigger the
        // creation or deletion of other feed items, so reload all feed items
        if (this.currentView === 'operations') {
          await this.loadOperationsFeed();
        } else if (this.currentView === 'talent') {
          await this.loadCurrentUserFeed();
        }
      }
    },
    // recommendations and matches
    async getRecommendedRoles(experiences) {
      const { data } = await axios.post(
        `/v1/users/${this.userId}/experiences/matched-roles`,
        { experiences },
      );
      return data;
    },
    async getRecommendedJARoles(experiences) {
      const { data } = await axios.post(
        `/v1/users/${this.userId}/experiences/matched-ja-roles`,
        { experiences },
      );
      return data;
    },
    resetUserMatchedOpportunities() {
      this.userMatchedOpportunities = undefined;
      this.userMatchedOpportunitiesTotalResults = 0;
    },
    async loadUserMatchedOpportunities({ params }) {
      //  Including default options to ensure that it functions as old behavior (all matched)
      const options = merge({}, {
        narrowCriteria: false,
        locationFiltering: 'anywhere',
      }, params);

      const data = await UserService.getUserMatchedOpportunities(this.userId, options);
      // if loading more, and userMatchedOpportunities is not undefined, combine with already loaded matched opportunities
      // we don't want to change the order of userMatchedOpportunities, and append the new ones to the end.
      // 1. if the new object (id) already exists in the userMatchedOpportunities, we replace it in place;
      // 2. else, we add the new opportunity to the end of the array.
      const matchedOpportunities = cloneDeep(this.userMatchedOpportunities) || [];
      data.results.forEach((newOp) => {
        const index = matchedOpportunities.findIndex((curOp) => curOp.id === newOp.id);
        if (index > -1) {
          matchedOpportunities[index] = newOp;
        } else {
          matchedOpportunities.push(newOp);
        }
      });
      this.userMatchedOpportunities = matchedOpportunities;
      this.userMatchedOpportunitiesTotalResults = data.totalResults;
    },
    async recommendToUsers(payload) {
      const globalStore = useGlobalStore();
      const { data } = await axios.post(
        '/v1/recommendations',
        payload,
      );
      globalStore.showSnackbar({
        message: `${isNil(payload.programId) ? 'Opportunity' : 'Program'} suggested!`,
        icon: 'check_circle',
      });
      return data;
    },
    async dismissMatchedUser(data) {
      const globalStore = useGlobalStore();
      const params = {
        ...data,
        dismissedUserIds: [data.userId],
      };

      const response = await axios.post(
        `/v1/companies/${this.userCompanyId}/requisitions/${data.requisitionId}/matched-user-dismissals`,
        params,
      );
      globalStore.showSnackbar({
        message: 'User hidden from suggestions',
        icon: 'check_circle',
      });
      return response;
    },
    // subordinates
    async searchSubordinates(params) {
      this.subordinateSearchParams = params;
      const { data } = await axios.get(
        `/v1/users/${this.userId}/subordinates`,
        { params },
      );
      // async API calls might not return in same order they are called,
      // so compare search parameters to latest and ignore response if out of date
      if (params === this.subordinateSearchParams) {
        this.subordinateSearchResults = data.results;
        this.subordinateSearchTotalResults = data.totalResults;
      }
    },
    async patchSubordinate({ params, userId }) {
      const updatedSettings = await UserService.patchSubordinate(userId, params);
      const updatedSubordinateSearchResults = this.subordinateSearchResults
        .map((subordinate) => {
          const user = cloneDeep(subordinate);
          if (user.id === userId) {
            Object.assign(user, updatedSettings);
          }
          return user;
        });
      this.subordinateSearchResults = updatedSubordinateSearchResults;
    },
    /**
     * Method for loading ALL of a user's interests (pagination not supported).
     * Limit param is accessible, but will still load all of a user's interests in chunks of the limit.
     */
    async loadUserInterests(params = { sort: ['-createdAt'], limit: 100 }) {
      let tempUserInterests = [];

      const { data: initialInterestsData } = await axios.get(`/v1/users/${this.userId}/interests`, { params });
      this.userInterestsTotalResults = initialInterestsData.totalResults;

      // if user interests <= limit, only call API once
      if (initialInterestsData.totalResults <= params.limit) this.userInterests = initialInterestsData.results;

      if (initialInterestsData.totalResults > params.limit) {
        tempUserInterests = initialInterestsData.results;

        // Generate array of required offsets
        const totalOffsets = Math.ceil(initialInterestsData.totalResults / params.limit) - 1;
        const offsetArray: number[] = [];
        for (let x = 1; x <= totalOffsets; x += 1) {
          offsetArray.push(x * params.limit);
        }
        // Call api additional times with all offsets then merge all arrays together
        const additionalInterests = await Promise.all(
          offsetArray.map((offset) => axios.get(`/v1/users/${this.userId}/interests`, { params: { ...params, offset } })),
        );
        additionalInterests.forEach((interestsArr) => {
          tempUserInterests = tempUserInterests.concat(interestsArr.data.results);
        });
        // Set userInterests
        this.userInterests = tempUserInterests;
      }
    },
    async submitUserInterest({ params }) {
      const { data } = await axios.put(
        `/v1/users/${this.userId}/interests`,
        params,
      );
      this.userInterests = [...this.userInterests, data];
      this.userInterestsTotalResults += 1;
    },
    async deleteUserInterest({ interestId }) {
      await axios.delete(`/v1/users/${this.userId}/interests/${interestId}`);
      this.userInterests = this.userInterests.filter((interest) => interest.id !== interestId);
      this.userInterestsTotalResults -= 1;
    },
    async loadTalentUserInterests(userId) {
      this.clearTalentUserInterests();
      const { data } = await axios.get(`/v1/users/${userId}/interests`);
      this.talentUserInterests = data.results;
      this.talentUserInterestsTotalResults = data.totalResults;
    },
    clearTalentUserInterests() {
      this.talentUserInterests = [];
      this.talentUserInterestsTotalResults = 0;
    },
    async loadUserMentorshipMatches() {
      const { data } = await axios.get(
        `/v1/users/${this.userId}/matched-mentorship`,
      );
      this.userMentorshipMatches = data;
    },
    async loadAdditionalMentoringRoles(after) {
      const query = `{
        company(id: ${this.userCompanyId}) {
          user(id: ${this.userId}) {
            settings {
              mentorship {
                optedInAsMentor {
                  roles(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}, after: "${after}") {
                    pageInfo { hasNextPage, endCursor }
                    nodes {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }`;
      const { data: res } = await axios.post('/graphql', { query });

      // should not be null since this is a pagination method, but TS will throw error if not checked
      if (this.mentorshipSettings.optedInAsMentor) {
        this.mentorshipSettings.optedInAsMentor.roles = uniqBy(concat(
          this.mentorshipSettings.optedInAsMentor.roles,
          res.data.company.user?.settings?.mentorship?.optedInAsMentor?.roles?.nodes || [],
        ), 'id');
      }

      if (res.data.company.user?.settings?.mentorship?.optedInAsMentor?.roles?.pageInfo?.hasNextPage) {
        await this.loadAdditionalMentoringRoles(
          res.data.company.user.settings.mentorship.optedInAsMentor.roles.pageInfo.endCursor,
        );
      }
    },
    async loadAdditionalMentoringSkills(after) {
      const query = `{
        company(id: ${this.userCompanyId}) {
          user(id: ${this.userId}) {
            settings {
              mentorship {
                optedInAsMentor {
                  skills(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}, after: "${after}") {
                    pageInfo { hasNextPage, endCursor }
                    nodes {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }`;
      // header value used in E2E tests
      const { data: res } = await axios.post('/graphql', { query }, { headers: { id: 'loadAdditionalMentorshipSkills' } });

      if (this.mentorshipSettings.optedInAsMentor) {
        this.mentorshipSettings.optedInAsMentor.skills = uniqBy(concat(
          this.mentorshipSettings.optedInAsMentor.skills,
          res.data.company.user?.settings?.mentorship?.optedInAsMentor?.skills?.nodes || [],
        ), 'id');
      }

      if (res.data.company.user?.settings?.mentorship?.optedInAsMentor?.skills?.pageInfo?.hasNextPage) {
        await this.loadAdditionalMentoringSkills(
          res.data.company.user.settings.mentorship.optedInAsMentor.skills.pageInfo.endCursor,
        );
      }
    },
    // Load user mentorship with target user, it only loaded first `numberOfMentorshipAndRelatedEntitiesToRequest` mentorships
    // NOTE: add load addition function as needed.
    async loadUserMentorshipsWithUser(targetUserId, { withMentorTo = true, withMentoredBy = true } = {}) {
      const res = await getUserMentorshipsWithUser(
        this.userId,
        this.userCompanyId,
        targetUserId,
        { mentorTo: withMentorTo, mentoredBy: withMentoredBy },
      );

      const { mentorTo, mentoredBy } = res.data.company.user;

      // initial data if not exist
      if (!this.userMentorships[targetUserId]) {
        this.userMentorships = {
          ...this.userMentorships,
          [targetUserId]: ({
            mentorToUsers: [],
            mentoredByUsers: [],
          }),
        };
      }

      // save to data cache
      if (withMentorTo && mentorTo) {
        this.userMentorships[targetUserId].mentorToUsers = mentorTo.nodes
          .map((n) => ({ userId: n.mentee.id, notificationLastSentAt: n?.notificationLastSentAt }));
      }

      if (withMentoredBy && mentoredBy) {
        this.userMentorships[targetUserId].mentoredByUsers = mentoredBy.nodes
          .map((n) => ({ userId: n.mentor.id, notificationLastSentAt: n?.notificationLastSentAt }));
      }
    },
    async loadAdditionalUserMentorships(mentorToAfter: string | null, mentoredByAfter: string | null) {
      const res = await getUserMentorships(this.userId, this.userCompanyId, {
        mentoredByConfig: {
          hasNext: mentoredByAfter !== null,
          after: mentoredByAfter,
        },
        mentorToConfig: {
          hasNext: mentorToAfter !== null,
          after: mentorToAfter,
        },
      });
      const { mentoredBy, mentorTo } = res.data.company.user;

      mentoredBy?.nodes.forEach((u) => {
        if (!this.userMentorships[u.mentor.id]?.mentoredByUsers) {
          this.userMentorships = {
            ...this.userMentorships,
            [u.mentor.id]: {
              mentorToUsers: [],
              mentoredByUsers: [],
            },
          };
        }
        this.userMentorships[u.mentor.id].mentoredByUsers
          .push({ userId: u.mentor.id, notificationLastSentAt: u.notificationLastSentAt });
      });

      mentorTo?.nodes.forEach((u) => {
        if (!this.userMentorships[u.mentee.id]?.mentorToUsers) {
          this.userMentorships = {
            ...this.userMentorships,
            [u.mentee.id]: {
              mentorToUsers: [],
              mentoredByUsers: [],
            },
          };
        }

        this.userMentorships[u.mentee.id].mentorToUsers
          .push({ userId: u.mentee.id, notificationLastSentAt: u.notificationLastSentAt });
      });

      const hasNext = mentoredBy?.pageInfo?.hasNextPage || mentorTo?.pageInfo?.hasNextPage;

      if (hasNext) {
        await this.loadAdditionalUserMentorships(
          mentoredBy?.pageInfo?.hasNextPage
            ? res.data.company.user.mentoredBy.pageInfo.endCursor : null,
          mentorTo?.pageInfo?.hasNextPage
            ? res.data.company.user.mentorTo.pageInfo.endCursor : null,
        );
      }
    },
    async loadUserMentorships() {
      const res = await getUserMentorships(
        this.userId,
        this.userCompanyId,
        {},
      );

      const { mentorTo, mentoredBy } = res.data.company.user;

      (mentorTo?.nodes ?? []).forEach((n) => {
        const menteeId = n.mentee.id;
        if (!this.userMentorships[menteeId]?.mentorToUsers) {
          this.userMentorships = {
            ...this.userMentorships,
            [menteeId]: {
              mentoredByUsers: [],
              mentorToUsers: [],
            },
          };
        }

        this.userMentorships[menteeId].mentorToUsers
          .push({ userId: menteeId, notificationLastSentAt: n?.notificationLastSentAt });
      });

      (mentoredBy?.nodes ?? []).forEach((n) => {
        const mentorId = n.mentor.id;
        if (!this.userMentorships[mentorId]?.mentoredByUsers) {
          this.userMentorships = {
            ...this.userMentorships,
            [mentorId]: {
              mentoredByUsers: [],
              mentorToUsers: [],
            },
          };
        }

        this.userMentorships[mentorId].mentoredByUsers
          .push({ userId: mentorId, notificationLastSentAt: n?.notificationLastSentAt });
      });

      // handle pagination of mentorTo and mentorBy users
      if (res.data.company.user?.mentorTo?.pageInfo?.hasNextPage
        || res.data.company.user?.mentoredBy?.pageInfo?.hasNextPage) {
        await this.loadAdditionalUserMentorships(
          res.data.company.user?.mentorTo?.pageInfo?.hasNextPage
          && res.data.company.user.mentorTo.pageInfo.endCursor,
          res.data.company.user?.mentoredBy?.pageInfo?.hasNextPage
          && res.data.company.user.mentoredBy.pageInfo.endCursor,
        );
      }
    },
    async loadUserMentorshipSettings() {
      // header value used in E2E tests
      const res = await getUserMentorshipSettings(this.userId, this.userCompanyId, { headers: { id: 'loadUserMentorshipSettings' } });
      if (res.data.company.user.settings?.mentorship?.optedInAsMentee) {
        this.mentorshipSettings.optedInAsMentee = res.data.company.user.settings.mentorship.optedInAsMentee;
      } else {
        this.mentorshipSettings.optedInAsMentee = null;
      }
      if (res.data.company.user.settings?.mentorship?.optedInAsMentor) {
        this.mentorshipSettings.optedInAsMentor = {
          id: res.data.company.user.settings.mentorship.optedInAsMentor.id,
          roles: res.data.company.user.settings.mentorship.optedInAsMentor.roles?.nodes,
          skills: res.data.company.user.settings.mentorship.optedInAsMentor.skills?.nodes,
        };
      } else {
        this.mentorshipSettings.optedInAsMentor = null;
      }

      // handle pagination of mentorship_opt_in_roles/skills
      const loadPromises: Promise<void>[] = [];
      if (res.data.company.user.settings?.mentorship?.optedInAsMentor?.skills?.pageInfo?.hasNextPage) {
        loadPromises.push(this.loadAdditionalMentoringSkills(
          res.data.company.user.settings.mentorship.optedInAsMentor.skills.pageInfo.endCursor,
        ));
      }
      if (res.data.company.user.settings?.mentorship?.optedInAsMentor?.roles?.pageInfo?.hasNextPage) {
        loadPromises.push(this.loadAdditionalMentoringRoles(
          res.data.company.user.settings.mentorship.optedInAsMentor.roles.pageInfo.endCursor,
        ));
      }
      await Promise.all(loadPromises);
    },
    async optInToMentorshipAsMentee() {
      await axios.post('/graphql', {
        query: `mutation {
          optInAsMentee(data: {}) {
            isMentee
          }
        }`,
      });
      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      return this.loadUserMentorshipSettings();
    },
    async optInToMentorshipAsMentor({ roleIds, skillIds }) {
      const query = `mutation {
        optInAsMentor(
          data: {
              roleIds: [${roleIds.join()}],
              skillIds: [${skillIds.join()}],
            }
          )
          { isMentor }
      }`;

      await axios.post('/graphql', { query });
      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      return this.loadUserMentorshipSettings();
    },
    async optOutOfMentorship({ mentorshipOptInId }) {
      await axios.post('/graphql', {
        query: `mutation {
          optOutOfMentorship(
            data: {
              id: ${mentorshipOptInId}
            }
          )
          { success }
        }`,
      });

      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      await this.loadUserMentorshipSettings();
    },
    async updateMentorshipOptIn({ mentorshipOptInId, roleIds, skillIds }) {
      await axios.post('/graphql', {
        query: `mutation {
          updateMentorTopics(data: {
            id: ${mentorshipOptInId},
            roleIds: [${roleIds.join()}],
            skillIds: [${skillIds.join()}],
          })
          { id }
        }`,
      });

      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      await this.loadUserMentorshipSettings();
    },
    async requestToBeMentor({
      menteeUserId, frequency, invitationMessage, roleIds, skillIds,
    }) {
      // NOTE invitationMessage is intentionally positioned on its own line between the block quote
      // triple-quotes. This ensures that text containing double quotes as the first and/or last
      // characters in the string don't confuse the GraphQL parser.
      const query = `mutation {
        requestToBeMentor(
          data: {
            menteeUserId: ${menteeUserId},
            frequency: ${frequency},
            invitationMessage: """
              ${invitationMessage}
            """,
            roleIds: [${roleIds.join()}],
            skillIds: [${skillIds.join()}],
          })
          { id }
        }`;
      await axios.post('/graphql', { query });

      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      await this.loadUserMentorshipsWithUser(menteeUserId, { withMentorTo: true, withMentoredBy: false });
    },
    async requestToBeMentee({
      mentorUserId, frequency, invitationMessage, roleIds, skillIds,
    }) {
      // NOTE invitationMessage is intentionally positioned on its own line between the block quote
      // triple-quotes. This ensures that text containing double quotes as the first and/or last
      // characters in the string don't confuse the GraphQL parser.
      const query = `mutation {
        requestToBeMentee(
          data: {
            mentorUserId: ${mentorUserId},
            frequency: ${frequency},
            invitationMessage: """
              ${invitationMessage}
            """,
            roleIds: [${roleIds.join()}],
            skillIds: [${skillIds.join()}],
          })
          { id }
        }`;

      await axios.post('/graphql', { query });

      // FIXME ideally the return value of the GraphQL mutation would be used to update data here
      // instead of making a separate API call to reload the user's mentorship records.
      await this.loadUserMentorshipsWithUser(mentorUserId, { withMentorTo: false, withMentoredBy: true });
    },
    /*
    ** Used by ProfileExperienceUpload and Profile components to convert data and
    ** prepare it for batch patching timeline experiences to a confirmed state.
    ** Only update roleId, domainId, and state for each item.
    */
    createUserExperienceUpdates(modifiedUserExperienceData) {
      interface PatchObject {
        id: number;
        value: {
          domainId: number | undefined;
          roleId: number | undefined;
          state?: string;
        }
      }

      let experienceUpdates: PatchObject[] = [];
      if (modifiedUserExperienceData.length > 0) {
        // capture all experiences with a state of draft or a false value for isEditable (aka primary experiences)
        const experiences = modifiedUserExperienceData
          .filter((experience) => experience.state === 'draft' || experience.isEditable === false);

        // format experiences for being patched
        experienceUpdates = experiences.map((experience) => {
          // if user did not select a role or domain, use predicted values if they exist
          const domainId = experience.domainId ?? experience.predictedDomainId;
          const roleId = experience.roleId ?? experience.predictedRoleId;

          // set placeholder for value to be patched
          const value = {
            domainId: undefined,
            roleId: undefined,
          };

          // only assign values to patch if both values are present
          // this check prevents a small but known bug where the matching engine
          // might return a role prediction but not a domain prediction
          if (!isNil(roleId) && !isNil(domainId)) {
            value.domainId = domainId;
            value.roleId = roleId;
          }

          const patchObject: PatchObject = {
            id: experience.id,
            value,
          };

          // when patching a primary experience (aka non-editable experience), you must ONLY include experience.id
          // and roleId and domainId as the value to be patched. If any other fields are included
          // to be patched on a non-editable experience, it responds with a 422
          // ensure experience.isEditable = true before assigning state to be patched
          if (experience.isEditable) patchObject.value.state = 'confirmed';
          return patchObject;
        });
      }
      return experienceUpdates;
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
