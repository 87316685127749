<template>
  <v-snackbar
    v-model="isVisible"
    v-bind="attributes"
    :class="customClasses"
    :data-e2e="snackbarConfig.e2eTag"
  >
    <slot>
      <slot name="icon">
        <v-icon
          v-if="snackbarConfig.icon"
          class="pr-4"
          color="white"
        >
          {{ snackbarConfig.icon }}
        </v-icon>
      </slot>
      <slot name="message">
        {{ snackbarConfig.message }}
      </slot>
      <btn
        v-if="snackbarConfig.button"
        :to="snackbarConfig.buttonPath || undefined"
        outlined
        color="white"
        class="white--text ml-3"
        @click="handleClick(snackbarConfig.atButtonClick)"
      >
        {{ snackbarConfig.button }}
      </btn>
      <slot name="button">
        <btn
          v-if="needsDismissing"
          type="text"
          color="white"
          @click="hideSnackbar"
        >
          Dismiss
        </btn>
      </slot>
    </slot>
  </v-snackbar>
</template>

<script>
import { storeToRefs } from 'pinia';
import {
  useGlobalStore,
} from '@/store';
import Btn from './Button.vue';

export default {
  name: 'Snackbar',
  components: { Btn },
  setup() {
    const globalStore = useGlobalStore();
    const {
      snackbarConfig,
    } = storeToRefs(globalStore);

    const {
      setSnackbarConfig,
      hideSnackbar,
    } = globalStore;
    return {
      snackbarConfig,
      setSnackbarConfig,
      hideSnackbar,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.snackbarConfig.visible;
      },
      set(value) {
        this.setSnackbarConfig({ visible: value });
      },
    },
    attributes() {
      return {
        ...this.$attrs,
        color: this.snackbarConfig.color,
        timeout: this.snackbarConfig.timeout,
        [this.snackbarConfig.position]: true,
        e2eTag: this.snackbarConfig.e2eTag,
      };
    },
    needsDismissing() {
      return this.snackbarConfig.isClosable;
    },
    customClasses() {
      return {
        'snackbar-justify-content-unset': !this.needsDismissing,
      };
    },
  },
  methods: {
    handleClick(functionName) {
      if (functionName) this[functionName]();
    },
    reloadApp() {
      // reloading the App will realign the client (browser's) code's build stamp
      // with the build stamp from the cdn
      window.location.reload(true); // true = forcedReload
    },
  },
};
</script>

<style lang="scss">
  .snackbar-justify-content-unset {
    & .v-snack__content {
      justify-content: unset;
    }
  }
</style>
