/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { getGroups, getLocations, getTenureExtents } from '@/services/companies';
import { getSkills } from '@/services/skills';
import { getSearchUsers } from '@/services/users';
import { type Group } from '@/types/group';
import { type Location } from '@/types/location';
import { type SearchUsersParams } from '@/types/search';
import { type Skill } from '@/types/skill';
import { type UserInfo } from '@/types/user';
import { isNil } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useUserStore } from './user';
import { useCompanyStore } from './company';
import { useAnalyticsStore } from './analytics';

interface SearchState {
  searchResults: UserInfo[],
  totalResults: number,
  currentSearchUsersParams: SearchUsersParams,
  filterGroupsForJobs: Partial<Group>[],
  filterGroupsForUsers: Partial<Group>[],
  filterSkills: Skill[],
  filterLocationsForUsers: Partial<Location>[],
  filterLocationsForJobs: Partial<Location>[],
  filterCompanyNames: string[],
  maxCompanyTenureMonths: number | null,
  maxTitleTenureMonths: number | null,
}

const getDefaultSearchState = (): SearchState => (
  {
    searchResults: [],
    totalResults: 0,
    currentSearchUsersParams: {},
    filterGroupsForJobs: [],
    filterGroupsForUsers: [],
    filterSkills: [],
    filterLocationsForUsers: [],
    filterLocationsForJobs: [],
    filterCompanyNames: [],
    maxCompanyTenureMonths: null,
    maxTitleTenureMonths: null,
  }
);

export const useSearchStore = defineStore('search', {
  state: (): SearchState => getDefaultSearchState(),
  actions: {
    async search({
      filters,
      limit,
      offset,
      sort,
      anonymize,
    }: SearchUsersParams) {
      const analyticsStore = useAnalyticsStore();
      let numSearchResults = 0;
      try {
        // update the latest search parameters
        this.currentSearchUsersParams = {
          filters,
          limit,
          offset,
          'sort[]': sort,
          anonymize: !isNil(anonymize) ? anonymize : 'never',
          includeOpenRequisitionCount: true, // required for open roles badge
        };

        const searchUsersResults = await getSearchUsers(this.currentSearchUsersParams);
        this.searchResults = searchUsersResults.results;
        this.totalResults = searchUsersResults.totalResults;
        numSearchResults = searchUsersResults.totalResults;
      } catch (error) {
        Vue.prototype.$log.error(`Error getting search results: ${error}`);
        throw (error);
      } finally {
        // Track 'Search and Filter Talent' event
        const properties = {
          filters,
          keywords: filters?.searchString,
          totalResults: numSearchResults,
        };
        analyticsStore.trackEvent({ eventName: 'Search and Filter Talent', propertiesObj: properties });
      }
    },
    async loadSearchFilters(
      { skillIds, companyNames }: { skillIds: number[], companyNames: string[] },
    ) {
      try {
        const userStore = useUserStore();
        if (!userStore.userCompanyId) {
          throw new Error('Current user has no company id for getting associated group tree');
        }

        // perform all API requests in parallel, resolving promise.all when all are complete
        const companyStore = useCompanyStore();
        const filterPromises = [
          companyStore.loadDomains(),
          companyStore.loadCompanyRoles(),
          this.loadUserGroupFilters(),
        ];
        // if skill IDs exist in URL on page load, we need to get an array of
        // these skill objects from fm-api in order to render the filter UI
        if (skillIds) {
          const loadSkills = getSkills({ skillIds })
            .then((skills) => { this.filterSkills = skills; });
          filterPromises.push(loadSkills);
        }

        // companyNames is an array of strings, there are no ids, or other key value pairs.
        if (companyNames && companyNames.length > 0) {
          this.filterCompanyNames = companyNames;
        }

        // wait for all filter API requests to resolve
        await Promise.all(filterPromises);
      } catch (error) {
        Vue.prototype.$log.error(`Error getting search filters: ${error}`);
        throw (error);
      }
    },
    async loadUserGroupFilters() {
      const userStore = useUserStore();
      const responseForUsers = await getGroups(userStore.userCompanyId, {
        asTree: true,
        forUsers: true,
        omitRootGroup: 1,
      });
      this.filterGroupsForUsers = responseForUsers.results;
    },
    async loadJobGroupFilters() {
      const userStore = useUserStore();
      const responseForJobs = await getGroups(userStore.userCompanyId, { asTree: true, forJobs: true });
      this.filterGroupsForJobs = responseForJobs.results;
    },
    async loadUserLocationFilters() {
      const userStore = useUserStore();
      const responseForUsers = await getLocations(userStore.userCompanyId, { asTree: true, forUsers: true });
      this.filterLocationsForUsers = responseForUsers.results;
    },
    async loadJobLocationFilters() {
      const userStore = useUserStore();
      const responseForJobs = await getLocations(userStore.userCompanyId, { asTree: true, forJobs: true });
      this.filterLocationsForJobs = responseForJobs.results;
    },
    async loadTenureExtents() {
      const userStore = useUserStore();
      const tenureExtents = await getTenureExtents(userStore.userCompanyId);
      this.maxCompanyTenureMonths = tenureExtents.maxCompanyTenureMonths;
      this.maxTitleTenureMonths = tenureExtents.maxTitleTenureMonths;
    },
    clearSearchState() {
      Object.assign(this, getDefaultSearchState());
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot));
}
