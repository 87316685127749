import auth0 from 'auth0-js';
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import qs from 'qs';
import Cookies from 'js-cookie';
import { pick, isEmpty } from 'lodash-es';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { WebAuth, AuthOptions, Auth0DecodedHash } from 'auth0-js';
/* eslint-disable import/no-cycle */
import { useUserStore } from './user';
import { useCompanyStore } from './company';
import { useAnalyticsStore } from './analytics';
/* eslint-enable import/no-cycle */

interface AuthState {
  webAuth: WebAuth;
  isAuthenticated: boolean;
  idTokenPayload: Auth0DecodedHash['idTokenPayload'];
  accessToken: string;
  tokenRenewalTimeout: null | ReturnType<typeof setTimeout>;
}

const LOCAL_STORAGE_KEY_ACCESS_TOKEN = 'access_token';
const LOCAL_STORAGE_KEY_EXPIRES_AT = 'expires_at';
const LOCAL_STORAGE_KEY_ID_TOKEN = 'id_token';
const LOCAL_STORAGE_KEY_ID_TOKEN_PAYLOAD = 'id_token_payload';

const TOKEN_KEY_FLUX_USER_ID = 'https://fluxwork.io/flux_user_id';
const TOKEN_KEY_FLUX_COMPANY_ID = 'https://fluxwork.io/flux_company_id';
const TOKEN_KEY_IMPERSONATOR_USER_EMAIL = 'https://fluxwork.io/impersonate_user_email';

/**
 * Decodea JWT Token, bypass all error cases
 * @param token jwt token string
 */
function decodeToken(token: string | null | undefined, source = ''): string | undefined {
  try {
    Vue.prototype.$log.debug(`decodeToken ${source}: ${token}`);
    return jwtDecode(token as string);
  } catch (error: unknown) {
    Vue.prototype.$log.debug('catch jwtDecode error', error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (error instanceof InvalidTokenError || (error as any).message?.includes('Invalid token specified')) {
      return undefined;
    }
    // throw other errors
    throw error;
  }
}

function tweakAuthResultForImpersonation(accessTokenPayload, authResult) {
  if (accessTokenPayload[TOKEN_KEY_IMPERSONATOR_USER_EMAIL]) {
    // Impersonating
    // Construct idToken and idTokenPayload properties on authResult to mimic a normal
    // user login
    // accessToken is actually a client-credential token in this case
    /* eslint-disable no-param-reassign */
    authResult.idToken = 'dummy-impersonation-id-token';
    authResult.idTokenPayload = pick(accessTokenPayload, [
      TOKEN_KEY_FLUX_USER_ID,
      TOKEN_KEY_FLUX_COMPANY_ID,
      'iss',
      'sub',
      'aud',
    ]);
    /* eslint-enable no-param-reassign */
  }
}

function logStates() {
  try {
    // We got some "state not match" here. Will try to log both state values (one in URL hash and one in cookie)
    let hashStr = window.location.hash;
    // Codes roughtly took from https://github.com/auth0/auth0.js/blob/5eb72cf422cf595140db52fbe25c37a61beff7e6/src/web-auth/index.js#L234
    hashStr = hashStr.replace(/^#?\/?/, '');
    const parsedHash = qs.parse(hashStr);
    Vue.prototype.$log.info('state in hash URL', parsedHash.state);
    for (const [key, value] of Object.entries(Cookies.get())) {
      if (key.startsWith('com.auth0')) {
        Vue.prototype.$log.info('Auth0 cookie', key, value);
        // Cookies.remove(key); // uncomment this line to mimic the "state not match" error
      }
    }
  } catch (err) {
    Vue.prototype.$log.warn('Err when trying to log states', err);
  }
}

function recoverParseHashError(webAuth: WebAuth, resolve, reject) {
  Vue.prototype.$log.info('auth.js: recoverParseHashError');
  webAuth.checkSession({ prompt: 'none' }, (err, authResult) => {
    if (err) {
      Vue.prototype.$log.error('recoverParseHashError: checkSession error', err);
      return reject(err);
    }
    Vue.prototype.$log.info('recoverParseHashError: checkSession succeeded');
    return resolve(authResult);
  });
}

function parseAuthHash(webAuth: WebAuth): Promise<Auth0DecodedHash> {
  Vue.prototype.$log.debug('auth.js: parseHash');
  return new Promise((resolve, reject) => {
    webAuth.parseHash(
      { __enableIdPInitiatedLogin: true },
      (err, authResult) => {
        Vue.prototype.$log.debug('parseHash', err, authResult);
        // 1st error recovery: state not match
        // Recover the "state does not match" error as it happens frequently with OneLogin
        // https://fluxwork.atlassian.net/browse/FX-2824
        if (err && err.errorDescription === '`state` does not match.') {
          Vue.prototype.$log.warn('Got the \'`state` does not match\' error, trying to recover');
          return recoverParseHashError(webAuth, resolve, reject);
        }
        // 2nd error recovery: opaque (undecodable) access token
        // When running IdP initiated SSO, the 1st accessToken will be an opaque token (non-JWT)
        // https://community.auth0.com/t/strange-access-token-returned-by-saml/7112
        // https://community.auth0.com/t/access-token-not-a-jwt-after-saml-idp-initiated-sso/10595
        // Although some Auth0 resources mentioned setting the "audience" parameter, I didn't
        // find anywhere in OneLogin to set that
        // Instead, a quick way to fix is to do a "checkSession" which obtains a new accessToken
        // when authentication succeeds but the accessToken is not a JWT
        const accessTokenPayload = authResult && decodeToken(authResult.accessToken, 'parseAuthHash');

        if (!accessTokenPayload) {
          Vue.prototype.$log.info('parseHash cannot decode access token, obtain a new one');
          return recoverParseHashError(webAuth, resolve, reject);
        }

        // Other errors should be thrown
        if (err) {
          Vue.prototype.$log.info('parseHash error', err);
          return reject(err);
        }

        Vue.prototype.$log.info('parseHash decoded access token');
        tweakAuthResultForImpersonation(accessTokenPayload, authResult);
        return resolve(authResult);
      },
    );
  });
}

const webAuthOptions: AuthOptions & { stateExpiration: number } = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  redirectUri: `${import.meta.env.VITE_BASE_URL}/auth/callback`,
  audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email',
  stateExpiration: 60 * 24, // extend state expiration from the default 30 minutes to 1 day to workaround "state does not match" error. FX-2824
};

const getDefaultState = (): AuthState => (
  {
    webAuth: new auth0.WebAuth(webAuthOptions),
    // User init flow 2 in https://fluxwork.atlassian.net/wiki/spaces/FM/pages/336592897/Front-End+User+Init+Flows
    // When localStorage has a valid token and a expires_at < now
    isAuthenticated: (new Date().getTime() < JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES_AT) || '""')),
    idTokenPayload: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ID_TOKEN_PAYLOAD) || '{}') || {},
    accessToken: localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN) ?? '',
    tokenRenewalTimeout: null,
  }
);

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => getDefaultState(),
  actions: {
    // initialize token when app start
    initToken(): void {
      Vue.prototype.$log.debug(`init token: ${localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN)}`);
      this.setAccessToken(localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN) ?? '');
    },
    // Set access_token to localStrong and Store
    setAccessToken(token: string): void {
      if (!isEmpty(token) && token !== 'undefined') {
        localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, token);
        this.accessToken = token;
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        this.accessToken = '';
      }
      Vue.prototype.$log.debug('setAccessToken', this.accessToken);
    },
    setSession(authResult: Auth0DecodedHash): void { // Save current Session Info
      Vue.prototype.$log.info('setSession', authResult.idTokenPayload);
      this.idTokenPayload = authResult.idTokenPayload;
      const accessToken = authResult?.accessToken ?? '';
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(((authResult.expiresIn ?? 0) * 1000) + new Date().getTime());
      this.setAccessToken(accessToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_ID_TOKEN, authResult?.idToken ?? '');
      localStorage.setItem(LOCAL_STORAGE_KEY_ID_TOKEN_PAYLOAD, JSON.stringify(authResult.idTokenPayload));
      localStorage.setItem(LOCAL_STORAGE_KEY_EXPIRES_AT, expiresAt);
      // Setting isAuthenticated as true after all tokens have been stored
      this.isAuthenticated = true;
    },
    clearSession(): void { // Clean current Session Info
      Vue.prototype.$log.info('clearSession');
      this.isAuthenticated = false;
      this.idTokenPayload = {};
      this.setAccessToken('');
      localStorage.removeItem(LOCAL_STORAGE_KEY_ID_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEY_ID_TOKEN_PAYLOAD);
      localStorage.removeItem(LOCAL_STORAGE_KEY_EXPIRES_AT);

      // clear renewal token action
      if (this.tokenRenewalTimeout) clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = null;
    },
    login(): void {
      this.webAuth.authorize();
    },
    logout(): void {
      this.clearSession();
      // This will clear Auth0 session and redirect user to homepage aka. login page
      this.webAuth.logout({ returnTo: import.meta.env.VITE_BASE_URL });
    },
    renewTokens(): void {
      if (this.isImpersonating) return;
      Vue.prototype.$log.info('Renew tokens');
      this.webAuth.checkSession(
        { prompt: 'none' },
        (err, authResult) => {
          if (err) {
            Vue.prototype.$log.warn('checkSession', err);
          } else {
            this.setSession(authResult);
            this.scheduleTokenRenewal(authResult.expiresIn);
          }
        },
      );
    },
    scheduleTokenRenewal(expiresInSeconds = 0): void {
      if (this.isImpersonating) {
        Vue.prototype.$log.info('Skip token renewal for impersonated users');
        return;
      }
      let delay;
      if (expiresInSeconds > 0) {
        // Halve the expiration time for renew schedule
        // And cap the delay at 1 hour, because although our Auth0 is configured to:
        // 1. Issue tokens with 24-hour expiration time
        // 2. Keep user session valid for 3 days
        // Other SSO system such as OneLogin may have a short session valid time. Default of OneLogin is 2 hours
        // So we'd want to renew token a bit aggressively, at least once an hour

        delay = Math.min(expiresInSeconds / 2, 3600);
      } else if (this.tokenRenewalTimeout === null) {
        // If expiresInSeconds is not set, and there isn't a current schedule, restart a schedule
        delay = 60;
      } else {
        delay = 0; // do not schedule another renew
        Vue.prototype.$log.info('Token renewal already scheduled');
      }
      if (delay > 0) {
        Vue.prototype.$log.info('Schedule token renewal in', delay, 'seconds');
        this.setTokenRenewalTimeout(setTimeout(() => {
          this.renewTokens();
        }, delay * 1000));
      }
    },
    setTokenRenewalTimeout(timeout): void { this.tokenRenewalTimeout = timeout; },
    captureAndThrow(msg, ...additionalLogs): void {
      Vue.prototype.$log.error(msg, ...additionalLogs);
      // For unexpected errors, we do a Sentry capture here as the error throw from handleAuth will be caught in Callback.vue and redirected
      Sentry.captureMessage(msg);
      throw new Error(msg);
    },
    // This function is only called when a user logins in and redirects from Auth0 page
    // It's not called when a user has a valid session and opens Flux page directly
    async handleAuth(): Promise<void> {
      Vue.prototype.$log.info('handleAuth');
      logStates(); // have to log states here. Once parseHash is called, the cookie will be erased
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const authResult = await parseAuthHash(this.webAuth as any);

      if (!authResult) {
        this.captureAndThrow('No auth result');
      }
      if (!authResult.accessToken || !authResult.idToken) {
        this.captureAndThrow('authResult does not have accessToken or idToken', authResult);
      }
      // Auth0.js SDK used to return authResult with decoded idToken in the idTokenPayload attribute
      // but we recently found it sometimes doesn't return this anymore... See https://sentry.io/organizations/fluxwork/issues/3042507239/events/3b9b7f63b4e443ff8397d00040aa2d98/?project=1385059
      // So if there is an idToken but no idTokenPayload, we should parse idToken and set to idTokenPayload
      if (authResult.idToken && !authResult.idTokenPayload) {
        Vue.prototype.$log.debug(' idToken', authResult.idToken);
        authResult.idTokenPayload = decodeToken(authResult.idToken, 'handleAuth');
        Vue.prototype.$log.debug('JwtDecoded idTokenPayload', authResult.idTokenPayload);

        if (!authResult.idTokenPayload) {
          this.captureAndThrow('authResult idToken cannot be decoded', authResult.idToken);
        }
      }
      if (!authResult.idTokenPayload[TOKEN_KEY_FLUX_USER_ID]) {
        // This could be caused by users who haven't been provisioned in Flux but logged in via SSO
        // So log it at info level and do not Sentry capture it
        Vue.prototype.$log.info('No Flux user id', authResult.idTokenPayload);
        throw new Error('authResult idToken does not contain Flux user id');
      }
      // Clear session/states after auth succeeds
      this.clearSession();
      useUserStore().$reset();
      useCompanyStore().$reset();

      // User init flow 1 in https://fluxwork.atlassian.net/wiki/spaces/FM/pages/336592897/Front-End+User+Init+Flows
      // Normal login success
      this.setSession(authResult);
      // Schedule token renewal
      this.scheduleTokenRenewal(authResult.expiresIn);
      // Track 'Signed In' event
      useAnalyticsStore().trackEvent({ eventName: 'Signed In' });
    },
  },
  getters: {
    fluxUserId: (state) => state.idTokenPayload
      && state.idTokenPayload[TOKEN_KEY_FLUX_USER_ID],
    fluxCompanyId: (state) => state.idTokenPayload
      && state.idTokenPayload[TOKEN_KEY_FLUX_COMPANY_ID],
    accessTokenPayload: (state) => (!isEmpty(state.accessToken) ? decodeToken(state.accessToken) : undefined),
    isImpersonating(): boolean {
      return !!(this.accessTokenPayload && this.accessTokenPayload[TOKEN_KEY_IMPERSONATOR_USER_EMAIL]);
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
