import { lowerCase, template } from 'lodash-es';

export const sentenceCase = (text: string): string => {
  // making lowercase first handles any case
  const string = lowerCase(text);
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateText = (text: string, maxLength = 25, ellipsis = true): string => {
  if (text.length > maxLength) {
    if (ellipsis) {
      return `${text.substring(0, maxLength - 3)}...`;
    }
    return `${text.substring(0, maxLength)}`;
  }
  return text;
};

export const templateLiteral = (
  orginStr: string,
  replaces: Record<string, string>,
  { interpolate = /{{([\s\S]+?)}}/g } = {},
): string => {
  if (orginStr === null || orginStr.length === 0) return orginStr;
  const compiled = template(orginStr, { interpolate });
  return compiled(replaces);
};
