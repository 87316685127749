/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-cycle
import { graphqlQuery } from '../fmApiClient';

const numberOfMentorshipAndRelatedEntitiesToRequest = 5;

interface MentorshipPagination {
  mentorToConfig?: {
    hasNext: boolean,
    after: string | null,
  },
  mentoredByConfig?: {
    hasNext: boolean,
    after: string | null,
  }

}
export const getUserMentorships = async (
  userId: number,
  companyId: number,
  {
    mentorToConfig = { hasNext: true, after: null },
    mentoredByConfig = { hasNext: true, after: null },
  }: MentorshipPagination,
): Promise<any> => {
  const mentorToAfter = mentorToConfig.after ? `, after: "${mentorToConfig.after}" ` : '';
  const mentoredByAfter = mentoredByConfig.after ? `, after: "${mentoredByConfig.after}"` : '';

  const mentorToQuery = `mentorTo(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}${mentorToAfter}) {
    pageInfo { hasNextPage, endCursor }
    nodes {
      id
      mentee { id name }
      notificationLastSentAt
    }
  }`;

  const mentoredByQuery = `mentoredBy(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}${mentoredByAfter}) {
    pageInfo { hasNextPage, endCursor }
    nodes {
      id
      mentor { id name }
      notificationLastSentAt
    }
  }`;

  return graphqlQuery(`
  {
    company(id: ${companyId}) {
      user(id: ${userId}) {
        ${mentorToConfig.hasNext ? mentorToQuery : ''}
        ${mentoredByConfig.hasNext ? mentoredByQuery : ''}
      }
    }
  }`);
};

export const getUserMentorshipsWithUser = async (
  userId: number,
  companyId: number,
  targetUserId: number,
  { mentorTo = true, mentoredBy = true } = {},
): Promise<any> => {
  const mentorToQuery = `mentorTo(menteeId: ${targetUserId}, first: ${numberOfMentorshipAndRelatedEntitiesToRequest}) {
    pageInfo { hasNextPage, endCursor }
    nodes {
      id
      mentee { id name }
      notificationLastSentAt
    }
  }`;

  const mentoredByQuery = `mentoredBy(mentorId: ${targetUserId}, first: ${numberOfMentorshipAndRelatedEntitiesToRequest}) {
    pageInfo { hasNextPage, endCursor }
    nodes {
      id
      mentor { id name }
      notificationLastSentAt
    }
  }
  `;
  return graphqlQuery(`{
    company(id: ${companyId}) {
      user(id: ${userId}) {
        ${mentorTo ? mentorToQuery : ''}
        ${mentoredBy ? mentoredByQuery : ''}
      }
    }
  }`);
};

export const getUserMentorshipSettings = async (
  userId: number,
  companyId: number,
  options,
): Promise<any> => graphqlQuery(`
{
  company(id: ${companyId}) {
    user(id: ${userId}) {
      settings {
        mentorship {
          optedInAsMentee { id }
          optedInAsMentor {
            id
            roles(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}) {
              pageInfo { hasNextPage, endCursor }
              nodes {
                id
                name
              }
            }
            skills(first: ${numberOfMentorshipAndRelatedEntitiesToRequest}) {
              pageInfo { hasNextPage, endCursor }
              nodes {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}`, options);
