import type { Notification } from '@/types/notification';
import { VERSION_V1 } from '../config';
// eslint-disable-next-line import/no-cycle
import fmApiClient from '../fmApiClient';

export const markNotificationRead = async (
  userId: number,
  notificationId: number,
  userView: string,
): Promise<Notification[]> => fmApiClient.patch(`/${VERSION_V1}/users/${userId}/feed/mark-notifications-read`, {
  maxFeedItemId: notificationId,
  userView,
});
