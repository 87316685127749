/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import type { AxiosError, AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import axios from 'axios';
import { isNil, isEmpty, pick } from 'lodash-es';
import * as Sentry from '@sentry/vue';
import globals from '@/globals';
import type { IApiClient } from '@/services/core/ApiClient';
import APIClient from '@/services/core/ApiClient';
import { shouldRetryRequest } from '@/services/core/retryAxios';
import { omit } from '@/utils/omit';
import { useUserStore } from '@/store';
import { useGlobalStore } from '../store/modules/global';
import { useAuthStore } from '../store/modules/auth';

/**
 * Check if it's a axios network error
 * REF: https://github.com/axios/axios/issues/383
 *
 * @param {AxiosError} err AxiosError
 * @returns {boolean}
 */
export const isNetworkError = (err: AxiosError) => !!err.isAxiosError && !err.response;

/**
 * Transform AxiosError to readable error message
 * Also exclude auth info
 *
 * @param error AxiosError
 * @returns Object
 */
export const transformAxiosError = (error: AxiosError) => {
  const configExcludes = ['headers.Authorization'];
  const responseIncludes = ['data', 'status', 'statusText', 'headers'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatError: Record<string, any> = {};

  // Basic Infos
  formatError.name = error.name;
  formatError.stack = error.stack;
  formatError.code = error.code;
  formatError.isAxiosError = error.isAxiosError;
  formatError.config = omit({ ...error.config }, ...configExcludes);

  if (error.response) {
    formatError.response = pick(
      error.response,
      responseIncludes,
    );
  }

  return formatError;
};

const fmApiDefaultConfigs: Partial<AxiosRequestConfig> = {
  baseURL: import.meta.env.VITE_FM_API_BASE,
  // responseType: 'json' as const,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'FLUX-BUILD': import.meta.env.VITE_BUILD_STAMP,
  },
};

export const defaultInterceptors = {
  requestInterceptor: (config: AxiosRequestConfig) => {
    const configClone = {
      ...config,
      // NOTE: Simulate an error stack here.
      // This stack trace will include information about the actual function being executed.
      errorStack: new Error('Thrown at:').stack,
    };
    // Will attach access_token from Auth Store before sending out any request
    const authStore = useAuthStore();
    const userStore = useUserStore();

    if (!configClone.headers) { configClone.headers = {}; }
    if (userStore.currentView) configClone.headers['FLUX-CURRENT-VIEW'] = userStore.currentView;
    if (authStore.isAuthenticated && authStore.accessToken && authStore.accessToken !== '') {
      // Send out request with valid token
      configClone.headers.Authorization = `Bearer ${authStore.accessToken}`;
    } else if (authStore.isAuthenticated && authStore.accessToken === '') {
      /**
      * Still send out request when user is login, but accessToken is not correct.
      * This will redirect user to `operation error` page to re-login.
      */
      configClone.headers.Authorization = 'Bearer ';
    } else {
      // clear Authorization Token from header
      delete configClone.headers.Authorization;
    }

    // In case an axios request is sent when auth token has been cleared, cancel the request
    if (isEmpty(configClone?.headers?.Authorization)) {
      configClone.cancelToken = new axios.CancelToken((cancel) => cancel('Cancel request without auth token'));
    }

    return configClone;
  },
  requestInterceptorError: async (error: any) => {
    // Cancel Request
    if (axios.isCancel(error)) {
      // Intentionally use info instead of error level log here, as it's expected to cancel requests without auth token
      // also error-level logs will fail e2e tests
      Vue.prototype.$log.info('Request canceled');
      // When axios request is canceled, the error object is not an Error instance, but an Object
      // repack it as an Error instance here so Sentry doesn't complain
      return Promise.reject(new Error(error.message));
    }
    return Promise.reject(error);
  },
  responseInterceptorError: async (error: any) => {
    // if it is not axios error then throw to next;
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error);
    }

    // Retry request
    const willRaxRetry = shouldRetryRequest(error);
    if (willRaxRetry) {
      // Rax will retry. No need to do global interceptor logic yet
      return Promise.reject(error);
    }

    // Last Retry
    Vue.prototype.$log.info('Last retry. Global error interceptor');
    const globalStore = useGlobalStore();
    const authStore = useAuthStore();

    const win: Window = window;

    // override stack trace
    if ((error.config as any).errorStack) {
      // eslint-disable-next-line no-param-reassign
      error.stack = (error.config as any).errorStack;
    }

    const errorWithoutAuth = transformAxiosError(error);
    // Globally handle 401, 403, 404, and 500 error responses
    switch (error.response?.status) {
      case 401:
        // If user is already logged out (when multiple 401 return simultaneously)
        // No need to do the 'logout => redirect to error page' again
        if (!authStore.isAuthenticated) {
          Vue.prototype.$log.info('Skip 401 handling as user is already logged out');
          return Promise.reject(error);
        }

        /* Handle unauthenticated user */
        // first store error messages to use within ErrorUser component
        localStorage.setItem('401_error_data', JSON.stringify(error.response?.data?.errors?.[0]));
        // next store error email address in auth store
        if (!isNil(authStore.idTokenPayload?.email || '')) {
          Vue.prototype.$log.debug('Save user error email', authStore.idTokenPayload.email);
          localStorage.setItem('user_error_email', authStore.idTokenPayload.email);
        }

        // Clear session so isAuthenticated is set to false and no further requests are sent
        await authStore.clearSession();
        // no access to Vue $router, use window
        if (error.response?.data?.errors?.[0].msg.includes('has been deactivated')) {
          Vue.prototype.$log.warn('Deactivated account');
          win.location = '/error/user?errorType=deactivated';
        } else if (error.response?.data?.errors?.[0].msg.includes('We don\'t recognize your account')) {
          Vue.prototype.$log.warn('Unrecognized account');
          win.location = '/error/user?errorType=unrecognizedAccount';
        } else if (error.response?.data?.errors?.[0].msg.includes('jwt expired')) {
          Vue.prototype.$log.warn('JWT expired');
          globalStore.showSnackbar({
            message: 'Session expired. Please login again.',
            button: 'Login',
            buttonPath: '/logout',
            icon: 'hourglass_bottom',
            color: 'warning',
            timeout: -1,
          });
        } else {
          Vue.prototype.$log.warn('Unexpected 401');
          Sentry.captureException(error);
          win.location = '/error/user';
        }
        break;
      case 403:
        // Handle forbidden url, generally not expected
        Sentry.captureException(error);
        // no access to Vue $router, use window instead
        win.location = '/error/403';
        break;
      case 404:
        // Handle 'not found' errors
        // Don't display error if onError=ignore present in URL
        if (!error.config.url?.includes('onError=ignore')) {
          Vue.prototype.$log.error('404 error', errorWithoutAuth);
          Sentry.captureException(error);
          // no access to Vue $router, use win
          win.location = '/error/404';
        }
        break;
      case 422:
        // 422 error is sometimes expected (for validations that must be done in api)
        Vue.prototype.$log.warn('422 error', errorWithoutAuth);
        break;
      case 429:
        // 429 Too Many Requests usually means that rate limit has been exceeded
        Vue.prototype.$log.warn('429 error', errorWithoutAuth);
        break;
      case 409: // unique violation in DB, which should not happen, as it should have been caught by validations
      case 500: // internal server error
      case 502: // Network error, including Nginx Gateway error
      case undefined: // No response, including network errors such as ETIMEDOUT, ENOTFOUND
        Vue.prototype.$log.error('Unexpected error', errorWithoutAuth);
        if (!isNetworkError(error)) {
          Sentry.captureException(error);
        }
        globalStore.showSnackbar({
          message: globals.errors.generic,
          icon: 'block',
          color: 'error',
        });
        break;
      default:
        Vue.prototype.$log.error('Unexpected response status', errorWithoutAuth);
        Sentry.captureException(error);
        break;
    }
    return Promise.reject(error);
  },
};

const apiClient: IApiClient = new APIClient(fmApiDefaultConfigs, defaultInterceptors);

export const graphqlQuery = <T>(query: string, options?: any): Promise<T> => apiClient.post('/graphql', { query }, options);

export default apiClient;
