import { useGlobalStore } from '@/store';
import axios from 'axios';
import type { Ref } from 'vue';
import Vue, { ref, watch } from 'vue';

interface Options {
  isAuthenticated?: Ref<boolean>
  isUserLoaded?: Ref<boolean>
  enabled: boolean
}
export const useBuildStamp = ({
  isAuthenticated = ref(false),
  isUserLoaded = ref(false),
  enabled = false,
}: Options) => {
  Vue.prototype.$log.info('Grow build stamp', import.meta.env.VITE_BUILD_STAMP || 'development');

  const buildStamp = ref(null);

  if (!enabled) return;

  /*
  ** The requestHeadersAndInterceptors.js contains logic that specifies that getCurrentBuildStamp
  ** not throw a 404 if ~/build-stamp.json is not found by including onError=ignore
  */
  const getCurrentBuildStamp = async () => {
    Vue.prototype.$log.debug('Get current BuildStamp');
    try {
      const { data } = await axios.get(`${import.meta.env.VITE_BASE_URL}/build-stamp.json?onError=ignore`);
      buildStamp.value = data?.buildStamp;
      console.log('CURRENT BuildStamp', data);
    } catch (error) {
      Vue.prototype.$log.error(`Error getting current build stamp json: ${error}`);
    }
  };

  // Get BuildStamp
  watch([isAuthenticated, isUserLoaded], async (values) => {
    if (values[0] && values[1]) {
      // only invoke getCurrentBuildStamp when in production build
      await getCurrentBuildStamp();
      // poll cdn at a 5 minute interval to ensure build stamps match
      setInterval(() => { getCurrentBuildStamp(); }, 300000); // 5 minute interval
    }
  }, { flush: 'post' });

  // Watch buildStamp changes
  watch(
    buildStamp,
    (newBuildStamp) => {
      // only display when in production build and the build stamp was successfully retrieved and does not
      // match import.meta.env.VITE_BUILD_STAMP
      if ((newBuildStamp
          && import.meta.env.VITE_BUILD_STAMP
          && import.meta.env.VITE_BUILD_STAMP !== newBuildStamp
      )) {
        useGlobalStore().showSnackbar({
          message: 'A new version of Grow is available!',
          button: 'Refresh',
          atButtonClick: 'reloadApp',
          icon: 'refresh',
          color: 'warning',
          timeout: -1,
        });
      }
    },
    { immediate: true },
  );
};
