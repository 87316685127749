/** Vuetify Plugin */
import type { UserVuetifyPreset } from 'vuetify';
import Vuetify from 'vuetify/lib/framework';
import { ClickOutside, Intersect } from 'vuetify/lib/directives';
import Vue from 'vue';
import type { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import SlackSvg from '../../assets/svg/slack-logo.svg';
import MSTeamSvg from '../../assets/svg/msteams-logo.svg';
import light from './theme';

/** Create Vuetify */
export function createVuetify(options: UserVuetifyPreset): Vuetify {
  Vue.use(Vuetify, {
    ...options,
    directives: {
      ClickOutside,
      Intersect,
    },
  });
  return new Vuetify(options);
}

const vuetify = createVuetify({
  icons: {
    iconfont: 'md',
    values: {
      // custom icons
      slack: {
        component: SlackSvg,
      },
      msteams: {
        component: MSTeamSvg,
      },
    },
  },
  theme: {
    themes: { light },
    options: {
      customProperties: true,
      variations: true,
    },
  },
});

export default vuetify;

export type { VuetifyThemeVariant };
