/* eslint no-param-reassign:
  ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { acceptHMRUpdate, defineStore } from 'pinia';
import yn from 'yn';
import axios from 'axios';
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import { useUserStore } from './user';

const enableSegment = yn(import.meta.env.VITE_ENABLE_SEGMENT, { default: !import.meta.env.VITE_BASE_URL?.includes('localhost') });

interface TrackedEvent {
  action: string;
  data: {
    pageName: string;
    eventName: string;
    propertiesObj: object;
  }
}

interface AnalyticsState {
  queuedTracking: TrackedEvent[];
}

export const useAnalyticsStore = defineStore('analytics', {
  state: (): AnalyticsState => ({
    queuedTracking: [],
  }),
  actions: {
    initializeAnalyticsTracking() {
      if (!enableSegment) return;

      if (!window.analytics) {
        /**
         * Segment tracking codes
         *
         * NOTE: Web blockers could block loading analytics.js from the Segment CDN.
         * which could cause the tracking to not work, and undefined methods.
         */
        /* eslint-disable */
        // @ts-ignore: Segment-provided/required code snippet, ignore TS rules.
        !function(){
          // @ts-ignore: Segment-provided/required code snippet, ignore TS rules.
          var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.2";
            if (import.meta.env.VITE_SEGMENT_WRITE_KEY) analytics.load(import.meta.env.VITE_SEGMENT_WRITE_KEY);
  
          // un-comment for extra debug statements in console during development
          /* if (import.meta.env.NODE_ENV === 'test') {
            analytics.debug();
          } */
        }}();
        /* eslint-enable */
      }

      // If any tracking was called before the analytics was initialized, fire it now
      this.queuedTracking.forEach((tracking) => this[tracking.action](tracking.data));
    },
    identifyAnalyticsUser() {
      if (!enableSegment) return;
      const userStore = useUserStore();
      const traits = {
        ...userStore.userId && { id: userStore.userId },
        ...userStore.userCompanyId && { companyId: userStore.userCompanyId },
        ...userStore.user.name && { name: userStore.user.name },
        ...userStore.user.email && { email: userStore.user.email },
        ...{
          onboardedAt: userStore.user.onboardedAt,
          hasAcceptedPrivacyPolicy: !!userStore.user.hasAcceptedPrivacyPolicy,
          privacyPolicyAcceptedAt: userStore.user.privacyPolicyAcceptedAt,
          isBillable: userStore.user.isBillable,
          isClient: userStore.isUserCompanyClient,
        },
        ...userStore.user.location && userStore.user.location.name && {
          location: userStore.user.location.name,
        },
        ...userStore.user.authzRoles && userStore.user.authzRoles.length > 0 && {
          authzRoles: userStore.user.authzRoles.map((authz) => authz.name),
        },
        ...userStore.user.groups && userStore.user.groups.length > 0 && {
          groups: userStore.user.groups.map((group) => ({
            name: group.name,
            type: group.type,
            isPrimary: group.isPrimary,
          })),
        },
        ...userStore.userRole.name && { role: userStore.userRole.name },
      };

      window.analytics.identify(userStore.userId, traits);
    },

    trackPage({ pageName, propertiesObj = {} }) {
      if (!enableSegment) return;
      Vue.prototype.$log.debug('trackPage', pageName, propertiesObj);
      if (window?.analytics?.page) {
        window.analytics.page(pageName, propertiesObj);
      } else {
        this.setQueuedTracking({ action: 'trackPage', data: { pageName, propertiesObj } });
      }
    },
    trackEvent({ eventName, propertiesObj = {} }) {
      if (!enableSegment) return;
      if (window?.analytics?.track) {
        window.analytics.track(eventName, propertiesObj);
      } else {
        this.setQueuedTracking({ action: 'trackEvent', data: { eventName, propertiesObj } });
      }
    },
    async trackEventViaBackend({ eventName, propertiesObj = {} }) {
      if (!enableSegment) return;
      try {
        await axios.post('/v1/analytics-events', {
          event: eventName,
          properties: propertiesObj,
        });
      } catch (error) {
        Vue.prototype.$log.error(`Error when sending ${eventName} event to backend`);
        throw error;
      }
    },
    setQueuedTracking({ action, data }) {
      if (!enableSegment) return;
      this.queuedTracking.push({ action, data });
    },
  },
});

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnalyticsStore, import.meta.hot));
}
